import * as types from "./actionTypes";

export const loadEquipmentModels = (equipmentModel) => ({
  type: types.LOAD_EQUIPMENT_MODELS,
  payload: equipmentModel
});

export const loadEquipmentSignee = (signee) => ({
  type: types.LOAD_EQUIPMENT_SIGNEE,
  payload: signee
});
