import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import RunStatusLabel from "../../common-for-log-sheet-and-run-logs/RunStatusLabel";
import { valueOrEmpty } from "../../../../utils/helpers/text";
import {
  ApproverUserNameCluster,
  Flex,
  RunLogWrapper,
  CustomDetailsCellStyled,
  MediaTypeCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import ValueOrFallbackText from "../../common-for-log-sheet-and-run-logs/ValueOrFallbackText";
import { changeDateFormat } from "../../helpers";
import RUN_LOGS_DATA_MODEL from "./../../../../utils/constants/runLogsDataModel";
import { DESCRIPTION_FIELD } from "../../../../utils/constants/descriptionField";

export const RunLogClusterTableMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: RUN_LOGS_DATA_MODEL.startDate.value,
          component: ({ item }) => (
            <DateTimeDetailValue date={changeDateFormat(item?.runStartDate)} time={item.runStartTime} />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.runEndDate.value,
          component: ({ item }) => (
            <DateTimeDetailValue date={changeDateFormat(item?.runEndDate)} time={item.runEndTime} />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.samplesProcessed.value,
          field: ["samplesProcessed"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.numberOfRuns.value,
          field: ["numberOfRuns"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.defectId.value,
          field: ["defectId"]
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.runIdentification.value,
          field: ["runIdentification"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.eLNid.value,
          field: ["eLNid"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.tipsUsed.value,
          component: ({ item }) => (
            <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.mediaType.value,
          component: MediaTypeCell
        },
        {
          label: RUN_LOGS_DATA_MODEL.assay.value,
          component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>,
          props: {
            expand: true
          }
        },
        DESCRIPTION_FIELD
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    runStartDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runStartDate.value,
      component: ({ item }) => changeDateFormat(item?.runStartDate),
      width: 500,
      maxWidth: 500
    },
    runEndDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runEndDate.value,
      component: ({ item }) => <DateTimeDetailValue date={changeDateFormat(item?.runEndDate)} time={item.runEndTime} />
    },
    eLNid: {
      text: RUN_LOGS_DATA_MODEL.eLNid.value,
      component: ({ item }) => valueOrEmpty(item?.eLNid, false, "-")
    },
    runStatus: {
      text: RUN_LOGS_DATA_MODEL.runStatus.value,
      component: RunStatusLabel
    },
    runIdentification: {
      text: RUN_LOGS_DATA_MODEL.runIdentification.value
    },
    gxpReady: {
      text: RUN_LOGS_DATA_MODEL.gxpReady.value
    },
    systemStatus: {
      text: RUN_LOGS_DATA_MODEL.systemStatus.value
    },
    ...ApproverUserNameCluster,
    description: {
      text: RUN_LOGS_DATA_MODEL.description.value
    },
    samplesProcessed: {
      text: RUN_LOGS_DATA_MODEL.samplesProcessed.value
    },
    numberOfRuns: {
      text: RUN_LOGS_DATA_MODEL.numberOfRuns.value
    },
    defectId: {
      text: RUN_LOGS_DATA_MODEL.defectId.value
    },
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    tipsUsed: {
      text: RUN_LOGS_DATA_MODEL.tipsUsed.value,
      component: ({ item }) => (
        <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
      )
    },
    mediaType: {
      text: RUN_LOGS_DATA_MODEL.mediaType.value,
      component: MediaTypeCell
    },
    assay: {
      text: RUN_LOGS_DATA_MODEL.assay.value,
      component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>
    },
    subEquipment: {
      text: COMMON_LOGS_DATA_MODEL.subEquipment.value
    }
  }
};

export const runLogTabletSizes = {
  runStartDate: "0 1 15%",
  runEndDate: "0 1 13%",
  subEquipment: "0 1 27%",
  eLNid: "0 1 13%",
  runStatus: "0 1 15%",
  runIdentification: "0 1 13%",
  equipmentModel: "0 1 16%",
  equipmentNickName: "0 1 13%",
  description: "0 1 25%",
  approverUserName: "0 1 12%",
  actionButtons: "0 0 132px"
};

export const runLogRegularSizes = {
  runStartDate: "0 1 15%",
  runEndDate: "0 1 13%",
  subEquipment: "0 1 27%",
  eLNid: "0 1 13%",
  runStatus: "0 1 15%",
  runIdentification: "0 1 13%",
  equipmentModel: "0 1 16%",
  equipmentNickName: "0 1 13%",

  description: "0 1 25%",
  approverUserName: "0 1 12%",
  actionButtons: "0 0 132px"
};

export default RunLogClusterTableMeta;
