import React from "react";
import LogBookTable from "./LogBookTable";
import UpdateData from "./UpdateData";

const LogBookList = ({ dataTestId, meta, fetchingFn, clusterLogStatus = false, type }) => (
  <>
    <LogBookTable dataTestId={dataTestId} meta={meta} type={type} />
    <UpdateData fetchingFn={fetchingFn} clusterLogStatus={clusterLogStatus} />
  </>
);

export default LogBookList;
