import React from "react";
import { Auth } from "aws-amplify";
import IconForApp from "../icons/IconForApp";
import LOGBOOK_LABEL from "./../utils/constants/logbookLabel";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../constants";
import { OwcButton, OwcDoubleGlobalArea, OwcIcon, OwcIconButton, OwcTypography } from "@one/react";
import { Link } from "@mui/material";
import styles from "./AppBarNotAuth.module.scss";
import { getEnv } from "../utils/helpers/text";

const CustomLink = ({ children, disabled, ...props }) => (
  <Link
    onClick={() => {
      if (disabled !== true) {
        if (process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !== "TRUE") {
          Auth.federatedSignIn({
            provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
          });
        } else {
          Auth.federatedSignIn();
        }
      }
    }}
    style={{
      textDecoration: "none"
    }}
    {...props}
  >
    {children}
  </Link>
);

const currentEnv = getEnv();
const Appbar = ({ disabled = false }) => {
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div data-testid="app-bar-not-auth-main-page">
      <OwcDoubleGlobalArea
        data-testid="app-bar-not-auth-main-page"
        className={currentEnv ? styles.appbarDoubleGlobalAreaWithEnv : styles.appbarDoubleGlobalAreaWithoutEnv}
      >
        <div slot="primary" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconForApp />
            <OwcTypography variant="subtitle1">{LOGBOOK_LABEL.app_name}</OwcTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomLink disabled={disabled} data-testid="link-to-sign-in-on-button" style={{ padding: "0 8px" }}>
              <OwcButton
                data-testid="sign-in-button"
                disabled={disabled}
                variant="primary"
                style={{ minWidth: "120px", marginRight: "26px" }}
                id={generateID.buttonID(Module.BUTTON.sign_in, "button")}
              >
                Sign in
              </OwcButton>
            </CustomLink>
            <OwcIconButton
              flat
              data-testid="help-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
              style={{ marginRight: "26px" }}
            />
            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>
      {/* <RocheTopBar
        title={
          <IconWithText
            iconComponent={IconForApp}
            iconStyle={{ marginRight: ".6rem" }}
          >
            <OwcTypography variant="button" style={{ textTransform: "none" }}>
              {LOGBOOK_LABEL.app_name}
            </OwcTypography>
          </IconWithText>
        }
        position="relative"
        rightIcons={[
          <CustomLink
            disabled={disabled}
            data-testid="link-to-sign-in-on-button"
          >
            <SignInButton
              data-testid="sign-in-button"
              disabled={disabled}
              color="primary"
              variant="contained"
              style={{ minWidth: "120px" }}
              id={generateID.buttonID(Module.BUTTON.sign_in, "button")}
            >
              Sign in
            </SignInButton>
          </CustomLink>,
          <AppBarInfo>
            <IconButton
              data-testid="help-button"
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              classes={{ root: classes.iconButtonRoot }}
              id={generateID.buttonID(Module.BUTTON.info, "button")}
            >
              <i className="one-icons">info</i>
            </IconButton>
          </AppBarInfo>
        ]}
      /> */}
    </div>
  );
};

export default Appbar;
