import React from "react";
import { OwcButton, OwcModalDialog } from "@one/react";
import { ModalSize } from "@one/web-components";

export const ConfirmDialog = ({
  testid = "confirm-dialog",
  title = "",
  content = <></>,
  cancelText = "Cancel",
  approveText = "OK",
  approveColor = "primary",
  onCancel,
  onApprove,
  onApproveAsync,
  close,
  open,
  children,
  isEnabled = true,
  size = "sm",
  disableBackdropClick = false
}: {
  testid: string;
  title: React.ReactNode;
  content: React.ReactNode;
  cancelText: string;
  approveText: string;
  approveColor: string;
  onCancel: () => {};
  onApprove?: () => {};
  onApproveAsync?: () => {};
  close: () => {};
  open: boolean;
  children: React.ReactNode;
  isEnabled: boolean;
  size: ModalSize;
  disableBackdropClick: boolean;
}) => {
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };
  const handleApprove = async () => {
    if (onApproveAsync) {
      await onApproveAsync();
    }
    if (onApprove) {
      onApprove();
    }
    close();
  };
  return (
    <OwcModalDialog
      visible={open}
      data-testid={testid}
      disableScrollLock
      size={size}
      className="owcmodalZIndex"
      disableBackdropClick={disableBackdropClick}
      onVisibleChange={(event: { detail: any }) => {
        if (!event?.detail) {
          handleCancel();
        }
      }}
    >
      <div slot="header" data-testid="confirm-dialog-title">
        {title}
      </div>
      <div data-testid="confirm-dialog-content">
        {children ? children : <div data-testid="confirm-dialog-content-text">{open ? content : ""}</div>}
      </div>
      <div
        slot="actions"
        data-testid="confirm-dialog-actions"
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        <div style={{ display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={handleCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText || null}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={handleApprove}
            color={approveColor && "primary"}
            variant="primary"
            disabled={!isEnabled}
          >
            {approveText || null}
          </OwcButton>
        </div>
      </div>
    </OwcModalDialog>
  );
};
export default ConfirmDialog;
