import { GXPemptyInstrumentsOptions } from "../constants";
import { changeDateFormat } from "../features/log-book/helpers";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { IActionLogFormValues, IRunLogFormValues, ISelectValues } from "./StepperContainer";
import gql from "graphql-tag";
import { v4 } from "uuid";

export const createRunLogBulkVariables = (
  formValues: IRunLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  user: { site: string; user: string; name: string } | undefined,
  systemStatuses: ISelectValues[]
) => {
  const variables = {
    ...formValues,
    runStartDate:
      formValues.runStartDate instanceof Date ? changeDateFormat(formValues.runStartDate) : formValues.runStartDate!,
    runEndDate:
      formValues.runEndDate instanceof Date ? changeDateFormat(formValues.runEndDate) : formValues.runEndDate!,
    site: user?.site ?? "",
    approverId: user?.user ?? "",
    approverUserName: user?.name ?? ""
  };

  return Object.fromEntries(
    equipmentList.map((equipment, index) => {
      const systemStatusKey = (systemStatuses as ISelectValues[]).find(
        (item) => item.value.toLowerCase() === equipment.systemStatus?.toLowerCase()
      )?.key;
      return [
        `input${index}`,
        {
          // KEEP ...VARIABLES AT THE TOP OR IT WILL OVERWRITE OTHER VALUES
          ...variables,
          runLogEntryId: v4(),
          inventoryId: equipment.inventoryId ?? "",
          equipmentModel: equipment.equipmentModel ?? "",
          serialNumber: equipment.serialNumber ?? "",
          equipmentId: equipment.equipmentId ?? "",
          modelVersion: equipment.modelVersion ?? "",

          systemStatus: {
            key: systemStatusKey ?? equipment.systemStatus ?? "",
            value: equipment.systemStatus ?? ""
          },
          gxpReady: GXPemptyInstrumentsOptions.qualificationStatus.find(
            (entry: { key: string | undefined }) =>
              entry.key?.toLowerCase() === equipment.qualificationStatus?.toLowerCase()
          ) || { key: "", value: "" }
        }
      ];
    })
  );
};

export const createRunLogBulkMutation = (equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[]) => {
  const mutationString = equipmentList
    .map(
      (_, index) => `
    runLog${index}: createDigitalLabLogbookRunLogChange(input: $input${index}) {
    id
    }
  `
    )
    .join("\n");

  const output = ` mutation BulkRunLogMutations(
        ${equipmentList.map((_, index) => `$input${index}: CreateDigitalLabLogbookRunLogChangeInput!`).join(", ")}
      ) {
        ${mutationString}
      }`;
  return gql(output);
};
export const createActionLogBulkVariables = (
  formValues: IActionLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  user: { site: string; user: string; name: string } | undefined
) => {
  const { systemStatus, ...otherFormValues } = formValues;
  const variables = {
    ...otherFormValues,
    actionDate:
      formValues.actionDate instanceof Date ? changeDateFormat(formValues.actionDate) : formValues.actionDate!,
    approverId: user?.user ?? "",
    inventoryIds: equipmentList.map((element) => element.inventoryId)
  };
  variables.gxpReady = { key: variables.gxpReady.key, value: variables.gxpReady.value };
  return {
    input: {
      ...variables,
      systemStatus: {
        key: systemStatus.key ?? "",
        value: systemStatus.value ?? ""
      }
    }
  };
};

export const createActionLogBulkMutation = (equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[]) => {
  const mutationString = equipmentList
    .map(
      (_, index) => `
    actionLog${index}: createDigitalLabLogbookLogSheetChange(input: $input${index}) {
    id
    }
  `
    )
    .join("\n");

  const output = ` mutation BulkActionLogMutations(
        ${equipmentList.map((_, index) => `$input${index}: CreateDigitalLabLogbookLogSheetChangeInput!`).join(", ")}
      ) {
        ${mutationString}
      }`;
  return gql(output);
};
