import { Tooltip } from "@mui/material";
import { SHOW_CLUSTER_KEY } from "../../../constants";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { getFile } from "../log-sheet/log-sheet-form/downloadFile";
import { OwcHyperlink } from "@one/react";
import { EN_DASH } from "@digitallab/grid-common-components";

export const DescriptionCell = {
  label: COMMON_LOGS_DATA_MODEL.description.value,
  field: ["description"],
  props: {
    fullRow: true
  }
};

export const ApproverCell = {
  label: COMMON_LOGS_DATA_MODEL.approverUserName.value,
  field: ["approverUserName"],
  props: {
    fullRow: false
  }
};
export const OperatorCell = {
  label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
  field: ["operatorUserName"],
  props: {
    fullRow: false
  }
};

export const SubComponent = {
  label: COMMON_LOGS_DATA_MODEL.subEquipment.value,
  field: ["subEquipment"],
  component: ({ item }) => (
    <div id="sub-component-list">
      {item?.subEquipment
        ? item?.subEquipment.map((i, index) => {
            let addDivCollector = "";
            let equipmentCollector = "";
            addDivCollector += `<strong>Component ${++index}: </strong>`;

            SHOW_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
              addDivCollector += `${clusterItem?.label}:${
                i?.[clusterItem?.key] !== null &&
                i?.[clusterItem?.key] !== undefined &&
                i?.[clusterItem?.key] !== "null"
                  ? i?.[clusterItem?.key]
                  : " -"
              }${indexItem < SHOW_CLUSTER_KEY.length - 1 ? ", " : ""}`;

              equipmentCollector += `${clusterItem?.label}:${
                i?.[clusterItem?.key] !== null &&
                i?.[clusterItem?.key] !== undefined &&
                i?.[clusterItem?.key] !== "null"
                  ? i?.[clusterItem?.key]
                  : " -"
              }${indexItem < SHOW_CLUSTER_KEY.length - 1 ? ", " : ""}`;
            });

            return (
              <>
                <Tooltip id="sub-component-list" title={equipmentCollector}>
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                    dangerouslySetInnerHTML={{ __html: addDivCollector }}
                  ></div>
                </Tooltip>
              </>
            );
          })
        : EN_DASH}
    </div>
  ),
  props: {
    fullRow: true
  }
};

export const ExternalDocument = {
  label: "Link list (Description and URL)",
  field: ["externalDocument"],
  props: {
    style: { width: "100%" },
    fullRow: true
  },
  component: ({ item }) => (
    <div id="sub-component-list">
      {item?.externalDocument && item?.externalDocument.length > 0
        ? item?.externalDocument?.map((i, index) => {
            return (
              <div
                key={`itemLinst${index}`}
                style={{
                  borderBottom: "1px solid var(--one-color-interaction-default-neutral-2)"
                }}
              >
                <p style={{ textWrap: "wrap" }}>{i?.name}</p>
                <p style={{ textWrap: "wrap" }}>{i?.link}</p>
              </div>
            );
          })
        : EN_DASH}
    </div>
  )
};

/**
 * method to create a entry in audit traif for attachments
 * @returns component with list of attachments
 */
export const Attachments = {
  label: "File list (Description and File name)",
  field: ["attachments"],
  props: {
    style: { width: "100%" },
    fullRow: true
  },
  component: ({ item }) => (
    <div id="sub-component-list">
      {item?.attachments?.length > 0
        ? item.attachments
            .filter((i) => i.status === "ACTIVE")
            .map((i, index) => {
              return (
                <div
                  key={`item${index}`}
                  style={{
                    borderBottom: "1px solid var(--one-color-interaction-default-neutral-2)"
                  }}
                >
                  <p style={{ textWrap: "wrap" }}>{i.fileDescription}</p>
                  <OwcHyperlink onClick={() => getFile(i)}>
                    <p style={{ textWrap: "wrap", color: "blue" }}>{i.fileName}</p>
                  </OwcHyperlink>
                </div>
              );
            })
        : EN_DASH}
    </div>
  )
};

export const runHeadingDetailCell = {
  label: LOGBOOK_LABEL.run_detail_heading,
  props: {
    fullRow: true
  }
};
export const actionHeadingDetailCell = {
  label: LOGBOOK_LABEL.action_detail_heading,
  props: {
    fullRow: true
  }
};
