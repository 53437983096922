import { Tooltip, IconButton } from "@mui/material";
import { OwcIcon } from "@one/react";
import { useState } from "react";
import { SHOW_HIERARCHY } from "../../constants";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";

export const ClusterActions = ({
  item,
  triggerClusterSetCollapse,
  viewExpander = true
}: {
  item: DigitalLabLogbookInstrumentModelType;
  triggerClusterSetCollapse: (open: boolean) => void;
  viewExpander: boolean;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div style={{ display: "flex" }}>
      {viewExpander && (
        <Tooltip data-testid="cluster-expand-button-tooltip" title={SHOW_HIERARCHY} placement="left" arrow>
          <IconButton
            aria-label="expand row"
            onClick={() => {
              setOpen(!open);
              if (triggerClusterSetCollapse) {
                triggerClusterSetCollapse(!open);
              }
            }}
          >
            {open ? (
              <OwcIcon
                name="caret_up"
                type="legacy"
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            ) : (
              <OwcIcon
                name="caret_down"
                type="legacy"
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            )}
          </IconButton>
        </Tooltip>
      )}
      <OwcIcon
        id={`cluster-identification-icon-${item?.inventoryId}`}
        className="one-icons"
        style={{
          marginTop: 10
        }}
        name="rack"
        type="legacy"
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      />
    </div>
  );
};
