import { LOG_SHEET_CHANGE_BY_LOG_SHEET_ENTRY_ID } from "../../../../gql/logBooksapi/queries";

export const ImportHistoryOfLogSheet = async (logSheet, { client }) => {
  const { data } = await client.query({
    query: LOG_SHEET_CHANGE_BY_LOG_SHEET_ENTRY_ID,
    fetchPolicy: "no-cache",
    variables: {
      logSheetEntryId: logSheet.id
    }
  });
  return data;
};
