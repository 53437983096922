import { useSelector } from "react-redux";
import { emptyLogSheet } from "../log-sheet/log-sheet-form/empty-log-sheet";
import { fetchLogSheets } from "../log-sheet/fetch-log-sheets";
import { HistoryLogSheetCellsInfoArray } from "../log-sheet/log-sheet-history/HistoryLogSheetCellsInfoArray";
import { ImportHistoryOfLogSheet } from "../log-sheet/log-sheet-history/ImportHistoryOfLogSheet";
import { CreateLogSheetChange } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import {
  AttachmentDocs,
  LogSheetTableMeta,
  LogSheetTableEquipmentCentricMeta
} from "../log-sheet/log-sheet-table/LogSheetTableMeta";
import { logSheetValidationSchemaFactory } from "../log-sheet/log-sheet-form/log-sheet-validation-schema";
import { makeCleanLogSheet } from "../log-sheet/log-sheet-form/make-clean-log-sheet";
import { logSheetItemsToSheet, runSheetItemsToPDF } from "../log-sheet/log-sheet-export/log-sheet-items-to-sheet";
import { logSheetSelectSuggestion } from "../log-sheet/log-sheet-form/log-sheet-select-suggestion";
import { logSheetClearSuggestion } from "../log-sheet/log-sheet-form/log-sheet-clear-suggestion";
import ItemPage from "./ItemPage";
import { Attachments } from "../common-for-log-sheet-and-run-logs/HistoryCommonCellsInfo";
import ActionDataInputsBox from "../log-sheet/log-sheet-form/ActionDataInputsBox";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";
import { formTypes, LOG_TYPES, LOGBOOK_ATTACHMENTS } from "../../../constants";
import { useParams } from "react-router-dom";
const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const LogSheetPage = ({ equipmentDetail }) => {
  const { inventoryId } = useParams();
  const storeUserSite = useSelector((store) => store.user?.site);

  // adding conditionally attachments for selected sites
  if (
    !LogSheetTableMeta.expandedComponentProps.infoMeta.fields.some(
      (el) => el.lookapVal === LOGBOOK_ATTACHMENTS.ATTACHMENTS
    ) &&
    attachmentSites.includes(storeUserSite)
  ) {
    LogSheetTableMeta.expandedComponentProps.infoMeta.fields.push(AttachmentDocs);
  }
  if (
    !HistoryLogSheetCellsInfoArray.some((el) => el.label === Attachments.label) &&
    attachmentSites.includes(storeUserSite)
  ) {
    HistoryLogSheetCellsInfoArray.push(Attachments);
  }
  if (
    !LogSheetTableEquipmentCentricMeta.expandedComponentProps.infoMeta.fields.some(
      (el) => el.lookapVal === LOGBOOK_ATTACHMENTS.ATTACHMENTS
    ) &&
    attachmentSites.includes(storeUserSite)
  ) {
    LogSheetTableEquipmentCentricMeta.expandedComponentProps.infoMeta.fields.push(AttachmentDocs);
  }

  // Action Logs audit trail dialog auditTrail
  return (
    <ItemPage
      addButtonLabel={LOGBOOK_LABEL.BUTTON.add_new_log}
      queryName="logSheetChangeByLogSheetEntryId"
      importFn={ImportHistoryOfLogSheet}
      CellsInfoArray={HistoryLogSheetCellsInfoArray}
      title={LOGBOOK_LABEL.action_logs_history_heading}
      dataTestId="log-sheet"
      type={formTypes?.ACTION_LOG}
      fetchingFn={fetchLogSheets}
      mapFn={logSheetItemsToSheet}
      mapSecondFn={runSheetItemsToPDF}
      fileNamePrefix="action-logs-report"
      pageTitle={LOGBOOK_LABEL.PAGE_TITLE.log_books}
      orderBy="actionDate"
      meta={inventoryId ? LogSheetTableEquipmentCentricMeta : LogSheetTableMeta}
      emptyItem={emptyLogSheet}
      cleanItemFn={makeCleanLogSheet}
      createItemChangeFn={CreateLogSheetChange}
      validationSchemaFactory={logSheetValidationSchemaFactory}
      selectSuggestionFn={logSheetSelectSuggestion}
      clearSuggestionFn={logSheetClearSuggestion}
      showChipBox={true}
      addNewDialogButtonLabel={LOG_TYPES.ADD_ACTION_LOG}
      equipmentDetail={equipmentDetail}
    >
      <ActionDataInputsBox />
    </ItemPage>
  );
};

export default LogSheetPage;
