import { find } from "lodash";
import {
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_MYLAB_FILTER,
  DEFAULT_PAGE_SIZE,
  GXPemptyInstrumentsOptions,
  LOOGBOOK_CONFIG,
  LP_CONFIG,
  MY_LAB,
  REPO_CONFIG,
  equipmentStatusOptions,
  systemStatusOptions
} from "../../constants";
import {
  LIST_IR_CATEGORY_MAPPINGS_MASTER,
  LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER,
  LIST_IR_FILTER_LOOKUPS_MASTER,
  LIST_IR_GROUP_MASTER,
  LIST_IR_LINKED_INSTANCE_MASTER,
  LIST_IR_SOP_MASTER,
  LIST_USER_DISPLAY_COLUMNS,
  LOGBOOK_GET_LOGGED_USER_DETAILS,
  LP_GET_LOGGED_USER_DETAILS_USER_FILTERS
} from "../../gql/logBooksapi";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { getAllData } from "../../utils/helpers/fetching";
import { getSortedUniqueList, getUniqValuesWithCaseInsensitive, sortedList, uniqList } from "../../utils/helpers/text";

export const equipmentCategoryMappings = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_CATEGORY_MAPPINGS_MASTER,
    dataPath: ["data", "listIRCategoryMappings"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
};
export const clusterCategoryMappings = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER,
    dataPath: ["data", "listIRClusterCategoryMappings"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
};

export const getGroupMaster = async (client) => {
  const groups = await getAllData({
    client,
    query: LIST_IR_GROUP_MASTER,
    dataPath: ["data", "listIRGroupMasters"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
  return { items: uniqList(groups.items, "value") || [] };
};

export const getResponsibleMaster = async (client) => {
  const responsiblePresons = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "responsiblePerson" },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
  return { items: uniqList(responsiblePresons.items, "filterValue") || [] };
};

export const getTagsMaster = async (client) => {
  const tags = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "tags" },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
  return { items: uniqList(tags.items, "filterValue") || [] };
};
export const getEquipmentAdministratorMaster = async (client) => {
  const equipmentAdministrators = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "equipmentAdministrator" },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
  return {
    items: uniqList(equipmentAdministrators?.items, "filterValue") || []
  };
};
export const getLinkedInstanceMaster = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_LINKED_INSTANCE_MASTER,
    dataPath: ["data", "listSupportedLinkedInstances"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
};
export const getSopMaster = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_SOP_MASTER,
    dataPath: ["data", "listIRSOPMasters"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
};
export const getSiteMaster = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_SOP_MASTER,
    dataPath: ["data", "listSites"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: REPO_CONFIG
  });
};
export const getLPMyLabFilter = async (client) => {
  const result = await getAllData({
    client,
    query: LP_GET_LOGGED_USER_DETAILS_USER_FILTERS,
    dataPath: ["data", "getLoggedInUserDetails"],
    variables: { limit: 1000 },
    drillData: true,
    awsAppSyncConfig: LP_CONFIG
  });
  const resultObj = result?.items[0];
  const myLabFilter = find(resultObj?.userFilters?.items, {
    filterName: MY_LAB
  });

  const myLabFilterFilterdefinition =
    typeof myLabFilter?.filterDefinition === "string"
      ? JSON.parse(myLabFilter?.filterDefinition)
      : myLabFilter?.filterDefinition;

  if (myLabFilter && myLabFilterFilterdefinition?.inventoryIds?.length > 0) {
    return {
      ...DEFAULT_MYLAB_FILTER,
      filterDefinition: {
        ...DEFAULT_MYLAB_FILTER?.filterDefinition,
        inventoryIds: myLabFilterFilterdefinition?.inventoryIds ?? null
      }
    };
  }

  return null;
};

export const getUserDisplayColumn = async ({ client, userID }) => {
  const result = await getAllData({
    client,
    query: LIST_USER_DISPLAY_COLUMNS,
    dataPath: ["data", "listUserDisplays"],
    variables: { limit: 1000, userID: userID },
    drillData: true,
    awsAppSyncConfig: LOOGBOOK_CONFIG
  });
  const list = result?.items ?? [];
  return list.map((item) => {
    if (typeof item.displayDefinition === "string") {
      item.displayDefinition = JSON.parse(item.displayDefinition);
    }
    return item;
  });
};

export const getUserDetail = async (client) => {
  const result = await getAllData({
    client,
    query: LOGBOOK_GET_LOGGED_USER_DETAILS,
    dataPath: ["data", "getLoggedInUserDetails"],
    drillData: true,
    awsAppSyncConfig: LOOGBOOK_CONFIG
  });
  const user = result?.items[0];
  const localFilterList = user?.userFilters?.items ?? [];
  const userFilters = localFilterList.map((item) => {
    if (typeof item.filterDefinition === "string") {
      item.filterDefinition = JSON.parse(item.filterDefinition);
    }
    return item;
  });
  if (user?.lastFilter) {
    user.lastFilter = typeof user?.lastFilter === "string" ? JSON.parse(user?.lastFilter) : user?.lastFilter;
  }
  if (user?.lastDisplayColumns) {
    var obj =
      typeof user?.lastDisplayColumns === "string" ? JSON.parse(user?.lastDisplayColumns) : user?.lastDisplayColumns;
    user.lastDisplayColumns = obj?.equipmentList;
  }

  const lastFavFilter = user?.lastFilter?.lastFavFilter ?? DEFAULT_FILTER;
  const lastDisplayColumns = user?.lastDisplayColumns ?? DEFAULT_COLUMNS;
  const lastPageSize = user?.lastPageSize ?? DEFAULT_PAGE_SIZE;
  return {
    userFilters: userFilters,
    lastFavFilter: lastFavFilter,
    lastDisplayColumns: lastDisplayColumns,
    lastPageSize: lastPageSize
  };
};
export const getAllMasterFilterData = async ({ client, site, userID }) => {
  return Promise.all([
    equipmentCategoryMappings(client),
    clusterCategoryMappings(client),
    getGroupMaster(client),
    getResponsibleMaster(client),
    getTagsMaster(client),
    getEquipmentAdministratorMaster(client),
    getLinkedInstanceMaster(client),
    getSopMaster(client),
    getSiteMaster(client),
    getLPMyLabFilter(client),
    getUserDisplayColumn({ client: client, userID: userID }),
    getUserDetail(client, site)
  ]).then((response) => {
    const equipmentCategoryMappingConfigurations = response[0];
    const clusterCategoryMappingConfigurations = response[1];
    const groupMasters = response[2];
    const filterLookups = response[3];
    const tagsMasters = response[4];
    const equipmentAdministrators = response[5];
    const linkedInstanceMasters = response[6];
    const sopMasters = response[7];
    const siteMasters = response[8];
    const myLabFilterMaster = response[9];
    const userDisplayColumnMaster = response[10];
    const userDetail = response[11];

    const masterData = {
      listIRCategoryMappingConfigurations: {
        items: equipmentCategoryMappingConfigurations.items
      },

      listIRManufacturerMasters: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "manufacturer")
      },
      listIREquipmentVariant: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "variant")
      },
      listIRClusterVariant: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "variant")
      },
      listIRGroupMasters: {
        items: sortedList(groupMasters.items, ["value"])
      },
      listIRSOPMasters: {
        items: sortedList(sopMasters.items, ["value"])
      },
      listIRequipmentModelConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIRclusterModelConfigurations: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIREquipmentCategoryConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRClusterCategoryMappings: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRFilterLookups: {
        items: sortedList(filterLookups.items, ["filterValue"])
      },
      listIRTags: {
        items: sortedList(tagsMasters.items, ["filterValue"])
      },
      listIREquipmentAdministrators: {
        items: sortedList(equipmentAdministrators?.items, ["filterValue"])
      },
      listLinkedInstanceMasters: {
        items: sortedList(linkedInstanceMasters.items, ["linkedInstanceDisplay"])
      }
    };

    const masterDataFilter = {};
    masterDataFilter[DATA_MODEL_TABLE?.equipmentCategory?.key] = [
      ...getUniqValuesWithCaseInsensitive(masterData?.listIREquipmentCategoryConfigurations?.items),
      ...getUniqValuesWithCaseInsensitive(masterData?.listIRClusterCategoryMappings?.items)
    ];
    masterDataFilter[DATA_MODEL_TABLE?.belongingToGroup?.key] = getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(masterData?.listIRGroupMasters?.items, "value")
    );
    masterDataFilter[DATA_MODEL_TABLE?.manufacturer?.key] = getUniqValuesWithCaseInsensitive(
      masterData?.listIRManufacturerMasters?.items
    );
    masterDataFilter[DATA_MODEL_TABLE?.responsiblePerson?.key] = getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(masterData?.listIRFilterLookups?.items, "filterValue")
    );
    masterDataFilter[DATA_MODEL_TABLE?.equipmentAdministrator?.key] = getUniqValuesWithCaseInsensitive(
      getSortedUniqueList(masterData?.listIREquipmentAdministrators?.items, "filterValue")
    );
    masterDataFilter[DATA_MODEL_TABLE?.siteName?.key] = getSortedUniqueList([siteMasters?.items], "siteName");
    masterDataFilter[DATA_MODEL_TABLE?.tags?.key] = getSortedUniqueList(masterData?.listIRTags?.items, "filterValue");
    masterDataFilter[DATA_MODEL_TABLE?.linkedInstance?.key] = getUniqValuesWithCaseInsensitive(
      masterData?.listLinkedInstanceMasters?.items?.map((linkedInstance) => linkedInstance?.linkedInstanceDisplay)
    );

    masterDataFilter[DATA_MODEL_TABLE?.equipmentModel?.key] = getUniqValuesWithCaseInsensitive([
      ...masterData?.listIRequipmentModelConfigurations?.items,
      ...masterData?.listIRclusterModelConfigurations?.items
    ]);

    masterDataFilter[DATA_MODEL_TABLE?.variant?.key] = getUniqValuesWithCaseInsensitive([
      ...masterData?.listIREquipmentVariant?.items,
      ...masterData?.listIRClusterVariant?.items
    ]);

    masterDataFilter[DATA_MODEL_TABLE?.csv?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.csv?.key]?.map((obj) => obj?.value)
    );
    masterDataFilter[DATA_MODEL_TABLE?.electronicRecord?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.electronicRecord?.key]?.map((obj) => obj?.value)
    );
    masterDataFilter[DATA_MODEL_TABLE?.electronicSignatures?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.electronicSignatures?.key]?.map((obj) => obj?.value)
    );
    masterDataFilter[DATA_MODEL_TABLE?.qualificationStatus?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.qualificationStatus?.key]?.map((obj) => obj?.value)
    );
    masterDataFilter[DATA_MODEL_TABLE?.controlledEquipmentStatus?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.controlledEquipmentStatus?.key]?.map((obj) => obj?.value)
    );
    masterDataFilter[DATA_MODEL_TABLE?.gxpRelevant?.key] = sortedList(
      GXPemptyInstrumentsOptions[DATA_MODEL_TABLE?.gxpRelevant?.key]?.map((obj) => obj?.value)
    );

    masterDataFilter[DATA_MODEL_TABLE?.sop?.key] = getSortedUniqueList(masterData?.listIRSOPMasters?.items, "value");

    masterDataFilter[DATA_MODEL_TABLE?.systemStatus?.key] = systemStatusOptions;
    masterDataFilter[DATA_MODEL_TABLE?.status?.key] = equipmentStatusOptions;

    let linkedInstanceMappingObj = {};
    masterData?.listLinkedInstanceMasters?.items.forEach((item) => {
      linkedInstanceMappingObj[item?.linkedInstance] = item?.linkedInstanceDisplay;
    });

    return {
      masterFiltersList: masterDataFilter,
      myLabFilterMaster: myLabFilterMaster,
      userDetail: userDetail,
      userDisplayColumnMaster: userDisplayColumnMaster
    };
  });
};
