import { OwcTimepicker } from "@one/react";
import React from "react";

const CustomTimePicker = ({ time, setTime, style }) => {
  return (
    <OwcTimepicker
      variant="filled"
      style={style}
      step={1}
      format={"hh:mm aa"}
      placeholder={"HH:MM"}
      label={"Time"}
      value={time ?? null}
      onValueChange={(e) => setTime(e.detail)}
    />
  );
};

export default CustomTimePicker;
