import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import { entryType } from "../../constants";

const checkShouldKeepFetching = (items, totalCount) => {
  return items.length < totalCount;
};

const fetchOne = async ({ variables, items }) => {
  const result = await CloudSearchConfig(variables);
  items = [...items, ...result?.data];
  let totalCount = result?.count;
  const shouldKeepFetching = checkShouldKeepFetching(items, totalCount);

  return { items, shouldKeepFetching, totalCount };
};

export const getAllCloudSearchData = async ({ requestObj = {} }) => {
  try {
    let items = [];
    let shouldKeepFetching = true;
    do {
      ({ items, shouldKeepFetching } = await fetchOne({
        variables: {
          negatefield: requestObj?.negatefield || "-cluster_id:*",
          sort: requestObj?.sort || "asc",
          sortBy: requestObj?.sortBy || "serial_number",
          start: items?.length,
          size: requestObj?.size || 10000,
          searchValue:
            requestObj?.searchValue ||
            `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
        },
        items
      }));
    } while (shouldKeepFetching);
    return {
      items,
      error: null
    };
  } catch (err) {
    return { items: [], error: err };
  }
};

export const getMegaClusterIds = async () => {
  const requestObj = {
    negatefield: "-cluster_id:*",
    sort: "asc",
    sortBy: "serial_number",
    start: 0,
    size: 10000,
    searchValue: `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
  };
  let result = await getAllCloudSearchData({ requestObj });
  let mclusterIds = [];
  if (result?.items?.length > 0) {
    mclusterIds = result?.items?.map((item) => item?.cluster_id[0]);
  }
  return mclusterIds;
};
