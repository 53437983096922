import React from "react";

import { fetchChangeLogSheets } from "../log-sheet/fetch-change-log-sheets";
import { runSheetItemsToPDF } from "../log-sheet/log-sheet-export/change-log-items-to-sheet";
import ChangeLogItemPage from "./ChangeLogItemPage";
import ActionDataInputsBox from "../log-sheet/log-sheet-form/ActionDataInputsBox";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";

const ChangeLogSheetPage = ({ equipmentDetail }) => {
  return (
    <ChangeLogItemPage
      dataTestId="change-logs"
      type="change"
      fetchingFn={fetchChangeLogSheets}
      mapSecondFn={runSheetItemsToPDF}
      fileNamePrefix="change-logs-report"
      pageTitle={LOGBOOK_LABEL.PAGE_TITLE.change_logs}
      raportTitle={`Change log - ${equipmentDetail?.shortIdentifier}`}
    >
      <ActionDataInputsBox />
    </ChangeLogItemPage>
  );
};

export default ChangeLogSheetPage;
