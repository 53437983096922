export const emptyRunLog = {
  approverId: null,
  approverUserName: null,
  assay: [],
  defectId: null,
  description: null,
  editReason: "",
  editComment: "",
  equipmentId: "",
  id: null,
  equipmentModel: "",
  gxpReady: {
    key: null,
    value: null
  },
  inventoryId: "",
  materialNumber: null,
  mediaType: [],
  modelVersion: null,
  numberOfRuns: 1,
  operatorId: "",
  operatorUserName: "",
  runEndDate: new Date(),
  runEndTime: null,
  runIdentification: null,
  runLogEntryId: null,
  runStartDate: new Date(),
  runStartTime: null,
  runStatus: null,
  samplesProcessed: null,
  serialNumber: null,
  site: null,
  systemStatus: {
    key: null,
    value: null
  },
  tipsUsed: null,
  eLNid: null,
  instrumentDetails: {
    configurationBaseline: null,
    instrumentGxPStatus: null,
    equipmentNickName: null,
    responsiblePerson: null,
    manufacturer: null,
    softwareVersion: null,
    location: null,
    responsibleProxy: null
  }
};

export const emptyRunLogCluster = {
  approverId: null,
  approverUserName: null,
  assay: [],
  defectId: null,
  description: null,
  equipmentId: "",
  id: null,
  equipmentModel: "",
  gxpReady: {
    key: null,
    value: null
  },
  gxpReadyCluster: {
    key: null,
    value: null
  },
  inventoryId: "",
  materialNumber: null,
  mediaType: [],
  modelVersion: null,
  numberOfRuns: 1,
  operatorId: "",
  operatorUserName: "",
  runEndDate: new Date(),
  runEndTime: null,
  runIdentification: null,
  runLogEntryId: null,
  runStartDate: new Date(),
  runStartTime: null,
  runStatus: null,
  samplesProcessed: null,
  serialNumber: null,
  site: null,
  systemStatus: {
    key: null,
    value: null
  },
  systemStatusCluster: {
    key: null,
    value: null
  },
  tipsUsed: null,
  eLNid: null,
  instrumentDetails: {
    configurationBaseline: null,
    instrumentGxPStatus: null,
    equipmentNickName: null,
    responsiblePerson: null,
    manufacturer: null,
    softwareVersion: null,
    location: null,
    responsibleProxy: null
  },
  editReason: "",
  editComment: "",
  subEquipment: []
};
