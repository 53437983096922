import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import { Button } from "@mui/material";
import { OwcIcon, OwcProgressSpinner, OwcTypography } from "@one/react";
import DLabGrid from "../DLabGrid/DLabGrid";
import styled from "styled-components";
import UploadFilesIcon from "../../icons/UploadFilesIcon";
import { withApollo } from "react-apollo";
import { compose } from "redux";
import { connect } from "react-redux";
import { GET_EXPORT_FILE_TO_ENDOR } from "../../gql/logBooksapi";
import { useFormikContext } from "formik";
import { ItemDialogFormContext } from "../../features/log-book/log-book-item-form-dialog/item-context/context";
import { ACTION_TYPE, FILE_STATE } from "../../features/log-book/log-book-item-form-dialog/item-context/ItemWrapper";
import { Notify } from "@digitallab/grid-common-components";

const FILE_MAX_NR = 10;

const INFO = [
  { label: "Maximum file size", value: "10 MB" },
  { label: "Supported formats", value: "ALL" },
  { label: "Maximum number of files", value: `${FILE_MAX_NR}` }
];

const PROGRESS = "Progress";
const Wrapper = styled.div`
  .ag-root-wrapper,
  .ag-theme-DLab {
    border: none;
  }
`;

const Container = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

const FILE_MAX_SIZE = 10_000_000;

const SIZES = ["B", "KB", "MB", "GB"];
const SIZES_VALUES = {
  B: 1,
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000
};
const getUnit = (value) => SIZES[Math.floor(Math.log2(value) / 10)];

const ProgressStatus = ({ icon, text }) => {
  return (
    <span
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {icon}
      {text}
    </span>
  );
};

const LoadingIcon = (
  <OwcProgressSpinner
    style={{
      height: 24,
      width: 24,
      marginTop: "0px"
    }}
    diameter={24}
  />
);

const FILE_STATE_AG_GRID = {
  [FILE_STATE.IDLE]: <ProgressStatus icon={LoadingIcon} text={"Uploading"} />,
  [FILE_STATE.UPLOAD]: (
    <ProgressStatus icon={<OwcIcon name="circle_confirm" type="filled" variant="success" />} text={"Uploaded"} />
  ),
  [FILE_STATE.PREUPLOAD]: <ProgressStatus icon={LoadingIcon} text={"Uploading"} />,
  [FILE_STATE.PREUPLOAD_URL]: <ProgressStatus icon={LoadingIcon} text={"Uploading"} />,
  [FILE_STATE.FINISH]: (
    <ProgressStatus icon={<OwcIcon name="circle_confirm" type="filled" variant="success" />} text={"Uploaded"} />
  ),
  [FILE_STATE.MAX_SIZE]: (
    <ProgressStatus icon={<OwcIcon name="circle_clear" type="filled" variant="error" />} text={"File too large"} />
  )
};

const COL_DATA = [
  { field: "Description", editable: true, flex: 4, wrapText: true },
  { field: "File name", flex: 4 },
  { field: "File size", flex: 3 },
  {
    field: PROGRESS,
    flex: 4,
    cellRenderer: (item) => FILE_STATE_AG_GRID[item.value]
  },
  {
    field: "",
    flex: 2,
    cellRenderer: () => <OwcIcon name="circle_clear" type="outlined" style={{ marginTop: "6px", cursor: "pointer" }} />
  }
];

const parseSize = (size) => {
  const unit = getUnit(size);
  const value = Math.round((size * 100) / SIZES_VALUES[unit]) / 100;
  return `${value} ${unit}`;
};

const calculateProgress = (file) => (file.size > FILE_MAX_SIZE ? FILE_STATE.MAX_SIZE : FILE_STATE.IDLE);

const parseFileData = (file) => {
  const progress = calculateProgress(file);

  return {
    "File name": file.name,
    "File size": parseSize(file.size),
    Description: file.name,
    Progress: progress,
    url: null,
    s3FileName: null
  };
};

const notifyFileUploadError = () => {
  Notify({
    type: "warning",
    icon: "alarm",
    text: "Try again later or add log without attachment(s)",
    title: "Error in adding file(s) to the log"
  });
};

function ConfirmDialogFiles({ open = false, close = () => {}, client, user, onSave = () => {} }) {
  const formik = useFormikContext();
  const [areFilesSaving, setAreFilesSaving] = useState(false);
  const inputRef = useRef(null);
  const { files, setFiles, setFilesData } = useContext(ItemDialogFormContext);
  const [filesLocal, setFilesLocal] = useState([]);

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  /**
   * handles adding files from chrome files list to dialog
   * @param {Event} e event
   * @returns void
   */
  const uploadFilesHandler = (e) => {
    const currentFiles = [...e.target.files].map((file) => ({
      ...parseFileData(file),
      file
    }));
    if (checkMaxFile([...currentFiles, ...filesLocal])) {
      return;
    }
    setFilesLocal([...filesLocal, ...currentFiles]);
    // resetting files in input type, so we can re-upload it after deletion
    inputRef.current.value = null;
  };

  /**
   * function that executes lambda getExportFileToEndor
   * @param {Object} file file object
   * @param {String} type type of action
   * @returns {Promise} data from lambda
   */
  const uploadAppsync = async (file, type = "PREUPLOAD") => {
    const { data } = await client.query({
      query: GET_EXPORT_FILE_TO_ENDOR,
      fetchPolicy: "no-cache",
      variables: {
        actionType: type,
        targetModule: "LOGBOOK",
        s3id: file.s3FileName || file.file.name,
        fileDescription: file["Description"],
        metadata: `{"parentId": "${formik.values.logSheetEntryId}", "userId": "${user.user}"}`
      }
    });
    const exportFileToEndorData = data.getExportFileToEndor;
    const statusCode = exportFileToEndorData.StatusCode;
    if (statusCode !== 200) {
      notifyFileUploadError();
    }
    return exportFileToEndorData;
  };

  const uploadToS3 = async (file) => {
    const { status } = await fetch(file.url, {
      method: "PUT",
      body: file.file,
      headers: {
        "Content-Type": file.file.type
      }
    });
    if (status !== 200) {
      notifyFileUploadError();
    }
    file[PROGRESS] = FILE_STATE.UPLOAD;
    setFilesLocal([...filesLocal]);
  };

  const uploadFilesToS3 = async (preuploadedFiles) => {
    const s3upload = preuploadedFiles.map((file) => uploadToS3(file));
    await Promise.allSettled(s3upload);
  };

  /**
   * checks if nr of files added is not exceeded, if yes, shows toast
   * @param {Array} currentFiles list of files
   * @returns boolean, true if exceeds, false if within limits
   */
  const checkMaxFile = (currentFiles) => {
    if (currentFiles.length > FILE_MAX_NR) {
      notifyTooManyFiles();
      return true;
    }
    return false;
  };

  /**
   * sends notification to toaster
   */
  const notifyTooManyFiles = () => {
    Notify({
      type: "info",
      icon: "alarm",
      text: `You can choose maximum ${FILE_MAX_NR} files`,
      title: ""
    });
  };

  /**
   * function taht fetches s3 pre-upload links and sets initial metadata alongisde with lsit of files
   * @param {Array} idleFiles list of files to process
   * @returns void
   */
  const preuploadAll = async (idleFiles) => {
    const preupload = idleFiles.map((file) =>
      calculateProgress(file.file) === FILE_STATE.MAX_SIZE || file[PROGRESS] === FILE_STATE.UPLOAD
        ? Promise.reject()
        : uploadAppsync(file)
    );
    const preuploadDone = await Promise.allSettled(preupload);
    idleFiles.forEach((file, index) => {
      file.url = preuploadDone[index].value?.url || file.url;
      file[PROGRESS] = preuploadDone[index].value?.url ? FILE_STATE.PREUPLOAD_URL : file[PROGRESS];
      file.s3FileName = preuploadDone[index].value?.s3FileName || file.s3FileName;
    });
    setFilesLocal((files) => [...files]);
  };

  const onApprove = async () => {
    setAreFilesSaving(true);
    const filesToUpdate = filesLocal.filter((file) => file[PROGRESS] === FILE_STATE.UPLOAD);
    const update = filesToUpdate.map((file) => uploadAppsync(file, ACTION_TYPE.UPLOAD));
    const data = await Promise.allSettled(update);
    setAreFilesSaving(false);
    setFiles([...files, filesToUpdate]);
    setFilesData(data.map(({ value }) => value?.data).filter((item) => !!item));
    onSave(filesLocal);
  };

  const approveText = useMemo(() => {
    return areFilesSaving ? "Saving..." : "Add files";
  }, [areFilesSaving]);

  const onDelete = (agItem) => {
    if (agItem.colDef.field !== "") {
      return;
    }
    const updatedFiles = [...filesLocal].filter(({ s3FileName }) => agItem.data.s3FileName !== s3FileName);
    setFilesLocal(updatedFiles);
  };

  const removeAllFiles = () => {
    setFilesLocal([]);
  };

  const onClose = useCallback(() => {
    const cleanUpFiles = [...filesLocal].filter((file) => file[PROGRESS] === FILE_STATE.FINISH);
    setFilesLocal(cleanUpFiles);
    close();
  }, [setFilesLocal, close, filesLocal]);

  useEffect(() => {
    const filesToPreUpload = filesLocal.filter((file) => file[PROGRESS] === FILE_STATE.IDLE);
    if (filesToPreUpload.length === 0) {
      return;
    }
    preuploadAll(filesLocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesLocal]);

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator    return !urlPattern.test(url);
    return !!urlPattern.test(url);
  };

  useEffect(() => {
    const filesToUploadToS3 = filesLocal.filter(
      (file) => file[PROGRESS] === FILE_STATE.PREUPLOAD_URL && isValidUrl(file.url)
    );
    if (filesToUploadToS3.length === 0) return;
    uploadFilesToS3(filesToUploadToS3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesLocal]);

  /**
   * sets color for remove buttons
   * @returns string
   */
  const setColor = () => {
    return filesLocal.length === 0 ? "default" : "primary";
  };

  // modify fileName value for displaying purposes only
  const filesToShow = filesLocal.map((i) => {
    i["File name"] = `${i["File name"].substring(0, 10)}...${i["File name"].split(".").at(-1)}`;
    return i;
  });

  const isAddButtonEnabled = filesLocal.length > 0 && filesLocal.every((i) => i[PROGRESS] === FILE_STATE.UPLOAD);

  return (
    <ConfirmDialog
      onApproveAsync={onApprove}
      open={open}
      close={onClose}
      cancelText="Cancel"
      title="Add files"
      size="md"
      approveText={approveText}
      isEnabled={isAddButtonEnabled}
      disableBackdropClick={true}
    >
      <input ref={inputRef} type="file" hidden onChange={uploadFilesHandler} multiple />
      <Container style={{ backgroundColor: "#f5f5f2", padding: "32px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer"
          }}
          onClick={onUploadClick}
        >
          <UploadFilesIcon />
        </div>
        <div style={{ display: "flex", gap: "18px", justifyContent: "center" }}>
          {INFO.map(({ label, value }, index) => (
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "baseline"
              }}
              key={`files${index}`}
            >
              <OwcTypography variant="body1">
                <span style={{ fontSize: "12px", color: "#706B69" }}>{label}</span>
              </OwcTypography>
              <OwcTypography variant="body1">
                <strong style={{ fontSize: "12px" }}>{value}</strong>
              </OwcTypography>
            </div>
          ))}
        </div>
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "12px",
          marginBottom: "12px"
        }}
      >
        <OwcTypography variant="body2">
          <strong>Files {filesLocal.length > 0 && <span>({filesLocal.length})</span>}</strong>
        </OwcTypography>

        <OwcTypography variant="body2">
          <span>
            <Button
              flat
              variant="text"
              disabled={filesLocal.length === 0}
              style={{
                textTransform: "none",
                borderStyle: "none",
                backgroundColor: "transparent",
                color: setColor() === "primary" ? "blue" : "grey"
              }}
              onClick={() => {
                removeAllFiles();
              }}
            >
              <span>Remove all files</span>
              <OwcIcon name="circle_clear" type="outlined" variant fill={setColor()} />
            </Button>
          </span>
          <span></span>
        </OwcTypography>
      </div>
      <Container>
        <Wrapper>
          <DLabGrid
            overlayNoRowsTemplate={"<span> Uploaded files will appear here </span>"}
            pagination={false}
            columnDefs={COL_DATA}
            onCellClicked={(item) => onDelete(item)}
            rowData={filesToShow}
          />
        </Wrapper>
      </Container>
    </ConfirmDialog>
  );
}

const mapStateToProps = (store) => ({
  user: store.user
});

export default compose(connect(mapStateToProps, {}), withApollo)(ConfirmDialogFiles);
