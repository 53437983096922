const LOGBOOK_LABEL = {
  app_name: "Equipment Log Book",
  PAGE_TITLE: {
    run_logs: "Run logs",
    log_books: "Action logs",
    change_logs: "Change logs",
    all_logs: "All logs"
  },
  TAB: {
    run_logs: "Run logs",
    action_logs: "Action logs",
    change_logs: "Change logs",
    all_logs: "All logs"
  },
  CLUSTER_TABS: {
    attribute: "Attribute",
    hierarchy: "Hierarchy"
  },
  BUTTON: {
    add_new_run: "Add Run log",
    add_run: "Add Run log",
    add_new_log: "Add Action log",
    export_file: "Export file",
    export_CSV: "Download CSV",
    download_PDF: "Download PDF",
    open_cover_sheet: "Open cover sheet",
    search: "Search",
    clear_all: "Reset all",
    close: "Close",
    cancel: "Cancel",
    show_snapshot: "Show snapshot",
    clear_snapshot: "Clear snapshot"
  },
  PAGINATION: {
    rows_per_page: "Rows per page: ",
    next_page: "Next page",
    previous_page: "Previous page",
    first_page: "First page"
  },
  SEARCH_FILTER: {
    search_box_label: "Search for Equipment ID or Serial number",
    search_for: "Searching for",
    all_models: "All models",
    date_range: "Date range",
    date_from: "Date from",
    date_to: "Date to"
  },
  STEPPER_LABEL: {
    define_action: "Define action",
    define_new_run: "Define run"
  },
  actions_label: "Actions",
  eqipment_info_heading: "Equipment information",
  eqipment_identification_heading: "Equipment identification",
  run_logs_history_heading: "Log entry information",
  action_logs_history_heading: "Log entry information",
  cover_sheet_heading: "Cover sheet",
  publish_to_ER: "Publish to equipment repository",
  run_data: "Run Details",
  action_data: "Action Details",
  equipmentId_serialNo_message: "Equipment ID or Serial number must be filled",
  equipmentId_serialNo_error_msg:
    "Equipment with provided equipment id and/or serial number does not exist at your current site ",
  equipmentId_serialNo_error_msg_1: ", where ",
  equipmentId_serialNo_error_msg_2: " is the selected site in GRID Home",
  sub_components: "Sub-components",
  sub_equipment_list: "List of sub equipment",
  action_detail_heading: "Action detail",
  run_detail_heading: "Run detail"
};

export default LOGBOOK_LABEL;
