import React from "react";
import NoConnectionIcon from "../icons/NoConnectionIcon";
import { ErrorScreenStyled } from "./NotFoundScreen";
import { OwcButton } from "@one/react";

const NoConnectionScreen = ({
  text = "We are having difficulty connecting to the server. Check your connection or try again later.",
  title = "No connection",
  onCallback
}) => {
  return (
    <ErrorScreenStyled data-testid="main-page-not-authenticated-with-error-conection">
      <NoConnectionIcon />
      <h2>{title}</h2>
      <h5>{text}</h5>
      <OwcButton data-testid="main-page-action-button-error-conection" variant="primary" onClick={onCallback}>
        Retry
      </OwcButton>
    </ErrorScreenStyled>
  );
};

export default NoConnectionScreen;
