import { useFormikContext } from "formik";
import { OwcTimepicker } from "@one/react";
import { changeDateFormat, changeTimeFormat } from "../../../helpers";
import { formTypes } from "../../../../../constants";

const RunTimeInput = ({
  property,
  label,
  testid,
  required = false,
  timeObj,
  setTimeObj,
  errorText = null,
  maxTime = null,
  logType = formTypes?.RUN_LOG
}) => {
  const formik = useFormikContext();
  let actionDateObj = null;
  if (logType === formTypes?.ACTION_LOG) {
    actionDateObj =
      typeof formik?.values?.actionDate === "object"
        ? formik?.values?.actionDate
        : new Date(formik?.values?.actionDate);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <OwcTimepicker
        variant="filled"
        style={{ width: "100%" }}
        step={1}
        format={"hh:mm aa"}
        placeholder={"--:--"}
        label={label + `${required ? " *" : ""}`}
        value={timeObj}
        maxTime={
          logType === formTypes?.ACTION_LOG
            ? changeDateFormat(actionDateObj) === changeDateFormat(new Date())
              ? maxTime
              : null
            : maxTime
        }
        onValueChange={(e) => {
          if (formik?.values?.actionTime !== changeTimeFormat(e.detail)) {
            setTimeObj(e.detail);
            formik.setFieldValue(property, e.detail ? changeTimeFormat(e.detail) : null, true);
          }
        }}
        required={required}
        requiredTimeMessage={formik.errors[property]}
      >
        {formik.errors[property] && <span slot="error-text">{formik.errors[property]}</span>}
      </OwcTimepicker>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          paddingLeft: errorText ? "10px" : "",
          color: "var(--one-color-interaction-default-negative-1)",
          fontSize: "var(--one-text-font-size-rem-0-75)"
        }}
      >
        {errorText}
      </div>
    </div>
  );
};

export default RunTimeInput;
