import { COVER_SHEET_LIST, entryType } from "../../constants";
import { CoverSheetNoDataConatiner } from "./CoverSheetMainPageStyle";
import { FullScreenCentered } from "../log-book/log-book-item-form-dialog/LoadItemForm";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { utcStringToLocaleString } from "../log-book/helpers";
import { OwcTypography } from "@one/react";
import { CircularProgress } from "@mui/material";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";
import { CoverSheetData } from "./CoverSheetData";

const DescriptionDetailView = ({
  equipmentDetail,
  loading,
  snapShotView,
  initialCoverSheetEquipment
}: {
  equipmentDetail: DigitalLabLogbookInstrumentModelType;
  loading: boolean;
  snapShotView: boolean;
  initialCoverSheetEquipment: { [key: string]: unknown };
}) => {
  //@ts-ignore
  const signees = useSelector((state) => state.equipmentModels.signee);

  const userObj = find(signees, {
    user: initialCoverSheetEquipment?.createdBy
  });

  if (loading)
    return (
      <FullScreenCentered data-testid="loading">
        <CircularProgress size={40} />
      </FullScreenCentered>
    );

  if (!equipmentDetail) {
    const equipmentTypeName = initialCoverSheetEquipment?.entryType === entryType.cluster ? "Cluster" : "Equipment";
    return (
      <CoverSheetNoDataConatiner data-testid="no-cover-sheet-data">
        <OwcTypography variant="body1" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          No data for this time stamp. {equipmentTypeName} was created in the repository on{" "}
          {utcStringToLocaleString(initialCoverSheetEquipment?.createdAt, 24)} by user{" "}
          {userObj ? userObj?.name : initialCoverSheetEquipment?.createdBy || "-"}
        </OwcTypography>
      </CoverSheetNoDataConatiner>
    );
  }

  return (
    <div
      style={{
        background: snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)",
        padding: "0px 10px 10px 30px"
      }}
    >
      <CoverSheetData
        equipmentDetail={equipmentDetail}
        snapShotView={snapShotView}
        coverSheetFields={COVER_SHEET_LIST}
      />
    </div>
  );
};

export default DescriptionDetailView;
