import React from "react";
import { generateID } from "@digitallab/grid-common-components";
import { OwcButton, OwcModalDialog, OwcTypography, OwcChip } from "@one/react";
import { replaceEmptyWithHyphen } from "../../../utils/helpers/text";
import { Grid } from "@mui/material";
import { MORE_DETAILS_LIST } from "../../../constants";

const MoreDetailsDialog = ({ moreDialog, setMoreDialog, moreDetails }) => {
  const data = moreDetails;

  const systemStatusRender = (val) => {
    return <OwcChip outlined>{replaceEmptyWithHyphen(val)}</OwcChip>;
  };

  return (
    <OwcModalDialog
      data-testid="more-details-component-dialog"
      size="lg"
      visible={moreDialog}
      className="owcmodalZIndex"
      onVisibleChange={(event) => {
        if (!event?.detail) {
          setMoreDialog(() => false);
        }
      }}
    >
      <div slot="header" data-testid="more-details-dialog-header">
        {data?.equipmentModel}
      </div>
      <div data-testid="more-details-dialog-content">
        <Grid container spacing={2}>
          {data &&
            Object.keys(MORE_DETAILS_LIST).map((x) => (
              // TODO: QS and SS
              <Grid item xs={3}>
                {x === MORE_DETAILS_LIST.systemStatus.key ? (
                  <>
                    <OwcTypography>{MORE_DETAILS_LIST[x].value}</OwcTypography>
                    <OwcTypography>
                      <strong>{systemStatusRender(data[x])}</strong>
                    </OwcTypography>
                  </>
                ) : (
                  <>
                    <OwcTypography>{MORE_DETAILS_LIST[x].value}</OwcTypography>
                    <OwcTypography>
                      <strong>{replaceEmptyWithHyphen(data[x])}</strong>
                    </OwcTypography>
                  </>
                )}
              </Grid>
            ))}
        </Grid>
      </div>
      <div
        slot="actions"
        data-testid="more-details-dialog-actions"
        style={{ displa: "flex", justifyContent: "flex-end" }}
      >
        <OwcButton
          onClick={() => {
            setMoreDialog(() => false);
          }}
          variant="primary"
          data-testid="more-details-ok-button"
          id={generateID.buttonID("moreDetailComponent", "button")}
        >
          Ok
        </OwcButton>
      </div>
    </OwcModalDialog>
  );
};

export default MoreDetailsDialog;
