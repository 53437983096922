import DLabGrid from "../DLabGrid/DLabGrid";
import React, { useRef, useState, useMemo } from "react";
import { OwcIconButton, OwcIcon } from "@one/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { replaceEmptyWithHyphen, validateSelection, valueOrEmpty } from "../../utils/helpers/text";
import { uniq } from "lodash";
import MoreDetailsDialog from "../../features/log-book/log-book-item-form-dialog/MoreDetailsDialog";

export const recursiveCluster = (data) => {
  let tempData = [...data];
  let tempRowList = [tempData[0]];
  let parentArray = [tempData[0]?.inventoryId];
  tempRowList[0].clusterParent = uniq([...parentArray]);
  tempData[0].subEquipment?.forEach((item, index) => {
    let subParentArray = uniq([...parentArray, item?.inventoryId]);
    const subClusterParent = uniq([...subParentArray]);
    tempRowList.push({ ...item, clusterParent: subClusterParent });
    if (item?.subEquipment) {
      item.subEquipment.forEach((element, index1) => {
        const childClusterParent = uniq([...subParentArray, element?.inventoryId]);
        tempRowList.push({ ...element, clusterParent: childClusterParent });
      });
    }
  });
  return tempRowList;
};

const ClusterGridTable = ({ meta, data = [], snapShotDate = "", snapShotTime = "", gxpReadys = [] }) => {
  const { inventoryId } = useParams();
  const gridRef = useRef();
  const [totalRows, setTotalRows] = useState(data?.length);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [dataList, setDataList] = useState([]);
  const [moreDialog, setMoreDialog] = useState(false);
  const [moreDetails, setMoreDetails] = useState([]);

  const handleOptionClick = (paramsinventoryId) => {
    const launchURL = `/cover-sheet/${paramsinventoryId}`;
    window.open(launchURL);
  };

  const actionRender = (params) => {
    return (
      <div style={{ float: "right" }}>
        {inventoryId === params?.data?.inventoryId ? (
          <OwcIconButton
            flat
            aria-label="expand row"
            data-testid={`open-logbook-button-${params?.data?.inventoryId}`}
            type="outlined"
          >
            <OwcIcon aria-label="expand row" data-testid="open-logbook-button-noaction" style={{ marginLeft: 12 }}>
              -
            </OwcIcon>
          </OwcIconButton>
        ) : (
          <div style={{ display: "flex" }}>
            <Tooltip title="More details" arrow>
              <OwcIconButton
                flat
                aria-label="expand row"
                onClick={() => {
                  setMoreDialog(() => true);
                  setMoreDetails(() => params?.data);
                }}
                data-testid={`open-logbook-button-${params?.data?.inventoryId}`}
                icon="element_show"
                type="outlined"
              />
            </Tooltip>
            <Tooltip title="Open log book" arrow>
              <OwcIconButton
                flat
                aria-label="expand row"
                onClick={() => {
                  handleOptionClick(params?.data?.inventoryId);
                }}
                data-testid={`open-logbook-button-${params?.data?.inventoryId}`}
                icon="launch"
                type="outlined"
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };
  const currentGXPStatusRenderer = (params) => {
    return (
      <>
        {snapShotDate && snapShotTime ? (
          <>{replaceEmptyWithHyphen(params?.data?.addInfo?.currentGxPStatusFromLogs)}</>
        ) : (
          <>
            {replaceEmptyWithHyphen(
              validateSelection(gxpReadys, {
                key: params?.data?.qualificationStatus
              })
            )}
          </>
        )}
      </>
    );
  };

  const overrideCellRender = (key, defaultObj) => {
    switch (key) {
      case "currentGxPStatusFromLogs":
        defaultObj = {
          ...defaultObj,
          cellRenderer: currentGXPStatusRenderer
        };
        break;
      default:
        defaultObj = {
          ...defaultObj,
          valueFormatter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
    }
    return defaultObj;
  };
  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in meta.fields) {
      if (key !== "actions" && key !== "clusterIdentifier") {
        defaultObj = {
          field: key,
          headerName: meta.fields[key].text
        };
        defaultObj = overrideCellRender(key, defaultObj);
        colDef.push(defaultObj);
      }
    }

    colDef.push({
      ...defaultObj,
      field: "actions",
      headerName: "Actions",
      maxWidth: 100,
      pinned: "right",
      lockVisible: true,
      cellClass: "action-render",
      cellRenderer: actionRender
    });
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.fields]);
  const onFilterChanged = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };

  const onRowDataUpdated = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };
  const onRowsPerPageChanged = (value) => {
    setRowPerPage(value);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const tempRowList = recursiveCluster(data);
      setDataList(tempRowList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let attr = {
    paginationConfig: {
      rowsPerPage: rowPerPage,
      total: totalRows
    },
    columnDefs: dLabColumnDef,
    animateRows: true,
    rowExport: false,
    suppressContextMenu: true,
    hiddenByDefault: true,
    rowData: dataList,
    onFilterChanged,
    onRowDataUpdated
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      sortable: false,
      minWidth: 200
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      maxWidth: 90,
      cellRendererParams: {
        innerRenderer: (params) => {
          return "";
        },
        suppressCount: true
      },
      pinned: "left"
    };
  }, []);
  const getDataPath = useMemo(() => {
    return (data) => {
      return data.clusterParent;
    };
  }, []);
  const detailCellRenderer = useMemo(() => {
    return () => <div>mater detail</div>;
  }, []);

  return (
    <>
      {moreDialog && (
        <MoreDetailsDialog moreDialog={moreDialog} setMoreDialog={setMoreDialog} moreDetails={moreDetails} />
      )}
      <DLabGrid
        {...attr}
        pagination={false}
        suppressPaginationPanel={false}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        treeData={true}
        animateRows={true}
        groupDefaultExpanded={1}
        detailCellRenderer={detailCellRenderer}
        gridRef={gridRef}
        onRowsPerPageChanged={onRowsPerPageChanged}
        getDataPath={getDataPath}
      />
    </>
  );
};

export default ClusterGridTable;
