import { initialState as paginationInitialState } from "../../../components/shared/pagination/PaginationWrapper";
import { DEFAULT_SITE, formTypes } from "../../../constants";
import { getAllData } from "../../../utils/helpers/fetching";

const getQueryAndFilter = ({
  equipmentModel, //
  signee, //
  sortDirection,
  queryDesc,
  queryAsc,
  instrument,
  dateFrom,
  dateTo,
  queryWithInstrumentDesc,
  queryWithInstrumentAsc,
  inventoryId,
  logType = formTypes?.RUN_LOG,
  signeeList
}) => {
  let currentDate = new Date().toJSON().slice(0, 10);
  let filter = {};
  if (dateFrom >= currentDate && dateTo > currentDate) {
    filter.logStatus = { eq: "ACTIVE" };
  }
  if (equipmentModel) {
    filter.equipmentModel = { eq: equipmentModel };
  }
  // if (signee && signee?.length !== 0) {
  // filter.approverUserName = { eq: signee };
  const tempSignefilter = { or: [] };
  if (signee && signee?.length !== 0 && signee?.length !== signeeList?.length) {
    signee = signee.toString();
    signee.split(",").forEach((element) => {
      const firstLetter = element.charAt(0).toUpperCase();
      const rest = element.slice(1).toLowerCase();
      const SigneeUpper = firstLetter + rest;
      tempSignefilter.or.push({
        approverUserName: {
          eq: element.toLowerCase()
        }
      });
      tempSignefilter.or.push({
        approverUserName: {
          eq: element.toUpperCase()
        }
      });
      tempSignefilter.or.push({
        approverUserName: {
          eq: SigneeUpper
        }
      });
    });
    filter.and = [tempSignefilter];
  }
  // }
  if (logType === formTypes?.ACTION_LOG) {
    if (dateFrom && dateTo) {
      filter.actionDate = { between: [dateFrom, dateTo] };
    }
  }

  if (Object.keys(filter).length === 0) {
    filter = undefined;
  }
  let query = sortDirection === "DESC" ? queryDesc : queryAsc;
  if (instrument || (typeof inventoryId !== "undefined" && inventoryId !== null)) {
    query = sortDirection === "DESC" ? queryWithInstrumentDesc : queryWithInstrumentAsc;
  }
  return { query, filter };
};

const extractVariables = ({ instrument, dateFrom, dateTo, storeUser, limit, nextToken, filter, inventoryId }) => {
  return {
    dateRange: dateFrom === null && dateTo === null ? undefined : [dateFrom, dateTo],
    site: storeUser?.site || DEFAULT_SITE,
    limit: limit || paginationInitialState.limit,
    nextToken,
    filter,
    inventoryId: instrument?.inventoryId ? instrument?.inventoryId : inventoryId,
    dateRangeBegin: dateFrom !== null ? dateFrom : "0001-01-01",
    dateRangeEnd: dateTo !== null ? dateTo : "9999-12-31"
  };
};

export const getUpdatedItems = (items) => {
  const updatedWithEquipIds = items.map((item) => {
    if (item.equipmentId === "null") {
      item.equipmentId = "";
    }

    return item;
  });
  return [...updatedWithEquipIds];
};

export const fetchCommon =
  ({ queryAsc, queryDesc, queryWithInstrumentAsc, queryWithInstrumentDesc, queryName }) =>
  async ({
    client,
    sortDirection,
    equipmentModel,
    signee,
    instrument,
    dateFrom,
    dateTo,
    storeUser,
    limit,
    nextToken,
    drillData = false,
    inventoryId,
    logType = formTypes?.RUN_LOG,
    signeeList
  }) => {
    const { query, filter } = getQueryAndFilter({
      equipmentModel,
      signee,
      sortDirection,
      queryDesc,
      queryAsc,
      instrument,
      dateFrom,
      dateTo,
      queryWithInstrumentDesc,
      queryWithInstrumentAsc,
      inventoryId,
      logType,
      signeeList
    });
    const { nextToken: token, items } = await getAllData({
      client,
      query,
      dataPath: ["data", queryName],
      variables: extractVariables({
        instrument,
        dateFrom,
        dateTo,
        storeUser,
        limit,
        nextToken,
        filter,
        inventoryId
      }),
      drillData
    });

    const updatedItems = getUpdatedItems(items);

    return {
      items: updatedItems,
      token
    };
  };
