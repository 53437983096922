import ACTION_LOGS_DATA_MODEL from "../../../utils/constants/actionLogsDataModel";
import { useContext } from "react";
import {
  EquipmentIdColumn,
  InstrumentModelColumn,
  SerialNumberColumn,
  VariantColumn
} from "../common-for-log-sheet-and-run-logs/table-common-meta-info";
import SubInstrumentsSelect from "./SubInstrumentsSelect";
import { valueOrEmpty } from "../../../utils/helpers/text";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel";
import { OwcIcon } from "@one/react";
import { FormControlLabel, FormGroup } from "@mui/material";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { formStates } from "../../../constants";

const clusterColumn = {
  headProps: {
    style: {
      flex: "0 0 42px",
      marginRight: 5
    }
  },
  cellProps: {
    style: {
      flex: "0 0 42px",
      marginRight: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
};
const ClusterCell = ({ item }) => {
  return (
    <>
      <div id={`cluster-identification-div-${item?.inventoryId}`}>
        <OwcIcon>
          <i id={`cluster-identification-icon-${item?.inventoryId}`} className="one-icons">
            table_of_content
          </i>
        </OwcIcon>
      </div>
    </>
  );
};
export const ChecboxCell = ({ item }) => {
  const { formState } = useContext(CoverSheetMainPageContext);
  return (
    <FormGroup row>
      <FormControlLabel
        data-testid="checbox-cell"
        control={
          <SubInstrumentsSelect
            item={item}
            style={undefined}
            visibility={true}
            edge={undefined}
            disabled={formState === formStates?.EDITABLE_FORM}
          />
        }
      />
    </FormGroup>
  );
};

export const logSheetTabletSizes = {
  actionDate: "0 1 12.75%",
  equipmentId: "0 1 12.75%",
  serialNumber: "0 1 12.75%",
  equipmentModel: "0 1 12.75%",
  action: "0 1 12.75%",
  approverUserName: "0 1 10.7%",
  gxpReady: "0 1 12.75%",
  systemStatus: "0 1 12.75%",
  actionButtons: "0 0 132px"
};

export const logSheetRegularSizes = {
  actionDate: "0 1 10%",
  equipmentId: "0 1 12%",
  serialNumber: "0 1 12%",
  equipmentModel: "0 1 15%",
  action: "0 1 15%",
  approverUserName: "0 1 15%",
  gxpReady: "0 1 15%",
  systemStatus: "0 1 15%",
  actionButtons: "0 0 132px"
};

export const LogBookSubEquipmentListMeta = {
  fields: {
    entryType: {
      component: ClusterCell,
      sortable: false,
      ...clusterColumn
    },
    ...InstrumentModelColumn,
    ...VariantColumn,
    ...EquipmentIdColumn,
    ...SerialNumberColumn,
    equipmentNickName: {
      text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
      component: ({ item }) => valueOrEmpty(item?.equipmentNickName, false, "-"),
      headProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      }
    },
    gxpReady: {
      text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
      component: ({ item }) => valueOrEmpty(item?.gxpReady?.value, false, "-"),
      headProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      }
    },
    systemStatus: {
      text: ACTION_LOGS_DATA_MODEL.systemStatus.value,
      component: ({ item }) => valueOrEmpty(item?.systemStatus?.value, false, "-"),
      headProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      },
      cellProps: {
        style: {
          flex: "0 1 15%",
          padding: "10px"
        }
      }
    }
  }
};
