import styled from "styled-components";

const MainBoxBase = styled.div`
  display: grid;
  grid-gap: 20px 12px;
`;

export const Mainbox = styled(MainBoxBase)`
  grid-template-columns: repeat(4, 1fr);
`;
export const ThreeFourthsCell = styled.div`
  grid-column: 1 / 5;
`;
export const GridRow = styled(MainBoxBase)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
`;
export const FirstHalfCell = styled.div`
  grid-column: 1 / 2;
  margin: 20px 0;
`;
export const SecondHalfCell = styled.div`
  grid-column: 4 / 6;
`;
export const FlexView = styled(MainBoxBase)`
  grid-template-columns: 1fr 3.1fr;
`;
export const AllMargin = styled.div`
  margin: 10px 10px 10px 17px;
`;
export const DescriptionWrapper = styled.div`
  margin-top: 20px;
`;
export const PreviousButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
export const LeftMargin = styled.div`
  margin-left: 15px;
  align-items: center;
  display: flex;
`;
export const TwoCell = styled.div`
  grid-column: span 2;
`;
export const LabelInnerHeading = styled.span`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const TwoColumnsBox = styled(MainBoxBase)`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
`;
export const ExpandRow = styled.div`
  grid-column: 1 / -1;
`;
export const MarginDiv = styled.div`
  margin: 10px;
  align-items: center;
  display: flex;
`;
export const Title = styled(ExpandRow)`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: -8px;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CoverSheetHeaderButton = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0066cc;
`;

export const EmptyLinkContainerStyled = styled.div`
  margin: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
`;
export const EmptyLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  cursor: pointer;
`;

export const LinkContainerDiv = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-direction: row;
  width: 100%;
  margin: 15px 0px;
`;

export const LinkContainerDivView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-direction: row;
  width: 100%;
  padding: 10px 0;
  border: 1px solid var(--one-color-background-contrast-1);
`;

export const LinkContainerDivInnerLeft = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  display: inline-flex;
  margin-left: 20px;
`;

export const LinkContainerDivInnerRight = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display: inline-flex;
  margin-right: 20px;
`;
export const LinkContainerStyled = styled.div`
  display: block;
  justify-content: center;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  margin: 0px;
  overflow-y: auto;
`;
