import { useState } from "react";
import { SearchTextContext } from "./context";

const SearchTextWrapper = ({ children }) => {
  const [searchText, setSearchText] = useState("");
  const [appliedSearchText, setAppliedSearchText] = useState("");
  const [lastSearchedValue, setLastSearchedValue] = useState("");
  const [logListData, setLogListData] = useState(null);
  const onSearchTextChange = (value) => {
    setSearchText(() => value);
  };
  const onAppliedSearchTextChange = (value) => {
    setAppliedSearchText(() => value ?? searchText);
  };

  const onLastSearchedValue = (value) => {
    setLastSearchedValue(value);
  };
  const onLastLogListData = (value) => {
    setLogListData(value);
  };
  return (
    <SearchTextContext.Provider
      value={{
        searchText,
        onSearchTextChange,
        appliedSearchText,
        onAppliedSearchTextChange,
        lastSearchedValue,
        onLastSearchedValue,
        logListData,
        onLastLogListData
      }}
    >
      {children}
    </SearchTextContext.Provider>
  );
};

export default SearchTextWrapper;
