import styled from "styled-components";

export const ActionContainer = styled.div`
  background-color: var(--one-color-background-base-1);
  margin-bottom: 15px;
`;

export const ContentContainer = styled.div`
  background-color: var(--one-color-background-neutral-1);
  display: block;
  width: 95%;
  padding: 10px;
  margin: 40px auto 20px auto;
  background: var(--one-color-cobas-white);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px;
  border-radius: 4px;
  justify-content: flex-start;
`;
