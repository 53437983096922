import { RUN_LOG_CHANGE_BY_RUN_LOG_ENTRY_ID } from "../../../../gql/logBooksapi/queries";

export const ImportHistoryOfRunLogs = async (runLog, { client }) => {
  const { data } = await client.query({
    query: RUN_LOG_CHANGE_BY_RUN_LOG_ENTRY_ID,
    fetchPolicy: "no-cache",
    variables: {
      runLogEntryId: runLog.id
    }
  });
  return data;
};
