import { useContext, useEffect } from "react";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debounce } from "underscore";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CustomFilterContext } from "../custom-filter/context";
import { SearchTextContext } from "../log-book-item-fillter/suggester/search-context/context";
import { uniq } from "lodash";

const WAIT_BEFORE_FETCH = 500;

const ChangeLogUpdateData = ({ client, fetchingFn, waitBeforeFetchTime = WAIT_BEFORE_FETCH }) => {
  const { inventoryId } = useParams();
  const { appliedSearchText } = useContext(SearchTextContext);
  const { nextData, nextToken, limit, sortDirection, dispatchAction } = useContext(PaginationContext);
  const { dateFrom, dateTo, site, instrument, equipmentModel, signee, signeeChangeLog, reason } =
    useContext(CustomFilterContext);
  const storeUser = useSelector((store) => store.user);
  const signeeList = uniq(useSelector((state) => state.equipmentModels.signee));
  useEffect(() => {
    if (nextData) {
      dispatchAction({
        type: "clearAll"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchAction({
      type: "fetching",
      payload: true
    });

    if (appliedSearchText && !instrument) {
      dispatchAction({
        type: "fetching",
        payload: false
      });
      return;
    }
    const fetchData = debounce(async () => {
      const { token, items } = await fetchingFn({
        client,
        sortDirection,
        equipmentModel,
        signee,
        signeeChangeLog,
        reason,
        instrument,
        dateFrom,
        dateTo,
        storeUser,
        limit,
        nextToken,
        inventoryId,
        signeeList
      });

      dispatchAction({
        type: "nextToken",
        payload: {
          token,
          data: items
        }
      });
    }, waitBeforeFetchTime);

    fetchData();

    return () => {
      fetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nextData,
    limit,
    site,
    dateFrom,
    dateTo,
    equipmentModel,
    signee,
    signeeChangeLog,
    reason,
    instrument,
    sortDirection,
    appliedSearchText
  ]);

  return null;
};

export default withApollo(ChangeLogUpdateData);
