import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  CustomDetailsCellStyled,
  Flex,
  RunLogWrapper
} from "../../features/log-book/common-for-log-sheet-and-run-logs/table-common-meta-info";
import DetailsCells from "./details-cells/DetailsCells";
import RunLogTableMeta, {
  RunLogTableEquipmentCentricMeta
} from "../../features/log-book/run-logs/runLogs-table/RunLogTableMeta";
import { CoverSheetMainPageContext } from "../../features/cover-sheet/cover-sheet-main-page-context/context";
import LogSheetTableMeta, {
  LogSheetTableEquipmentCentricMeta
} from "../../features/log-book/log-sheet/log-sheet-table/LogSheetTableMeta";
import AllLogClusterTableMeta from "../../features/log-book/run-logs/runLogs-table/AllLogClusterTableMeta";
import { LOG_TYPES } from "../../constants";

const DetailCellRenderer = (params) => {
  const { tabValue } = useContext(CoverSheetMainPageContext);
  const { inventoryId } = useParams();
  let metaField =
    tabValue === LOG_TYPES.ADD_RUN_LOG
      ? RunLogTableMeta?.expandedComponentProps?.infoMeta
      : tabValue === LOG_TYPES.ADD_ACTION_LOG
      ? LogSheetTableMeta?.expandedComponentProps?.infoMeta
      : [];

  let centricMetaField =
    tabValue === LOG_TYPES.ADD_RUN_LOG
      ? RunLogTableEquipmentCentricMeta?.expandedComponentProps?.infoMeta
      : tabValue === LOG_TYPES.ADD_ACTION_LOG
      ? LogSheetTableEquipmentCentricMeta?.expandedComponentProps?.infoMeta
      : tabValue === LOG_TYPES.ALL
      ? params?.data?.logType === "Run"
        ? AllLogClusterTableMeta?.expandedComponentProps?.infoMeta
        : AllLogClusterTableMeta?.expandedComponentProps?.actionInfoMeta
      : [];

  return (
    <Flex>
      <DetailsCells
        notHistory={true}
        Wrapper={RunLogWrapper}
        item={params.data}
        infoMeta={inventoryId ? centricMetaField : metaField}
        CellWrapper={CustomDetailsCellStyled}
      />
    </Flex>
  );
};

export default DetailCellRenderer;
