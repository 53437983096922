import React, { useRef } from "react";
import { OwcMenu, OwcListItem } from "@one/react";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { HistoryItemContext } from "../log-book-history-item/context";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { entryType, equipmentStatus, formStates, formTypes } from "../../../constants";
import { getEquipDetailInfo } from "../LoadLogBooksInfo";
import { withApollo } from "react-apollo";
import { getEquipDetailLogList, getRunLogSubEquipments } from "../run-logs/run-logs-form/CreateRunLogChange";
import { getActionLogSubEquipments } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import { CloudSearchConfig } from "../../../components/shared/CloudSearchConfig";
import { LogBookSelectContext } from "./logBooksSelectAll/context";
import { getSubEquipments } from "../../../utils/helpers/fetching";
import { find, unionBy } from "lodash";

const MenuList = ({ params, setShowMenu, client }) => {
  const menuElement = useRef(null);
  const canEdit = useSelector((store) => store.user.canEdit);
  const user = useSelector((store) => store.user);
  const systemStatus = useSelector((store) => store.runLogsForm.systemStatuss);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const { handleOpenHistoryContent: historyHandleOpen } = useContext(HistoryItemContext);
  const isVisible = params?.data?.equipmentDetails[0]?.status !== equipmentStatus?.deleted?.key;
  const handleClick = (option) => {
    setShowMenu(false);
  };
  const { handleOpenWorkFlowModel, loadLogBookEquipment, logType, allLogType } = useContext(CoverSheetMainPageContext);
  const { onSetSelection } = useContext(LogBookSelectContext);
  const loadSelectedSubEquipment = async (equipmentData) => {
    if (logType === formTypes?.RUN_LOG) {
      return await getRunLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (logType === formTypes?.ACTION_LOG) {
      return await getActionLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (logType === formTypes?.ALL_LOG && allLogType === formTypes?.RUN_LOG) {
      return await getRunLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (logType === formTypes?.ALL_LOG && allLogType === formTypes?.ACTION_LOG) {
      return await getActionLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    }
  };

  const handlePromises = async ({ promises, selectedEquipment, equipmentData, subEquipmentLogDetails = null }) => {
    const updatedEquipmentData = await getSubEquipments({
      promises,
      selectedEquipment,
      equipmentData,
      logType: logType === formTypes?.ALL_LOG ? allLogType : logType,
      gxpReadys,
      systemStatus,
      isEditMode: true,
      subEquipmentLogDetails
    });
    if (updatedEquipmentData) {
      loadLogBookEquipment(updatedEquipmentData?.equipmentData);
      onSetSelection(updatedEquipmentData?.selectedSubEquip);
      handleOpenWorkFlowModel({
        isCluster: equipmentData?.entryType === entryType?.cluster,
        form: formStates?.EDITABLE_FORM
      });
      handleClick("editLog");
    }
  };

  const onMenuClick = async () => {
    let equipmentData = { ...params?.data };
    equipmentData.qualificationStatus = equipmentData?.equipmentDetails?.qualificationStatus
      ? equipmentData?.equipmentDetails?.qualificationStatus
      : null;
    equipmentData.equipSystemStatus = equipmentData?.equipmentDetails?.systemStatus
      ? equipmentData?.equipmentDetails?.systemStatus
      : "-";
    equipmentData.status = equipmentData?.equipmentDetails?.status ? equipmentData?.equipmentDetails?.status : "-";
    equipmentData.entryType = equipmentData?.equipmentDetails.entryType;
    equipmentData.equipmentModel = equipmentData?.equipmentDetails?.equipmentModel;
    equipmentData.equipmentNickName = equipmentData.equipmentDetails.equipmentNickName;
    equipmentData.addInfo = {
      currentGxPStatusFromLogs: equipmentData?.equipmentDetails?.addInfo?.currentGxPStatusFromLogs || null,
      currentSystemStatusFromLogs: equipmentData?.equipmentDetails?.addInfo?.currentSystemStatusFromLogs || null
    };
    if (logType === formTypes?.RUN_LOG || logType === formTypes?.ACTION_LOG) {
      equipmentData.selectedLogType = logType;
    } else {
      equipmentData.selectedLogType = equipmentData?.logType === "Action" ? formTypes?.ACTION_LOG : formTypes?.RUN_LOG;
    }
    equipmentData.subEquipment = [];
    let promises = [];
    let clusterSubEquipmentPromises = [];
    let cloudSeachPromiesClusterSub = [];
    const defaultObj =
      equipmentData?.selectedLogType === formTypes?.RUN_LOG
        ? {
            key: "-",
            value: "-"
          }
        : null;

    if (equipmentData?.selectedLogType === formTypes?.ACTION_LOG) {
      equipmentData.gxpReady =
        find(gxpReadys, {
          key: equipmentData?.gxpReady?.key
        }) || defaultObj;
      equipmentData.systemStatus =
        find(systemStatus, {
          value: equipmentData?.systemStatus?.value
        }) || defaultObj;

      if (equipmentData?.entryType === entryType?.cluster) {
        equipmentData.gxpReadyCluster =
          find(gxpReadys, {
            key: equipmentData?.gxpReadyCluster?.key
          }) || defaultObj;
        equipmentData.systemStatusCluster =
          find(systemStatus, {
            value: equipmentData?.systemStatusCluster?.value
          }) || defaultObj;
      }
    }

    if (equipmentData?.entryType === entryType?.cluster) {
      Promise.all([loadSelectedSubEquipment(equipmentData)]).then(async (response) => {
        let updatedResponseData = response ? response[0] || [] : null;
        updatedResponseData = updatedResponseData?.map((data) => {
          const updatedData = {
            ...data,
            gxpReady:
              find(gxpReadys, {
                key: data?.gxpReady?.key
              }) || defaultObj,
            systemStatus:
              find(systemStatus, {
                value: data?.systemStatus?.value
              }) || defaultObj
          };
          return updatedData;
        });
        const clusterIds = [];
        const result = await CloudSearchConfig({
          sortBy: "position_in_cluster",
          searchValue: `cluster_id:${equipmentData?.inventoryId} AND site_name:${user.site}`,
          start: 0,
          size: 1000,
          sort: "asc"
        });
        result?.data?.forEach((subEquip) => {
          if (subEquip.entry_type[0] === entryType?.cluster) {
            clusterIds.push(subEquip.id[0]);
          }
          promises?.push(getEquipDetailInfo(subEquip.id[0], client));
        });

        let subEquipLogPromises = [];
        let subEquipmentLogDetails = [];
        if (updatedResponseData && updatedResponseData?.length > 0) {
          let uniqRespose = unionBy(updatedResponseData, "inventoryId");
          uniqRespose.forEach((item) => {
            const logTypeKey =
              equipmentData?.selectedLogType === formTypes?.RUN_LOG ? "runLogEntryId" : "logSheetEntryId";
            subEquipLogPromises.push(getEquipDetailLogList(item[logTypeKey], client, equipmentData?.selectedLogType));
          });

          Promise.all(subEquipLogPromises).then((logDetails) => {
            subEquipmentLogDetails = logDetails?.map((log) => {
              const logObj = {
                ...log,
                inventoryId: log?.equipmentDetails?.inventoryId,
                gxpReady:
                  find(gxpReadys, {
                    key: log?.gxpReady?.key
                  }) || defaultObj,
                systemStatus:
                  find(systemStatus, {
                    value: log?.systemStatus?.value
                  }) || defaultObj
              };
              return logObj;
            });
            if (clusterIds?.length > 0) {
              clusterIds.forEach((clusterId) => {
                const clusterSubEquipment = CloudSearchConfig({
                  sortBy: "position_in_cluster",
                  searchValue: `cluster_id:${clusterId} AND site_name:${user.site}`,
                  start: 0,
                  size: 1000,
                  sort: "asc"
                });
                cloudSeachPromiesClusterSub.push(clusterSubEquipment);
              });

              const allCloudSearchPromised = Promise.all(cloudSeachPromiesClusterSub);
              allCloudSearchPromised?.then((clusterSubEquipmentRespose) => {
                clusterSubEquipmentRespose?.forEach((subEquip) => {
                  subEquip?.data?.forEach((equip) => {
                    clusterSubEquipmentPromises?.push(getEquipDetailInfo(equip.id[0], client));
                  });
                });
                handlePromises({
                  promises: [...promises, ...clusterSubEquipmentPromises],
                  selectedEquipment: [updatedResponseData],
                  equipmentData,
                  subEquipmentLogDetails
                });
              });
            } else {
              handlePromises({
                promises: [...promises],
                selectedEquipment: [updatedResponseData],
                equipmentData,
                subEquipmentLogDetails
              });
            }
          });
        }
      });
    } else {
      loadLogBookEquipment(equipmentData);
      handleOpenWorkFlowModel({
        isCluster: params?.data?.equipmentDetails?.entryType === entryType?.cluster,
        form: formStates?.EDITABLE_FORM
      });
      handleClick("editLog");
    }
  };

  return (
    <div>
      <OwcMenu
        ref={menuElement}
        visible={true}
        onClickOutside={() => setShowMenu(false)}
        anchor={params?.data?.id}
        disable-portal={true}
        strategy="fixed"
      >
        {isVisible && canEdit && !params?.data?.clusterEntryRefId && (
          <OwcListItem onClick={onMenuClick} id={"editlog"}>
            Edit
          </OwcListItem>
        )}
        <OwcListItem
          onClick={() => {
            handleClick("auditLog");
            historyHandleOpen(params?.data);
          }}
          id={"auditlog"}
        >
          View audit trail
        </OwcListItem>
      </OwcMenu>
    </div>
  );
};

export default withApollo(MenuList);
