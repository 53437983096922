const FileIcon = (props) => {
  return (
    <svg {...props} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 18V6.5C16 6 15.8 5.5 15.4 5.1L11 0.6C10.7 0.2 10.2 0 9.6 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18ZM9 2V7H14V18H2V2H9Z"
        fill="#706B69"
      />
    </svg>
  );
};

export default FileIcon;
