import { useFormikContext } from "formik";
import { useEffect, useState, useContext } from "react";
import { compose, withApollo } from "react-apollo";
import { connect, useSelector } from "react-redux";
import { debounce } from "underscore";
import { entryType, equipmentStatus, formStates } from "../../../../constants";
import {
  INSTRUMENT_BY_SITE_AND_EQUIPMENT_ID,
  INSTRUMENT_BY_SITE_AND_SERIAL_NUMBER
} from "../../../../gql/logBooksapi/queries";
import { getAllData } from "../../../../utils/helpers/fetching";
import { loadInstrumentSuggestions, loadInstrumentSuggestion } from "../redux/actions";
import { getUpdatedItems } from "../../common-for-log-sheet-and-run-logs/fetch-common";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";

const isSuggestionCandidate = (formik, instrumentSuggestion) =>
  instrumentSuggestion?.equipmentId === formik.values.equipmentId &&
  instrumentSuggestion?.serialNumber === formik.values.serialNumber &&
  instrumentSuggestion?.materialNumber === formik.values.materialNumber;

const WAIT_BEFORE_SUGGEST = 1000;

const ItemFormSuggester = ({
  loadInstrumentSuggestions: loadSuggestions,
  loadInstrumentSuggestion: loadSuggestion,
  client,
  clearSuggestionFn,
  selectSuggestionFn,
  waitBeforeSuggestTime = WAIT_BEFORE_SUGGEST
}) => {
  const formik = useFormikContext();

  const [oldSerialNumber, setOldSerialNumber] = useState(formik.values.serialNumber);
  const [oldEquipmentId, setOldEquipmentId] = useState(formik.values.equipmentId);

  const instrumentSuggestion = useSelector((state) => state.runLogsForm.instrumentSuggestion);
  const instrumentSuggestions = useSelector((state) => state.runLogsForm.instrumentSuggestions);
  const user = useSelector((state) => state.user);

  const { formState } = useContext(CoverSheetMainPageContext);

  useEffect(() => {
    return () => {
      setOldSerialNumber(formik.values.serialNumber);
      setOldEquipmentId(formik.values.equipmentId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.serialNumber, formik.values.equipmentId]);

  useEffect(() => {
    const byEquipmentId = formik.values.equipmentId !== oldEquipmentId;

    if (isSuggestionCandidate(formik, instrumentSuggestion)) {
      return;
    }

    const fetchData = debounce(async () => {
      const formikEquipmentId = formik.values.equipmentId === "" ? "null" : formik.values.equipmentId;
      const { items } = await getAllData({
        client,
        query: byEquipmentId ? INSTRUMENT_BY_SITE_AND_EQUIPMENT_ID : INSTRUMENT_BY_SITE_AND_SERIAL_NUMBER,
        variables: {
          siteName: user.site,
          limit: 1000,
          serialNumber: !byEquipmentId ? formik.values.serialNumber : undefined,
          equipmentId: byEquipmentId ? formikEquipmentId : undefined,
          filter:
            formState === formStates?.EDITABLE_FORM
              ? {
                  equipmentId: !byEquipmentId
                    ? {
                        eq: formikEquipmentId
                      }
                    : undefined,
                  serialNumber: byEquipmentId
                    ? {
                        eq: formik.values.serialNumber
                      }
                    : undefined,
                  and: [
                    {
                      or: [
                        { status: { eq: equipmentStatus?.active?.key } },
                        { status: { eq: equipmentStatus?.pending?.key } }
                      ]
                    }
                  ]
                }
              : {
                  and: [
                    {
                      or: [
                        { status: { eq: equipmentStatus?.active?.key } },
                        { status: { eq: equipmentStatus?.pending?.key } }
                      ]
                    }
                  ],
                  entryType: { eq: entryType?.standaloneEquipment }
                }
        },
        dataPath: ["data", byEquipmentId ? "instrumentBySiteAndEquipmentId" : "instrumentBySiteAndSerialNumber"]
      });

      const updatedItems = getUpdatedItems(items);

      loadSuggestions(updatedItems?.slice(0, 20) || []);
    }, waitBeforeSuggestTime);

    if (formState === formStates?.NEW_FORM) {
      if (formik.values.equipmentId || formik.values.serialNumber) fetchData();
    }

    return () => {
      fetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.serialNumber,
    formik.values.equipmentId,
    oldSerialNumber,
    oldEquipmentId,
    instrumentSuggestion,
    loadSuggestions,
    user.site
  ]);

  useEffect(() => {
    if (instrumentSuggestions.length === 0 || instrumentSuggestions.length > 1) {
      const suggestion = instrumentSuggestions.find((suggestion) => isSuggestionCandidate(formik, suggestion));
      if (suggestion) {
        const instrumentUpdatedObj = {
          ...suggestion,
          equipSystemStatus: suggestion?.systemStatus || "-"
        };
        selectSuggestionFn(instrumentUpdatedObj, {
          loadInstrumentSuggestion: loadSuggestion,
          formik,
          user
        });
      }
      if (!suggestion && formState !== formStates?.EDITABLE_FORM) {
        clearSuggestionFn({
          loadSuggestion,
          formik,
          user
        });
      }
    } else {
      const instrumentUpdatedObj = {
        ...instrumentSuggestions[0],
        equipSystemStatus: instrumentSuggestions[0]?.systemStatus || "-"
      };
      selectSuggestionFn(instrumentUpdatedObj, {
        loadInstrumentSuggestion: loadSuggestion,
        formik,
        user
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSuggestion, instrumentSuggestions]);

  return null;
};

export default compose(
  connect(null, {
    loadInstrumentSuggestions,
    loadInstrumentSuggestion
  }),
  withApollo
)(ItemFormSuggester);
