import { OwcChip } from "@one/react";
import { equipmentStatus, statusChip } from "../../../constants";
import { EN_DASH } from "@digitallab/grid-common-components";

const EquipmentStatusRender = ({ value, style }: { value: string; style?: React.CSSProperties }) => {
  if (!Object.keys(statusChip).includes(value)) return value ? value.toString() : EN_DASH;
  const status = statusChip[value as keyof typeof statusChip];
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          ...style
        }}
      >
        {value !== null ? (
          <OwcChip
            outlined={true}
            style={{
              minHeight: "22px",
              color:
                status === equipmentStatus?.deleted?.value
                  ? "var(--one-color-red-a-300)"
                  : status === equipmentStatus?.pending?.value
                  ? "var(--one-color-yellow-a-300)"
                  : "var(--one-color-cobas-green-600)"
            }}
            placeholder=""
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          >
            <strong> {status}</strong>
          </OwcChip>
        ) : (
          "-"
        )}
      </div>
    </>
  );
};
export default EquipmentStatusRender;
