import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { loadInstrumentSuggestion, loadInstrumentSuggestions } from "./redux/actions";
import initialState from "./redux/initialState";
import { CircularProgress } from "@mui/material";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const LoadItemForm = ({ children, client, loadInstrumentSuggestion, loadInstrumentSuggestions }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        loadInstrumentSuggestion(initialState.instrumentSuggestion);
        loadInstrumentSuggestions([]);
      } catch (err) {
        console.warn(err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [loadInstrumentSuggestions, dispatch, user.site, loadInstrumentSuggestion, client]);
  if (loading) {
    return (
      <FullScreenCentered>
        <CircularProgress data-testid="loading" size={80} />
      </FullScreenCentered>
    );
  }
  return children;
};

export default compose(
  connect(null, {
    loadInstrumentSuggestion,
    loadInstrumentSuggestions
  }),
  withApollo
)(LoadItemForm);
