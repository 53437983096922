import React, { useState } from "react";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { OwcDrawer, OwcIconButton, OwcTypography } from "@one/react";
import styles from "./AppDrawer.module.scss";
import { HOME_SUB_MENU } from "../constants";
import DrawerSideBar from "./DrawerSideBar";

const AppDrawer = ({ children, containerHeight }) => {
  const sideMenu = HOME_SUB_MENU;
  const [isMini, setIsMini] = useState(false);
  return (
    <div className={styles.appdrawerOwcdrawer} data-testid="app-drawer">
      <OwcDrawer id="drawer" mode="push" hideBackdrop visible={sideMenu.length !== 0} mini={isMini}>
        <div slot="panel" className={styles.appdrawerPanel}>
          <DrawerSideBar />
        </div>
        <div slot="header" className={styles.appdrawerHeader}>
          <OwcIconButton
            flat
            id="menu-button"
            icon="menu"
            className={styles.appdrawerIcon}
            onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
          />
          <OwcTypography variant="button">Menu</OwcTypography>

          <OwcIconButton
            flat
            id="collapse-button"
            icon="clear"
            className={styles.appdrawerMenuclearicon}
            onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
          />
        </div>
        <div className={styles.appdrawerDrawercontent}>
          <main style={{ padding: "1rem" }}>{children}</main>
        </div>
      </OwcDrawer>
    </div>
  );
};
const mapStateToProps = (store) => ({
  selectedType: store.user?.selected?.type ?? null,
  email: store.user.email,
  NotificationBanner: store.user.NotificationBanner
});
export default compose(connect(mapStateToProps), withApollo)(AppDrawer);
