import React, { useContext } from "react";
import { InstrumentInformationsContext } from "./instrument-informations-context/context";
import InstrumentInformationsContent from "./InstrumentInformationsContent";
import CustomSmallDialog from "../../../components/shared/CustomSmallDialog";
import { CloseAddItemButtonSection } from "../log-book-history-item/CloseAddItemButtonSection";
import { ItemDialogFormContext } from "../log-book-item-form-dialog/item-context/context";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";

const InstrumentInformationsDialog = ({ addButtonLabel }) => {
  const { open, handleCloseInstrumentInformation } = useContext(InstrumentInformationsContext);
  const { handleOpen } = useContext(ItemDialogFormContext);
  return (
    <CustomSmallDialog
      open={open}
      handleClose={handleCloseInstrumentInformation}
      dataTestId="instrument-information"
      title={LOGBOOK_LABEL.eqipment_info_heading}
      actions={
        <CloseAddItemButtonSection
          dataTestId="instrument-information"
          handleOpen={handleOpen}
          handleClose={handleCloseInstrumentInformation}
          addButtonLabel={addButtonLabel}
        />
      }
    >
      <InstrumentInformationsContent />
    </CustomSmallDialog>
  );
};

export default InstrumentInformationsDialog;
