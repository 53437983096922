import { useFormikContext } from "formik";
import { OwcDatepicker } from "@one/react";

/**
 * #2034878 Function that converts date string to Date Object with offset TZ
 * @param {string} input - date as string in format YYYY-MM-DD
 * @returns {Date} -modified date with timezone Offsetted by the difference between local TZ and UTC
 *
 */
const convertLocalDateToUTC = (input) => {
  const transformed = new Date(input);
  const tzOffset = transformed.getTimezoneOffset();
  input = new Date(transformed.getTime() + tzOffset * 60 * 1000);
  return input;
};

const RunDateInput = ({ property, otherDateProperty, label, testid, required = false }) => {
  const formik = useFormikContext();
  const dateValue = (value) => {
    formik.setFieldTouched(otherDateProperty, true);
    if ((value.detail instanceof Date && !isNaN(value.detail)) || value.detail === null) {
      formik.setFieldValue(property, value.detail, true);
    }
  };
  return (
    <>
      <OwcDatepicker
        autoClose
        hasClearIcon={true}
        format="dd-MMM-yyyy"
        label={label}
        placeholder={"DD-MMM-YYYY"}
        value={
          typeof formik.values[property] === "string"
            ? convertLocalDateToUTC(formik.values[property])
            : formik.values[property]
        }
        variant="filled"
        onValueChange={(e) => dateValue(e)}
        style={{ width: "100%" }}
        invalidDateMessage={"Invalid date format"}
        required={required}
        requiredDateMessage={formik.errors[property]}
      >
        {formik.errors[property] && <span slot="error-text">{formik.errors[property]}</span>}
      </OwcDatepicker>
    </>
  );
};

export default RunDateInput;
