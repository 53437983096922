import React, { useContext } from "react";
import { HistoryItemContext } from "./context";
import styled from "styled-components";
import { ItemDialogFormContext } from "../log-book-item-form-dialog/item-context/context";
import { CloseAddItemButtonSection } from "./CloseAddItemButtonSection";
import HistoryItemContent from "./HistoryItemContent";
import ShowEquipmentInfo from "../ShowEquipmentInfo";
import { OwcModalDialog } from "@one/react";

const AuditEquipmentInfo = styled.div`
  background-color: #fafafa;
  border-radius: 4px;
  padding: 16px 24px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
`;

const HistoryItemDialog = ({
  open,
  handleClose,
  CellsInfoArray,
  title,
  dataTestId,
  addButtonLabel,
  disableStatus = false,
  selectedItem
}) => {
  const { handleOpen } = useContext(ItemDialogFormContext);
  const { historyItem } = useContext(HistoryItemContext);
  return (
    <OwcModalDialog
      visible={open}
      size="md"
      className="owcmodalZIndex"
      onVisibleChange={(event) => {
        if (!event?.detail) {
          handleClose();
        }
      }}
    >
      <div slot="header" data-testid={`history-${dataTestId}-title`}>
        {title}
      </div>
      <div style={{ height: "300px", minHeight: "fit-content" }}>
        {historyItem?.length > 0 && (
          <AuditEquipmentInfo>
            <ShowEquipmentInfo equipmentDetail={historyItem[0]?.equipmentDetails} />
          </AuditEquipmentInfo>
        )}
        <div>
          <HistoryItemContent
            selectedItem={selectedItem}
            CellsInfoArray={CellsInfoArray}
            dataTestId={dataTestId}
            disableStatus={disableStatus}
          />
        </div>
      </div>
      <div slot="actions">
        <CloseAddItemButtonSection
          selectedItem={selectedItem}
          dataTestId={`history-${dataTestId}`}
          handleClose={handleClose}
          handleOpen={handleOpen}
          addButtonLabel={addButtonLabel}
          disableStatus={disableStatus}
        />
      </div>
    </OwcModalDialog>
  );
};

export default HistoryItemDialog;
