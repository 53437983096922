import React, { Fragment } from "react";
import { OwcEditableDropdown, OwcListItem } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";

const CommonOwcEditableDropDown = ({
  labelValue,
  style,
  list,
  selected = null,
  label,
  propValue,
  defaultLabel,
  onChange,
  helpertext,
  onClose,
  onOpen,
  required = false,
  disabled = false,
  sopHelperText = false,
  onBlur
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: sopHelperText ? "8px 8px 0px 0px" : ""
        }}
      >
        <OwcEditableDropdown
          style={style}
          label={labelValue ? label : selected !== null ? defaultLabel : label}
          placeholder={labelValue ? "" : label}
          value={selected}
          onSelectChange={(e) => {
            onChange(e.detail);
          }}
          required={required}
          onBlur={onBlur}
          onPanelOpen={onOpen}
          disabled={disabled}
          onPanelClose={onClose}
          requiredText={helpertext}
        >
          {list?.map((item, index) => {
            const option = item?.[propValue] || item;
            return (
              <Fragment key={`${option}${index}`}>
                <OwcListItem
                  value={option}
                  key={`${option}${index}`}
                  data-testid={`${label}-${option}`}
                  id={generateID.UUID(label, `EditableDropDown_${label}-${option}`, "item")}
                >
                  {option}
                </OwcListItem>
              </Fragment>
            );
          })}
        </OwcEditableDropdown>
      </div>
    </div>
  );
};

export default CommonOwcEditableDropDown;
