import {
  LIST_LOG_SHEET_SYSTEM_STATUSS,
  LIST_LOG_SHEET_GXP_READYS,
  LIST_LOG_SHEET_ACTIONS
} from "../../../../gql/logBooksapi/queries";
import { loadGxpReadys, loadSystemStatuss, loadLogSheetActions } from "../../log-book-item-form-dialog/redux/actions";
import { sortBy } from "lodash";
import { getAllData } from "../../../../utils/helpers/fetching";

export const logSheetLoadItemSpecifics = async ({ client, dispatch, site }) => {
  const [{ items: gxpReadys }, { items: systemStatuss }, { items: actionLog }] = await Promise.all([
    getAllData({
      client,
      query: LIST_LOG_SHEET_GXP_READYS,
      fetchPolicy: "no-cache",
      variables: {
        limit: 200
      },
      dataPath: ["data", "listLogSheetGxpReadys"],
      drillData: true
    }),
    getAllData({
      client,
      query: LIST_LOG_SHEET_SYSTEM_STATUSS,
      fetchPolicy: "no-cache",
      variables: {
        limit: 200
      },
      dataPath: ["data", "listLogSheetSystemStatuss"],
      drillData: true
    }),
    getAllData({
      client,
      query: LIST_LOG_SHEET_ACTIONS,
      fetchPolicy: "no-cache",
      variables: {
        limit: 200
      },
      dataPath: ["data", "listLogSheetActions"],
      drillData: true
    })
  ]);

  dispatch(loadGxpReadys(sortBy(gxpReadys, ["value"])));

  dispatch(loadSystemStatuss(sortBy(systemStatuss, ["value"])));

  dispatch(loadLogSheetActions(sortBy(actionLog, [(actionLogVal) => actionLogVal.value.toLowerCase()])));
};
