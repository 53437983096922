import {
  RUN_LOG_BY_SITE_AND_START_DATE_ASC,
  RUN_LOG_BY_SITE_AND_START_DATE_DESC,
  RUN_LOG_BY_INVENTORY_ID_ASC,
  RUN_LOG_BY_INVENTORY_ID_DESC
} from "../../../gql/logBooksapi/queries";
import { fetchCommon } from "../common-for-log-sheet-and-run-logs/fetch-common";

export const fetchRunLogs = fetchCommon({
  queryAsc: RUN_LOG_BY_SITE_AND_START_DATE_ASC,
  queryDesc: RUN_LOG_BY_SITE_AND_START_DATE_DESC,
  queryWithInstrumentAsc: RUN_LOG_BY_INVENTORY_ID_ASC,
  queryWithInstrumentDesc: RUN_LOG_BY_INVENTORY_ID_DESC,
  queryName: "runLogBySiteAndStartDate"
});
