const IconForApp = (props) => (
  <svg width="40" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_192_2495)">
      <path
        d="M16.3325 5.64502H12.7875C12.6915 5.64497 12.5955 5.64998 12.5 5.66002C11.8108 5.72991 11.172 6.05279 10.707 6.56625C10.242 7.07971 9.9839 7.74731 9.98249 8.44002V8.44002V41.9625C9.98148 42.6567 10.2386 43.3264 10.7039 43.8416C11.1691 44.3568 11.8093 44.6805 12.5 44.75C12.5955 44.7601 12.6915 44.7651 12.7875 44.765H35.2125C35.5801 44.766 35.9442 44.6945 36.2842 44.5547C36.6241 44.4149 36.9332 44.2094 37.1937 43.9501C37.4542 43.6907 37.661 43.3826 37.8024 43.0433C37.9437 42.704 38.0168 42.3401 38.0175 41.9725V8.44002C38.0168 8.07232 37.9438 7.70835 37.8024 7.36889C37.6611 7.02943 37.4543 6.72113 37.1938 6.46159C36.9334 6.20205 36.6243 5.99635 36.2844 5.85625C35.9444 5.71614 35.5802 5.64437 35.2125 5.64502H31.6675"
        stroke="#0B41CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0325 3.25H17.8125C17.4403 3.28614 17.0951 3.46 16.8444 3.73748C16.5937 4.01497 16.4558 4.37607 16.4575 4.75V4.75V6.5475C16.4558 6.92143 16.5937 7.28253 16.8444 7.56002C17.0951 7.8375 17.4403 8.01136 17.8125 8.0475H30.0325C30.4303 8.0475 30.8119 7.88946 31.0932 7.60816C31.3745 7.32686 31.5325 6.94532 31.5325 6.5475V4.75C31.5325 4.35218 31.3745 3.97064 31.0932 3.68934C30.8119 3.40804 30.4303 3.25 30.0325 3.25Z"
        stroke="#0B41CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6975 13.975H34.3025M13.6975 17.915H34.3025M13.6975 21.855H34.3025M13.6975 25.795H34.3025M13.6975 29.7375H34.3025M13.6975 33.6775H34.3025M13.6975 37.6175H24"
        stroke="#1482FA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_192_2495">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default IconForApp;
