import { OwcTypography } from "@one/react";

const SyncActionContent = ({ Dipsflag = 3 }) => {
  return (
    <>
      {Dipsflag === 4 && (
        <OwcTypography variant="body1">
          The 'System status' and 'Qualification status' of the equipment will be propagated to the repository, only if
          their ‘Equipment status’ is active.
        </OwcTypography>
      )}
      {Dipsflag === 3 && (
        <OwcTypography variant="body1">
          "System status" and "Qualification status" will be propagated to repository
        </OwcTypography>
      )}
      {Dipsflag === 2 && (
        <OwcTypography variant="body1">"System status" will be propagated to repository </OwcTypography>
      )}
      {Dipsflag === 1 && (
        <OwcTypography variant="body1">"Qualification status" will be propagated to repository </OwcTypography>
      )}
      {Dipsflag !== 4 && <OwcTypography variant="body1">Do you want to proceed?</OwcTypography>}
    </>
  );
};

export default SyncActionContent;
