import { OwcTypography } from "@one/react";
import { Grid } from "@mui/material";
import { CLUSTER_COVER_SHEET_LIST, COVER_SHEET_LIST } from "../../constants";
import { useSelector } from "react-redux";
import { coverSheetValuesComponents, DisplayItemValueDefault } from "./DisplayFunctions";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";

export const CoverSheetData = ({
  equipmentDetail,
  snapShotView,
  coverSheetFields
}: {
  equipmentDetail: DigitalLabLogbookInstrumentModelType;
  snapShotView: boolean;
  coverSheetFields: typeof COVER_SHEET_LIST | typeof CLUSTER_COVER_SHEET_LIST;
}) => {
  //@ts-ignore
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  return (
    <Grid container spacing={1} data-testid="cover-sheet-data">
      {equipmentDetail &&
        Object.keys(coverSheetFields).map((coverSheetItem) => {
          const properties = {
            snapShotView,
            equipmentDetail,
            gxpReadys
          };

          return (
            <Grid item xs={2} key={coverSheetItem}>
              <OwcTypography
                variant="body2"
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              >
                {coverSheetFields[coverSheetItem as keyof typeof coverSheetFields].label}
              </OwcTypography>

              <strong>
                {coverSheetValuesComponents.get(coverSheetItem)?.(properties) ||
                  DisplayItemValueDefault(equipmentDetail, coverSheetItem)}
              </strong>
            </Grid>
          );
        })}
      <Grid item xs={2} />
    </Grid>
  );
};
