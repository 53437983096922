import { useState, useCallback, useEffect } from "react";
import { LogBookSelectContext } from "./context";
import { entryType } from "../../../../constants";

export const compareInstrument = (x, y) => x?.inventoryId === y?.inventoryId;

export const LogbookSelectedWrapper = ({ children, selectedInstruments = [], equipmentDetail = null }) => {
  const [filteredData, setFilteredData] = useState({});
  useEffect(() => {
    let subEquipmentData = [];
    if (equipmentDetail && equipmentDetail.subEquipment && equipmentDetail.subEquipment?.length > 0) {
      equipmentDetail.subEquipment?.forEach((details) => {
        subEquipmentData.push(details);
        if (details?.entryType === entryType?.cluster && details?.subEquipment) {
          details?.subEquipment?.forEach((details1) => {
            subEquipmentData.push(details1);
          });
        }
      });

      setFilteredData(subEquipmentData);
    }
  }, [equipmentDetail]);

  const [selected, setSelected] = useState(selectedInstruments);
  const [selectedOnlyParents, setSelectedOnlyParents] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState("");

  const onSelect = useCallback((item) => {
    setSelected((prev) => {
      return prev.some((i) => compareInstrument(i, item))
        ? prev.filter((i) => !compareInstrument(i, item))
        : [...prev, item];
    });
  }, []);

  const onAllSelect = useCallback(() => {
    setSelected((prev) =>
      filteredData.every((filteredItem) => prev.some((item) => compareInstrument(item, filteredItem)))
        ? []
        : filteredData.map((i) => ({
            inventoryId: i?.inventoryId
          }))
    );
  }, [filteredData]);

  useEffect(() => {
    setSelected((prev) =>
      prev.filter((item) => filteredData.some((filteredItem) => compareInstrument(item, filteredItem)))
    );
  }, [filteredData]);

  const isSelected = useCallback((item) => selected.some((i) => compareInstrument(i, item)), [selected]);
  const getCount = (count) => {
    if (count > 0) {
      setSelectedCount(`(${selected.length})`);
    } else {
      setSelectedCount("");
    }
  };

  useEffect(() => {
    setAllSelected(selected.length && filteredData.length === selected.length);
  }, [filteredData, selected]);
  useEffect(() => {
    getCount(selected.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const clearSelection = () => {
    setSelected(() => []);
    setSelectedOnlyParents(() => []);
  };

  const onSetSelection = (values) => {
    setSelected(() => [...values]);
  };

  const onAddSelectedParents = (onlyParents) => {
    setSelectedOnlyParents(() => onlyParents);
  };

  return (
    <LogBookSelectContext.Provider
      value={{
        selected,
        onSelect,
        onAllSelect,
        isSelected,
        isAllSelected,
        selectedCount,
        clearSelection,
        onSetSelection,
        selectedOnlyParents,
        onAddSelectedParents
      }}
    >
      {children}
    </LogBookSelectContext.Provider>
  );
};

export default LogbookSelectedWrapper;
