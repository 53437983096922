import React from "react";
import { AuditItemContentCellsInfoArray } from "../audit-trial/AuditItemCellsConfig";
import AuditItemSingleFrame from "./AuditItemSingleFrame";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const AuditAdminText = styled.p`
  padding-left: 30px;
  margin-left: 35px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #0066cc;
`;
const ChangeLogDetailCellRenderer = (params) => {
  let count = [];
  if (params?.data?.changes) {
    let lengthChanges = JSON.parse(params?.data?.changes);
    count = Object.keys(lengthChanges).length;
  }
  const relevantPositionState = params?.data?.newData?.trackPositionInCluster;
  return (
    <Flex>
      {count !== 0 && params?.data?.changes !== "" && (
        <AuditItemSingleFrame
          isAuditTrial={true}
          auditAction={params?.data?.action}
          history={JSON.parse(params?.data?.changes)}
          newData={params?.data?.newData ? JSON.parse(params?.data?.newData) : null}
          CellsInfoArray={AuditItemContentCellsInfoArray}
          relevantPositionState={relevantPositionState}
        />
      )}

      {(count === 0 || params?.data?.changes === "") && (
        <AuditAdminText>For more records please contact Admin.</AuditAdminText>
      )}
    </Flex>
  );
};

export default ChangeLogDetailCellRenderer;
