import React from "react";
import { useParams } from "react-router-dom";
import LogBooksMainPage from "../log-book/log-book-pages/LogBooksMainPage";
import DescriptionDetailView from "./DescriptionDetailView";
import ClusterDescriptionDetailView from "./cluster-cover-sheet/ClusterDescriptionDetailView";
import { OwcExpandable, OwcExpandableGroup, OwcTypography } from "@one/react";
import { configureAwsAppSync } from "../../utils/helpers/configureAwsAppSync";
import { API } from "aws-amplify";
import {
  LIST_DIGITAL_LAB_LOGBOOK_SUBEQUIPMENT_INSTRUMENT,
  GET_EQUIPMENT_OR_CLUSTER_SNAPSHOT,
  REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY
} from "../../gql/logBooksapi";
import { withApollo } from "react-apollo";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { getAllData, getSubEquipments } from "../../utils/helpers/fetching";
import SnapshotAction from "./SnapshotAction";
import { changeDateFormat, getTimeZoneOffset } from "../../utils/helpers/text";
import { exportToSnapshotPDF } from "../log-book/log-book-export-pdf/export-to-pdf";
import { useSelector } from "react-redux";
import DATA_MODEL_TABLE, { ADD_INFO_TABLE } from "../../utils/constants/dataModelTable";
import { entryType, SECOND_VALUE, REPO_CONFIG } from "../../constants";
import { CoverSheetMainPageContext } from "./cover-sheet-main-page-context/context";
import { useRef } from "react";
import styles from "./CoverSheetMainPage.module.scss";
import { getEquipDetailInfo } from "../log-book/LoadLogBooksInfo";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";

const CoverSheetMainPage = ({ client }) => {
  const storeUser = useSelector((store) => store.user);
  const systemStatus = useSelector((store) => store.runLogsForm.systemStatuss);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const { inventoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [snapShotView, setSnapShotView] = useState(false);
  const [snapShotDate, setSnapShotDate] = useState(null);
  const [snapShotTime, setSnapShotTime] = useState(null);
  const [showSnapShotClk, setShowSnapShotClk] = useState(false);
  const [snapShotEquipmentDetail, setSnapShotEquipmentDetails] = useState(null);
  const [initialCoverSheetEquipment, setInitialCoverSheetEquipment] = useState(null);
  const { refreshCoverSheet, loadLogBookEquipment, logType } = useContext(CoverSheetMainPageContext);
  const expandableGroupElement = useRef(null);
  useEffect(() => {
    const handlePromises = async ({ promises, selectedEquipment = null, equipmentData }) => {
      const updatedEquipmentData = await getSubEquipments({
        promises,
        selectedEquipment,
        equipmentData,
        logType,
        gxpReadys,
        systemStatus,
        isEditMode: false
      });
      if (updatedEquipmentData) {
        loadLogBookEquipment(updatedEquipmentData?.equipmentData);
        setSnapShotEquipmentDetails(updatedEquipmentData?.equipmentData);
        setInitialCoverSheetEquipment(updatedEquipmentData?.equipmentData);
      }
      setLoading(false);
    };
    let promises = [];
    let clusterSubEquipmentPromises = [];
    let cloudSeachPromiesClusterSub = [];
    const getDetails = async () => {
      try {
        setLoading(true);
        let detailInfo = await getEquipDetailInfo(inventoryId, client);
        let equipmentData = { ...detailInfo };
        equipmentData.equipSystemStatus = equipmentData?.systemStatus ? equipmentData?.systemStatus : "-";
        equipmentData.subEquipment = [];
        const clusterIds = [];
        const result = await CloudSearchConfig({
          sortBy: "position_in_cluster",
          searchValue: `cluster_id:${equipmentData?.inventoryId} AND site_name:${storeUser.site}`,
          start: 0,
          size: 1000,
          sort: "asc"
        });
        result?.data?.forEach((subEquip) => {
          if (subEquip.entry_type[0] === entryType?.cluster) {
            clusterIds.push(subEquip.id[0]);
          }
          promises?.push(getEquipDetailInfo(subEquip.id[0], client));
        });
        if (clusterIds?.length > 0) {
          clusterIds.forEach((clusterId) => {
            const clusterSubEquipment = CloudSearchConfig({
              sortBy: "position_in_cluster",
              searchValue: `cluster_id:${clusterId} AND site_name:${storeUser.site}`,
              start: 0,
              size: 1000,
              sort: "asc"
            });
            cloudSeachPromiesClusterSub.push(clusterSubEquipment);
          });

          const allCloudSearchPromised = Promise.all(cloudSeachPromiesClusterSub);
          allCloudSearchPromised?.then((clusterSubEquipmentRespose) => {
            clusterSubEquipmentRespose?.forEach((subEquip) => {
              subEquip?.data?.forEach((equip) => {
                clusterSubEquipmentPromises?.push(getEquipDetailInfo(equip.id[0], client));
              });
            });
            handlePromises({
              promises: [...promises, ...clusterSubEquipmentPromises],
              equipmentData
            });
          });
        } else {
          handlePromises({
            promises: [...promises],
            equipmentData
          });
        }
      } catch (err) {
        setLoading(false);
      }
    };
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCoverSheet]);

  const clearSnapShot = async (id) => {
    if (id !== undefined) {
      setLoading(true);
      try {
        const detailInfo = await getEquipDetailInfo(inventoryId, client);
        if (detailInfo) {
          setSnapShotEquipmentDetails(detailInfo);
        }
      } catch (err) {
      } finally {
        setSnapShotView(false);
        setLoading(false);
      }
    }
  };

  const showSnapShot = async ({ snapShotDate, snapShotTime, id }) => {
    const getSnapShotDtl = await getSnapShotDetails({
      snapShotDate,
      snapShotTime,
      id
    });
    if (getSnapShotDtl) {
      setSnapShotEquipmentDetails(getSnapShotDtl);
    } else {
      setSnapShotEquipmentDetails(null);
    }
  };

  const getSubEquipmentsDetailInfo = async (id) => {
    if (id !== undefined) {
      try {
        const result = await getAllData({
          client,
          query: LIST_DIGITAL_LAB_LOGBOOK_SUBEQUIPMENT_INSTRUMENT,
          variables: {
            limit: 1000,
            filter: { clusterId: { eq: id }, siteName: { eq: storeUser?.site } }
          },
          dataPath: ["data", "listDigitalLabLogbookInstruments"],
          drillData: true
        });
        return result?.items;
      } catch (err) {}
    }
  };
  const getRepoClusterDetailsById = async (clusterId) => {
    configureAwsAppSync(REPO_CONFIG);
    try {
      const subEquipment = await API.graphql({
        query: REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
        client,
        variables: {
          id: clusterId
        }
      });

      return subEquipment.data.getDigitalLabInstrumentRepositoryEntry.subEquipment.items;
    } catch (err) {
      console.error(err.message);
      return { error: err };
    }
  };
  const exportSnapShotPDF = (snapShotDate, snapShotTime) => {
    let date = snapShotDate ? changeDateFormat(snapShotDate, "DD-MMM-YYYY") : null;
    let time = snapShotTime ? changeDateFormat(changeDateFormat(snapShotTime, "ISO"), "h:mm") : null;
    const rows = [
      [DATA_MODEL_TABLE?.equipmentId?.key, DATA_MODEL_TABLE?.location?.key],
      [DATA_MODEL_TABLE?.serialNumber?.key, DATA_MODEL_TABLE?.responsiblePerson?.key],
      [DATA_MODEL_TABLE?.materialNumber?.key, DATA_MODEL_TABLE?.responsibleProxy?.key],
      [DATA_MODEL_TABLE?.equipmentNickName?.key, DATA_MODEL_TABLE?.softwareVersion?.key],
      [DATA_MODEL_TABLE?.equipmentModel?.key, DATA_MODEL_TABLE?.configurationBaseline?.key],
      [
        DATA_MODEL_TABLE?.manufacturer?.key,
        snapShotDate ? ADD_INFO_TABLE?.currentGxPStatusFromLogs?.key : DATA_MODEL_TABLE.qualificationStatus?.key
      ],
      [
        DATA_MODEL_TABLE?.variant?.key,
        snapShotDate ? ADD_INFO_TABLE?.currentSystemStatusFromLogs?.key : DATA_MODEL_TABLE.systemStatus?.key
      ]
    ];
    const records = [];
    rows?.forEach((values) => {
      const arrayObj = [];
      values?.forEach((value, index) => {
        if (value === "currentGxPStatusFromLogs" || value === "currentSystemStatusFromLogs") {
          arrayObj[index] =
            snapShotEquipmentDetail?.addInfo[value] && snapShotEquipmentDetail?.addInfo[value] !== "null"
              ? `${ADD_INFO_TABLE[value]?.value}: ${snapShotEquipmentDetail?.addInfo[value]}`
              : `${ADD_INFO_TABLE[value]?.value}: -`;
        } else {
          arrayObj[index] =
            snapShotEquipmentDetail[value] && snapShotEquipmentDetail[value] !== "null"
              ? `${DATA_MODEL_TABLE[value]?.value}: ${snapShotEquipmentDetail[value]}`
              : `${DATA_MODEL_TABLE[value]?.value}: -`;
        }
      });
      records?.push(arrayObj);
    });
    exportToSnapshotPDF({
      fileNamePrefix: "snapshot",
      storeUser,
      records,
      date,
      time,
      shortIdentifier: initialCoverSheetEquipment?.shortIdentifier
    });
  };

  const getSnapShotDetails = async ({ snapShotDate, snapShotTime, id }) => {
    if (snapShotDate && snapShotTime) {
      setLoading(true);
      try {
        let date = changeDateFormat(snapShotDate, "ISO");
        let time = changeDateFormat(snapShotTime, "ISO");
        date = snapShotDate ? date?.split("T")[0] : null;
        time = snapShotTime ? time?.split("T")[1]?.split(".")[0] : null;
        time = time.split(":");
        time[2] = SECOND_VALUE; // setting the seconds to 59
        time = time.join(":");
        const timeZone = getTimeZoneOffset(snapShotTime);
        const snapShotDateTime = `${date}T${time}.000${timeZone}`;
        const result = await client.query({
          query: GET_EQUIPMENT_OR_CLUSTER_SNAPSHOT,
          fetchPolicy: "no-cache",
          variables: {
            inventoryId: id,
            snapshotDateTime: snapShotDateTime
          }
        });
        if (result?.data?.getEquipmentOrClusterSnapshot) {
          return result?.data?.getEquipmentOrClusterSnapshot;
        } else {
          return null;
        }
      } catch (err) {
        console.log("=========err========", err);
      } finally {
        setSnapShotView(true);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={styles.coverSheetMainPage_MainContainer} data-testid="log-book-main-page">
        <OwcTypography variant="title5" style={{ margin: "17px 0" }}>
          Log book - {initialCoverSheetEquipment?.shortIdentifier}
        </OwcTypography>
        <OwcExpandableGroup ref={expandableGroupElement} multiple>
          <OwcExpandable variant="standard" round expanded={true}>
            <span slot="title">Cover sheet</span>

            <span
              slot="content"
              style={{
                background: snapShotView
                  ? "var(--one-color-background-neutral-2)"
                  : "var(--one-color-foreground-neutral-2)",
                boxShadow: "inset 0 0 0 0.0625rem var(--one-color-interaction-default-neutral-2)",
                minHeight: "300px",
                height: "fit-content",
                overflowY: "scroll"
              }}
            >
              {!snapShotEquipmentDetail && [
                <SnapshotAction
                  inventoryId={inventoryId}
                  showSnapShot={showSnapShot}
                  clearSnapShot={clearSnapShot}
                  exportSnapShotPDF={exportSnapShotPDF}
                  bgColor={
                    snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)"
                  }
                  borderBottom={false}
                  snapShotDate={snapShotDate}
                  setSnapShotDate={setSnapShotDate}
                  snapShotTime={snapShotTime}
                  setSnapShotTime={setSnapShotTime}
                  showSnapShotClk={showSnapShotClk}
                  setShowSnapShotClk={setShowSnapShotClk}
                  equipmentDetail={snapShotEquipmentDetail}
                />,
                <DescriptionDetailView
                  equipmentDetail={snapShotEquipmentDetail}
                  loading={loading}
                  snapShotView={snapShotView}
                  storeUser={storeUser?.name || storeUser?.email}
                  initialCoverSheetEquipment={initialCoverSheetEquipment}
                />
              ]}
              {snapShotEquipmentDetail &&
                snapShotEquipmentDetail?.entryType === entryType?.standaloneEquipment && [
                  <SnapshotAction
                    inventoryId={inventoryId}
                    showSnapShot={showSnapShot}
                    clearSnapShot={clearSnapShot}
                    exportSnapShotPDF={exportSnapShotPDF}
                    bgColor={
                      snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)"
                    }
                    borderBottom={false}
                    snapShotDate={snapShotDate}
                    setSnapShotDate={setSnapShotDate}
                    snapShotTime={snapShotTime}
                    setSnapShotTime={setSnapShotTime}
                    showSnapShotClk={showSnapShotClk}
                    setShowSnapShotClk={setShowSnapShotClk}
                    equipmentDetail={snapShotEquipmentDetail}
                  />,
                  <DescriptionDetailView
                    equipmentDetail={snapShotEquipmentDetail}
                    loading={loading}
                    snapShotView={snapShotView}
                    initialCoverSheetEquipment={initialCoverSheetEquipment}
                    snapShotDate={snapShotDate}
                    showSnapShotClk={showSnapShotClk}
                  />
                ]}
              {snapShotEquipmentDetail &&
                (snapShotEquipmentDetail?.entryType === entryType?.cluster ||
                  snapShotEquipmentDetail?.entryType === entryType?.clusterSubequipment) && (
                  <div className={styles.coverSheetMainPage_ClusterMainContainer} data-testid="log-book-main-page">
                    <ClusterDescriptionDetailView
                      inventoryId={inventoryId}
                      equipmentDetail={snapShotEquipmentDetail}
                      clearSnapShot={clearSnapShot}
                      getRepoClusterDetailsById={getRepoClusterDetailsById}
                      getSubEquipmentsDetailInfo={getSubEquipmentsDetailInfo}
                      showSnapShot={showSnapShot}
                      exportSnapShotPDF={exportSnapShotPDF}
                      loading={loading}
                      snapShotView={snapShotView}
                      snapShotDate={snapShotDate}
                      setSnapShotDate={setSnapShotDate}
                      snapShotTime={snapShotTime}
                      setSnapShotTime={setSnapShotTime}
                      showSnapShotClk={showSnapShotClk}
                      setShowSnapShotClk={setShowSnapShotClk}
                      getSnapShotDetails={getSnapShotDetails}
                    />
                  </div>
                )}
            </span>
          </OwcExpandable>

          <OwcExpandable variant="standard" round expanded={true}>
            <span slot="title">Logs</span>

            <span
              slot="content"
              style={{
                minHeight: "300px",
                height: "fit-content",
                overflowY: "scroll"
              }}
            >
              <LogBooksMainPage />
            </span>
          </OwcExpandable>
        </OwcExpandableGroup>
      </div>
    </>
  );
};

export default withApollo(CoverSheetMainPage);
