import { useContext, useEffect } from "react";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debounce } from "underscore";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CustomFilterContext } from "../custom-filter/context";
import { SearchTextContext } from "../log-book-item-fillter/suggester/search-context/context";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { uniq } from "lodash";
import { formTypes } from "../../../constants";

const WAIT_BEFORE_FETCH = 500;

const UpdateData = ({ client, fetchingFn, waitBeforeFetchTime = WAIT_BEFORE_FETCH, clusterLogStatus = false }) => {
  const { inventoryId } = useParams();
  const { appliedSearchText } = useContext(SearchTextContext);
  const { nextData, nextToken, nextRunToken, nextActionToken, limit, sortDirection, dispatchAction } =
    useContext(PaginationContext);
  const { dateFrom, dateTo, site, instrument, equipmentModel, signee } = useContext(CustomFilterContext);
  const { logType } = useContext(CoverSheetMainPageContext);
  const storeUser = useSelector((store) => store.user);
  const signeeList = uniq(useSelector((state) => state.equipmentModels.signee));
  useEffect(() => {
    if (nextData) {
      dispatchAction({
        type: "clearAll"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchAction({
      type: "fetching",
      payload: true
    });

    if (appliedSearchText && !instrument) {
      dispatchAction({
        type: "fetching",
        payload: false
      });
      return;
    }
    const fetchData = debounce(async () => {
      if (logType === formTypes?.ALL_LOG) {
        const { runToken, runItems } = await fetchingFn({
          client,
          sortDirection,
          equipmentModel,
          signee,
          instrument,
          dateFrom,
          dateTo,
          storeUser,
          limit,
          nextToken: nextRunToken,
          inventoryId,
          logType: "runLog",
          signeeList
        });

        dispatchAction({
          type: "nextRunToken",
          payload: {
            token: runToken,
            runData: runItems
          }
        });

        const { actionToken, actionItems } = await fetchingFn({
          client,
          sortDirection,
          equipmentModel,
          signee,
          instrument,
          dateFrom,
          dateTo,
          storeUser,
          limit,
          nextToken: nextActionToken,
          inventoryId,
          logType: "actionLog",
          signeeList
        });

        dispatchAction({
          type: "nextActionToken",
          payload: {
            token: actionToken,
            actionData: actionItems
          }
        });

        dispatchAction({
          type: "fetching",
          payload: false
        });
      } else {
        const { token, items } = await fetchingFn({
          client,
          sortDirection,
          equipmentModel,
          signee,
          instrument,
          dateFrom,
          dateTo,
          storeUser,
          limit,
          nextToken,
          inventoryId,
          logType,
          signeeList
        });

        dispatchAction({
          type: "nextToken",
          payload: {
            token,
            data: items
          }
        });
      }
    }, waitBeforeFetchTime);

    fetchData();

    return () => {
      fetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nextData,
    limit,
    site,
    dateFrom,
    dateTo,
    equipmentModel,
    signee,
    instrument,
    sortDirection,
    appliedSearchText,
    clusterLogStatus
  ]);

  return null;
};

export default withApollo(UpdateData);
