const BinIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 3H0V5H2V18C2 19.1 2.9 20 4 20H14C15.1 20 16 19.1 16 18V5H18V3ZM14 5V18H4V5H14ZM12 0H6V2H12V0Z"
        fill="#706B69"
      />
    </svg>
  );
};

export default BinIcon;
