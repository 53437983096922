import styled from "styled-components";
import InstrumentInformationsWrapper from "../instrument-informations-dialog/instrument-informations-context/InstrumentInformationsWrapper";
import ItemWrapper from "../log-book-item-form-dialog/item-context/ItemWrapper";
import ChangeLogList from "../log-book-table/ChangeLoglist";
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ChangeLogItemPage = ({ addButtonLabel, dataTestId, fetchingFn }) => {
  return (
    <ItemWrapper>
      <InstrumentInformationsWrapper addButtonLabel={addButtonLabel}>
        <ChangeLogList dataTestId={`${dataTestId}-table`} fetchingFn={fetchingFn} />
      </InstrumentInformationsWrapper>
    </ItemWrapper>
  );
};

export default ChangeLogItemPage;
