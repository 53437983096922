import { OwcChip, OwcListItem, OwcMenu } from "@one/react";
import React, { useState } from "react";

const Tags = ({ chips }) => {
  const [openChips, setOpenChips] = useState(null);
  const tags = chips || [];
  let tagsDropdown = [];
  if (tags?.length > 2) {
    tags?.forEach((element, index) => {
      if (index > 1) {
        tagsDropdown.push(element);
      }
    });
  }

  if (tags?.length === 0) {
    return <>-</>;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {tags?.length > 0 &&
            tags?.map((tag, index) => {
              if (index < 2) {
                return (
                  <div style={{ marginRight: 5 }}>
                    <OwcChip variant="outlined">{tag}</OwcChip>
                  </div>
                );
              }

              return "";
            })}
        </div>
        {tagsDropdown?.length > 0 && [
          <div
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              setOpenChips(() => event.currentTarget);
            }}
          >
            +{tagsDropdown?.length}
          </div>,
          <OwcMenu
            anchorEl={openChips}
            open={openChips}
            onClose={() => {
              setOpenChips(() => null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{ height: 300 }}
          >
            {tagsDropdown?.map((tag) => (
              <OwcListItem>{tag}</OwcListItem>
            ))}
          </OwcMenu>
        ]}
      </div>
    </>
  );
};

export default Tags;
