import { parseTimeLocal } from "../../../utils/helpers/text";
import { NormalFont } from "../NormalFont";

const parseTimeVariousInputFormats = (time) => {
  const theTime = parseTimeLocal(time);
  if (theTime !== time) {
    return theTime;
  }

  return parseTimeLocal(time);
};

export const DateTimeDetailValue = ({ date = null, time }) => {
  const theTime = parseTimeVariousInputFormats(time);
  return (
    <>
      {date ? (
        <>
          {date ?? "-"} <br />
          <NormalFont> {theTime ? `(${theTime})` : ""}</NormalFont>
        </>
      ) : (
        <>{theTime ? `${theTime}` : "-"}</>
      )}
    </>
  );
};
