import React, { useEffect, forwardRef, useState, useRef } from "react";
import styled, { css } from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import clsx from "clsx";
import { CircularProgress, Collapse } from "@mui/material";

const TableStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--one-color-gray-300);
  // margin: 1rem 0;

  ${(props) =>
    props.noBorderTop &&
    css`
      border-radius: 0 0 4px 4px;
      border-top: none;
    `}

  & .body {
    background-color: var(--one-color-cobas-white);
  }
  & .head {
    background-color: var(--one-color-cobas-gray-150);
  }
  & .head,
  & .row {
    padding: 10px 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  & .row.expanded {
    &,
    &:hover {
      background: var(--one-color-blue-100);
    }
    &:not(.expanded-content) {
      border-bottom-color: transparent;
    }
    &.expanded-content {
      position: relative;
      &::before {
        content: "";
        border-bottom: 1px solid var(--one-color-blue-300);
        position: absolute;
        top: -10px;
        left: 18px;
        right: 18px;
      }
    }
  }
  & .row.selected {
    background: #e9f4ff;
  }
  & .row:hover {
    background: var(--one-color-gray-50);

    &.selected {
      background: #d2e9ff;
    }
  }
  & .body .cell,
  & .head .cell {
    margin: 0;
    line-height: 18px;
    font-size: 14px;
  }
  & .head .cell {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  & .body .cell {
    font-weight: 500;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
  & .body .loading {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  & .cell {
    flex: 1 1 auto;

    &.sortable {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  & .empty {
    text-align: center;
    padding: 10px 16px;
  }
  & .empty,
  & .head,
  & .row {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--one-color-gray-300);
  }
  & .fetchMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }
`;
const BodyTable = styled.div`
  overflow: auto;
  max-height: 64vh;
`;
const isObject = (item) => typeof item === "object" && item !== null;

const CustomList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  loading,
  preExpanded,
  fetching,
  noBorderTop = false,
  isSelected = () => false,
  customNoDataText,
  disableStatus = false
}) => {
  const bodyRef = useRef();
  if (!isObject(meta)) {
    return null;
  }
  if (!isObject(meta?.fields)) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }

  let body = null;

  if (loading) {
    body = (
      <div className="loading">
        <CircularProgress size={30} />
      </div>
    );
  } else if (data.length === 0) {
    body = (
      <div className="empty" data-testid="custom-list-no-data">
        {customNoDataText ?? "No data"}
      </div>
    );
  } else {
    body = data.map((item, index) => {
      const id = item?.[meta?.rowId] || item?.id || (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          expandable={meta?.expandable}
          ExpandedComponent={meta?.ExpandedComponent}
          item={item}
          key={id}
          id={id}
          fields={meta?.fields}
          preExpanded={preExpanded}
          bodyRef={bodyRef}
          selected={isSelected(item)}
          lastElement={false}
          onRowClick={onRowClick}
          meta={meta}
          disableStatus={disableStatus}
        />
      );
    });
  }

  return (
    <TableStyled noBorderTop={noBorderTop} data-testid="custom-list">
      <div className="head" data-testid="custom-list-head">
        <Header meta={meta} onRequestSort={onRequestSort} isReverseOrder={isReverseOrder} orderBy={orderBy} />
      </div>
      <BodyTable className="body" ref={bodyRef}>
        {body}
        {fetching && (
          <div className="fetchMore" data-testid="custom-list-fetchmore">
            <CircularProgress size={30} />
          </div>
        )}
      </BodyTable>
    </TableStyled>
  );
};

export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent ? meta?.fields?.[key]?.headerComponent : null;
    if (!Component) {
      return meta.fields[key]?.sortable ? (
        <div
          className={clsx({
            cell: true,
            sortable: meta.fields[key]?.sortable
          })}
          data-testid={`list-head-${key}`}
          key={key}
          {...(meta?.fields?.[key]?.headProps ?? {})}
          onClick={() => {
            if (key === orderBy) {
              onRequestSort(key, !isReverseOrder);
            } else {
              onRequestSort(key, false);
            }
          }}
        >
          {meta?.fields?.[key]?.text}
          {key !== orderBy ? (
            <ArrowDropDownIcon></ArrowDropDownIcon>
          ) : isReverseOrder ? (
            <ArrowDropUpIcon data-testid="arrow-drop-down-up-icon" color="primary"></ArrowDropUpIcon>
          ) : (
            <ArrowDropDownIcon data-testid="arrow-drop-down-down-icon" color="primary"></ArrowDropDownIcon>
          )}
        </div>
      ) : (
        <div className="cell" data-testid={`list-head-${key}`} key={key} {...(meta?.fields?.[key]?.headProps ?? {})}>
          {meta?.fields?.[key]?.text}
        </div>
      );
    }
    return (
      <div
        className={clsx({
          cell: true,
          sortable: meta.fields[key]?.sortable
        })}
        data-testid={`list-head-${key}`}
        key={key}
        {...(meta?.fields?.[key]?.headProps ?? {})}
      >
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </div>
    );
  });
};

export const ItemRow = forwardRef(
  (
    {
      item,
      fields,
      id,
      expandable,
      ExpandedComponent = React.Fragment,
      preExpanded,
      selected,
      lastElement,
      onRowClick,
      meta,
      disableStatus
    },
    elementRef
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef();

    const toggleExpanded = () => {
      setIsExpanded((val) => !val);
    };

    useEffect(() => {
      if (preExpanded === item && expandable === true) {
        ref.current.scrollIntoView();
        setIsExpanded(true);
      }
    }, [ref, preExpanded, item, expandable]);

    const shouldExpand = isExpanded && expandable === true;

    return (
      <div ref={lastElement ? elementRef : null}>
        <div
          onClick={(event) => onRowClick && onRowClick(event, item)}
          className={clsx("row", {
            selected,
            expanded: isExpanded
          })}
          data-testid={`${id}-row`}
          ref={ref}
        >
          {Object.keys(fields).map((field) => {
            const Component = fields?.[field]?.component ? fields?.[field].component : null;
            if (!Component) {
              return (
                <div
                  key={`item-row-${id}-${field}`}
                  data-testid={`item-row-${id}-${field}`}
                  className="cell"
                  {...(fields?.[field]?.cellProps ?? {})}
                >
                  {item[field] && item[field] !== "null" ? item[field] : "-"}
                </div>
              );
            }
            return (
              <div
                className="cell"
                key={`item-row-${id}-${field}`}
                data-testid={`item-row-${id}-${field}`}
                {...(fields?.[field]?.cellProps ?? {})}
              >
                <Component
                  item={item}
                  {...(fields?.[field]?.cellProps ?? {})}
                  toggleExpanded={toggleExpanded}
                  expanded={shouldExpand}
                  selected={selected}
                  disableStatus={disableStatus}
                />
              </div>
            );
          })}
        </div>
        <Collapse in={shouldExpand}>
          {expandable === true && (
            <div
              className={clsx("row", "expanded-content", {
                selected,
                expanded: isExpanded
              })}
              data-testid={`${id}-row-expanded`}
            >
              <ExpandedComponent item={item} fields={fields} {...(meta.expandedComponentProps ?? {})} />
            </div>
          )}
        </Collapse>
      </div>
    );
  }
);

export default CustomList;
