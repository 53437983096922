import { changeTimeFormat } from "../../helpers";

export const emptyLogSheet = {
  action: {
    key: null,
    value: null
  },
  actionDate: new Date(),
  actionTime: changeTimeFormat(new Date()),
  approverId: null,
  approverUserName: null,
  description: null,
  editReason: "",
  editComment: "",
  ecrNumber: null,
  equipmentId: "",
  gxpReady: {
    key: null,
    value: null
  },
  id: null,
  instrumentDetails: {
    configurationBaseline: null,
    instrumentGxPStatus: null,
    equipmentNickName: null,
    responsiblePerson: null,
    manufacturer: null,
    softwareVersion: null,
    location: null,
    responsibleProxy: null
  },
  equipmentModel: "",
  inventoryId: "",
  logSheetEntryId: null,
  logStatus: null,
  materialNumber: null,
  modelVersion: null,
  operatorId: "",
  operatorUserName: "",
  serialNumber: null,
  site: null,
  externalDocument: [],
  systemStatus: {
    key: null,
    value: null
  },
  updatedSoftwareVersion: {
    newValue: null,
    shouldBePublished: null
  }
};
export const emptyLogSheetCluster = {
  action: {
    key: null,
    value: null
  },
  actionDate: new Date(),
  actionTime: changeTimeFormat(new Date()),
  approverId: null,
  approverUserName: null,
  description: null,
  ecrNumber: null,
  equipmentId: "",
  gxpReady: {
    key: null,
    value: null
  },
  gxpReadyCluster: {
    key: null,
    value: null
  },
  id: null,
  instrumentDetails: {
    configurationBaseline: null,
    instrumentGxPStatus: null,
    equipmentNickName: null,
    responsiblePerson: null,
    manufacturer: null,
    softwareVersion: null,
    location: null,
    responsibleProxy: null
  },
  equipmentModel: "",
  inventoryId: "",
  logSheetEntryId: null,
  logStatus: null,
  materialNumber: null,
  modelVersion: null,
  operatorId: "",
  operatorUserName: "",
  serialNumber: null,
  isClusterQualificationChanged: false,
  editReason: "",
  editComment: "",
  subEquipment: [],
  externalDocument: [],
  // removedSubEquipment: null,
  site: null,
  systemStatus: {
    key: null,
    value: null
  },
  systemStatusCluster: {
    key: null,
    value: null
  },
  updatedSoftwareVersion: {
    newValue: null,
    shouldBePublished: null
  }
};
