export const GridAutoCompleteStyles = {
  //...not to overflow equipment model and signee label over search when all are active
  gridAutoCompleteLableShrink: {
    "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
      transform: "translate(14px, 17px) scale(1)"
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -1.5px) scale(0.75)"
    }
  }
};
