import { OwcCard, OwcGrid } from "@one/react";
import { Description, THEME, EM_DASH } from "@digitallab/grid-common-components";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";

export const EquipmentDetailsDisplay = ({
  equipmentDetails
}: {
  equipmentDetails: DigitalLabLogbookInstrumentModelType;
}) => {
  const equipmentDetailsGrid = [
    [
      { label: "Category", value: equipmentDetails.equipmentCategory },
      { label: "Manufacturer", value: equipmentDetails.manufacturer },
      { label: "Model", value: equipmentDetails.equipmentModel }
    ],
    [
      { label: "Variant", value: equipmentDetails.variant },
      { label: "Nickname", value: equipmentDetails.equipmentNickName },
      { label: "Location", value: equipmentDetails.location }
    ],
    [
      { label: "Serial number", value: equipmentDetails.serialNumber },
      { label: "Equipment ID", value: equipmentDetails.equipmentId },
      { label: "Material number", value: equipmentDetails.materialNumber }
    ]
  ];

  return (
    <OwcCard
      style={{
        backgroundColor: THEME["one-color-cobas-gray-50"],
        padding: 0
      }}
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
    >
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          padding: 0,
          paddingRight: 8
        }}
      >
        {equipmentDetailsGrid.map((row, index) => (
          <div key={"row-" + index}>
            <OwcGrid
              columns={12}
              container
              hGutter={parseInt(THEME["one-spacer-12"])}
              vGutter={parseInt(THEME["one-spacer-24"])}
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              {row.map((item, index) => (
                <OwcGrid
                  key={"column-" + index}
                  item
                  xs={12}
                  s={4}
                  style={{ alignSelf: "center" }}
                  placeholder=""
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                >
                  <Description label={item.label} value={item.value || EM_DASH} />
                </OwcGrid>
              ))}
            </OwcGrid>
            {index !== equipmentDetailsGrid.length - 1 && <br />}
          </div>
        ))}
      </div>
    </OwcCard>
  );
};
