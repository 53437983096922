import { EquipmentDetailsDisplay } from "./EquipmentDetailsDisplay";
import { GET_CHECKLIST_FOR_EQUIPMENT } from "../../gql/logBooksapi/queries";
import { useQueryExt } from "./useQuery";

import {
  AlertIcon,
  CenteredSpinner,
  FolderEmptyIcon,
  IconWithTexts,
  RANGE_SPACE,
  SVGWrapper
} from "@digitallab/grid-common-components";
import { OwcHyperlink, OwcIcon } from "@one/react";
import { INSTRUMENTS_REPO_ENDPOINT } from "../../constants";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";
import { Fragment } from "react/jsx-runtime";

export type ChecklistModelType = {
  url: string;
  description: string;
  models: string[];
  groups: string[];
  sites: string[];
};

export const ViewChecklist = ({ equipmentDetails }: { equipmentDetails: DigitalLabLogbookInstrumentModelType }) => {
  const { loading, data, error } = useQueryExt({
    query: GET_CHECKLIST_FOR_EQUIPMENT,
    endpoint: INSTRUMENTS_REPO_ENDPOINT,
    variables: {
      input: {
        model: equipmentDetails.equipmentModel,
        site: equipmentDetails.siteName,
        group: equipmentDetails.belongingToGroup
      }
    }
  });
  const checklists = (data?.getChecklistsForEquipment as { items: ChecklistModelType[] })?.items;
  return (
    <div style={{ width: "100%", minHeight: 400 }}>
      <EquipmentDetailsDisplay equipmentDetails={equipmentDetails} />
      <br />
      <div style={{ height: 200, overflow: "auto" }}>
        {error === undefined || loading ? (
          <CenteredSpinner />
        ) : error ? (
          <IconWithTexts icon={<SVGWrapper icon={<AlertIcon />} />} mainText="Error" additionalText={error} />
        ) : checklists?.length > 0 ? (
          checklists.map(({ url: link, description }, index) => (
            <Fragment key={index}>
              <div style={{ display: "flex" }}>
                <OwcIcon
                  name="link"
                  type="outlined"
                  placeholder=""
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                ></OwcIcon>
                {RANGE_SPACE}
                <OwcHyperlink
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  placeholder=""
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                >
                  {description}
                </OwcHyperlink>
              </div>
              <br />
            </Fragment>
          ))
        ) : (
          <IconWithTexts
            icon={<SVGWrapper icon={<FolderEmptyIcon />} />}
            additionalText={"Selected equipment does not have any checklist"}
            mainText={""}
          />
        )}
      </div>
    </div>
  );
};
