import { Notify, toQueryParameter } from "@digitallab/grid-common-components";
import gql from "graphql-tag";
import { WithApolloClient } from "react-apollo";
import { InstrumentDetailsForStepperModelType } from "./StepperContainer";
import { History } from "history";

export const loadEquipmentsData = async (
  equipmentIdList: string[],
  client: WithApolloClient<any>,
  history: History<unknown>,
  pathname: string
) => {
  const queryString = equipmentIdList
    .map(
      (_, index) => `
  input${index}: getDigitalLabLogbookInstrument(inventoryId: $input${index}) {
     belongingToGroup,
     configurationDetails,
     equipmentId,
     equipmentModel,
     equipmentNickName,
     inventoryId,
     location,
     qualificationStatus,
     serialNumber,
     siteName,
     status,
     systemStatus,
  }
`
    )
    .join("\n");
  const output = ` query BulkGetDigitalLabLogbookInstrument(
      ${equipmentIdList.map((_, index) => `$input${index}: String!`).join(", ")}
    ) {
      ${queryString}
    }`;

  const variables = Object.fromEntries(equipmentIdList.map((equipment, index) => [`input${index}`, equipment]));

  try {
    const result = await client.query({
      query: gql(output),
      fetchPolicy: "no-cache",
      variables
    });
    // as we send bulk request - return data are in shape {input0:{...equipmentDetails},input1:{...nextEqupmentDetails}}
    if (Object.values(result.data)) {
      const foundEquipment = Object.values(result.data as { input: InstrumentDetailsForStepperModelType }).filter(
        Boolean
      );

      if (foundEquipment.length < equipmentIdList.length) {
        const foundEquipmentIds = foundEquipment.map(({ inventoryId }) => inventoryId);

        const removedInventoryIds = equipmentIdList.filter((item) => !foundEquipmentIds.includes(item));
        const newSearch = toQueryParameter("equipmentIdList", foundEquipmentIds);
        history.replace(pathname + newSearch);
        throw new Error(`Unable to find equipment with following id(s): ${removedInventoryIds.join(", ")}`);
      }
      return foundEquipment;
    } else {
      throw new Error(`Did not received any data in response`);
    }
  } catch (error) {
    Notify({
      title: "Error when loading equipment(s) details",
      text: (error as Error).message,
      type: "warning",
      icon: "alarm"
    });
  }
  return [];
};
