import React from "react";
import { OwcHorizontalStep, OwcHorizontalStepper, OwcIcon, OwcTooltip } from "@one/react";
import { formTypes } from "../../../constants";

/**
 * Assign the project to an employee.
 * @param {Object} stepsArray - Array of Object.
 * @param {string} stepsArray.label - label like showing step index
 * @param {string} stepsArray.description - description.
 * @param {boolean} stepsArray.active - false.
 * @param {boolean} stepsArray.completed - false.
 * @param {boolean} stepsArray.valid - false.
 * @param {boolean} stepsArray.error - false.
 */
const FormSteps = ({ stepsArray = [], activeStep, handleClick, isEditMode = false, type }) => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <OwcHorizontalStepper
        data-testid="booking-calendar-stepper-element-box"
        activeStep={activeStep.id}
        style={{
          width: type === formTypes?.RUN_LOG ? "50%" : "73%",
          margin: "15px 0 14px 0",
          minWidth: "fit-content"
        }}
      >
        {stepsArray.map((label, index) => {
          if (isEditMode && label.id === 1) {
            label.description = "Update equipment";
          }
          const labelProps = {
            completed: label.completed,
            error: label.error,
            active: label.active,
            disabled: label.disabled,
            cursor: label.cursor
          };

          return (
            <OwcHorizontalStep
              data-testid={`booking-calendar-stepper-element-step`}
              onClick={() => {
                handleClick(label);
              }}
              style={{ cursor: isEditMode ? "pointer" : "default" }}
              {...labelProps}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div style={{ paddingRight: "10px" }}>{label.description}</div>
                <div>
                  <OwcIcon id={`tooltip-trigger-${type}-${label.id}`} name="circle_help" />
                  <OwcTooltip anchor={`tooltip-trigger-${type}-${label.id}`} placement="right">
                    {label.tooltip}
                  </OwcTooltip>
                </div>
              </div>
            </OwcHorizontalStep>
          );
        })}
      </OwcHorizontalStepper>
    </div>
  );
};

export default FormSteps;
