import * as types from "./actionTypes";

const initialState = {
  client: null
};

export default function appSyncReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.SET_CLIENT: {
      return {
        ...state,
        client: payload
      };
    }
    default:
      return state;
  }
}
