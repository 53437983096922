import React, { useRef, useState } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  FormControl,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { OwcButton } from "@one/react";

export const getCurrentDatas = (limit, pageVal, totalDatas) => {
  const startPoint = limit * pageVal;
  const endPoint = limit + limit * pageVal;
  return totalDatas.slice(startPoint, endPoint);
};

export const ButtonNext = ({ onPaginationChange, totalPages, currentPage }) => {
  return (
    <IconButton
      aria-label="Next page"
      data-testid="button-next-pagination"
      size="small"
      title="Next page"
      disabled={currentPage === totalPages}
      onClick={() => {
        onPaginationChange({ type: "NextPage" });
      }}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

export const ButtonPrev = ({ onPaginationChange, currentPage }) => {
  return (
    <IconButton
      aria-label="Prev page"
      data-testid="button-prev-pagination"
      size="small"
      title="Prev page"
      disabled={currentPage === 1}
      onClick={() => {
        onPaginationChange({ type: "PreviousPage" });
      }}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export const FirstPage = ({ onPaginationChange, currentPage }) => {
  return (
    <IconButton
      aria-label="first page"
      data-testid="dlab-first-page"
      size="small"
      title="First page"
      disabled={currentPage === 1}
      onClick={() => {
        onPaginationChange({ type: "FirstPage" });
      }}
    >
      <FirstPageIcon />
    </IconButton>
  );
};

export const LastPage = ({ onPaginationChange, totalPages, currentPage }) => {
  return (
    <IconButton
      aria-label="last page"
      data-testid="dlab-last-page"
      size="small"
      title="Last page"
      disabled={currentPage === totalPages}
      onClick={() => {
        onPaginationChange({ type: "LastPage" });
      }}
    >
      <LastPageIcon />
    </IconButton>
  );
};

const PageRowNuberSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  & > div {
    margin-left: 8px;
  }
  #paginator-row-number-selector {
    padding: 6px 32px 6px 16px;
  }
`;

export const PageRowNuberSelector = ({ onPaginationChange, limit }) => {
  return (
    <PageRowNuberSelectorStyled>
      <div>Rows per page: </div>
      <FormControl variant="outlined">
        <Select
          margin="dense"
          labelId="paginator-row-number-selector"
          data-testid="paginator-row-number-selector"
          id="paginator-row-number-selector"
          value={limit}
          onChange={(event) => {
            onPaginationChange({ type: "Limit", value: event.target.value });
          }}
          MenuProps={{
            style: {
              zIndex: 1600
            }
          }}
        >
          <MenuItem data-testid="paginator-row-number-selector-item-v10" value={10}>
            10
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v20" value={20}>
            20
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v50" value={50}>
            50
          </MenuItem>
        </Select>
      </FormControl>
    </PageRowNuberSelectorStyled>
  );
};

const PaginationActionsStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 70px;
  width: 100%;
  background-color: #ffffff;
  & > .pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 0.5rem;
    align-items: center;
    & button:first-of-type {
      margin-left: 10px;
    }
  }
`;

/**
 *
 * @param {options} param0
 * @sampleStructure {
    actions: [
      {
        key: "add_equipment",
        value: "Add equipment",
        onClick: () => {
          console.log("add equipment");
        }
      },
      {
        key: "add_cluster",
        value: "Add cluster",
        onClick: () => {
          console.log("add cluster");
        }
      }
    ]
  }
 */

export const DLabGridPagination = ({
  onPaginationChange,
  limit,
  totalPages,
  currentPage,
  totalRows,
  options = {
    actions: []
  }
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (event, item) => {
    item?.onClick(event);
  };

  const handleMenuItemClick = (event, key, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <PaginationActionsStyled>
      <div className="pagination">
        <PageRowNuberSelector onPaginationChange={onPaginationChange} limit={limit} />
        <div>
          {limit * currentPage - limit + 1} -{" "}
          {limit + limit * currentPage - limit > totalRows ? totalRows : limit + limit * currentPage - limit} of{" "}
          {totalRows}
        </div>

        <FirstPage onPaginationChange={onPaginationChange} currentPage={currentPage} />
        <ButtonPrev onPaginationChange={onPaginationChange} currentPage={currentPage} />
        <ButtonNext onPaginationChange={onPaginationChange} totalPages={totalPages} currentPage={currentPage} />
        <LastPage
          onPaginationChange={onPaginationChange}
          totalPages={totalPages}
          currentPage={currentPage}
          totalRows={totalRows}
        />
      </div>
      {options?.actions?.length === 1 && (
        <div
          style={{
            display: "flex",
            width: "300px",
            alignItems: "center",
            justifyContent: "end",
            marginRight: "10px"
          }}
        >
          <OwcButton
            variant="primary"
            onClick={(event) => {
              handleClick(event, options?.actions[selectedIndex]);
            }}
            disabled={options?.actions[selectedIndex]?.disabled}
            color="secondary"
            data-testid="try-again-button"
          >
            {options?.actions[selectedIndex]?.value}
          </OwcButton>
        </div>
      )}
      {options?.actions?.length > 1 && (
        <div
          style={{
            display: "flex",
            width: "200px",
            alignItems: "center",
            justifyContent: "end",
            marginRight: "10px"
          }}
        >
          <ButtonGroup variant="outlined" size="small" color="primary" ref={anchorRef} aria-label="split button">
            <Button
              style={{
                textTransform: "none"
              }}
              onClick={(event) => {
                handleClick(event, options?.actions[selectedIndex]);
              }}
              size="small"
            >
              {options?.actions[selectedIndex]?.value}
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options?.actions?.map((option, index) => (
                        <MenuItem
                          key={option?.key}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, option?.key, index)}
                        >
                          {option?.value}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </PaginationActionsStyled>
  );
};

export default DLabGridPagination;
