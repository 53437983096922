export const LogStatus = {
  ACTIVE: "ACTIVE",
  DELETE: "DELETE"
};
export const RunStatus = {
  COMPLETED: "COMPLETED",
  PROCESSING: "PROCESSING",
  RUN_ABORT: "RUN_ABORT",
  SUCCESS: "SUCCESS",
  USER_ABORT: "USER_ABORT"
};
export const RunStatusLabels = [
  { key: RunStatus.COMPLETED, value: "Completed" },
  { key: RunStatus.PROCESSING, value: "Processing" },
  { key: RunStatus.RUN_ABORT, value: "Run abort" },
  { key: RunStatus.SUCCESS, value: "Success" },
  { key: RunStatus.USER_ABORT, value: "User abort" }
];
