import * as yup from "yup";
import moment from "moment";

export const runLogValidationSchema = () =>
  yup.object({
    runStartDate: yup.date().typeError("Enter run start date").required("Enter correct run start date"),

    runStartTime: yup
      .string()
      .typeError("Enter run start time (with am/pm)")
      .nullable()
      .test(
        "startTimeValidation",
        "start time should not be empty when end time is selected",
        (value, obj) => !!value || !obj.parent.runEndTime
      ),
    runEndDate: yup
      .date()
      .required("Enter correct run end date")
      .test("dateValidation", "End date cannot be before start date", (value, obj) => {
        if (obj.parent.runStartDate > value!) return false;
        return true;
      })
      .typeError("Enter run end date"),

    runEndTime: yup
      .string()
      .typeError("Enter run end time (with am/pm)")
      .nullable()
      .test("mintimeValidation", "End time is less than Start time", (value, obj) => {
        if (obj.parent.runStartDate.getTime() === obj.parent.runEndDate.getTime() && obj.parent.runStartTime && value) {
          const startTime = moment(obj.parent.runStartTime, "hh:mm:ss");
          const endTime = moment(value, "hh:mm:ss");
          return startTime.isSameOrBefore(endTime);
        }
        return true;
      }),
    runStatus: yup.string().typeError("Enter run status").required("Enter run status"),
    runIdentification: yup.string().typeError("Enter run Id").nullable(),
    samplesProcessed: yup.number().typeError("Enter number of samples").nullable(),
    numberOfRuns: yup.number().typeError("Enter number of runs").nullable(),
    description: yup.string().typeError("Enter description").required("Enter description"),
    defectId: yup.string().typeError("Enter defect Id").nullable(),
    eLNid: yup.string().typeError("Enter Eln ID").nullable(),
    mediaType: yup
      .array(
        yup.object({
          key: yup.string().typeError("Enter media Type"),
          value: yup.string().typeError("Enter media Type")
        })
      )
      .max(3, "You can add/select max of 3")
      .nullable(),
    tipsUsed: yup
      .object({
        key: yup.string().typeError("Enter tips Used"),
        value: yup.string().typeError("Enter tips Used")
      })
      .nullable(),

    operatorId: yup.string().nullable(),
    assay: yup.array(yup.string().typeError("Enter assay")).nullable()
  });
