import { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllData } from "../../utils/helpers/fetching";
import { LIST_DIGITAL_LAB_LOGBOOK_REASON } from "../../gql/logBooksapi/queries";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "../../components/shared/GridAutoCompleteStyles";
import { OwcTextarea } from "@one/react";
import { find } from "lodash";

const ReasonForm = styled.div`
  & > .customSelect {
    margin-bottom: 10px;
  }
`;

const ReasonDropdown = ({ client, setSelectedReason, selectedReason, addComment, setAddComment }) => {
  const [reasonList, setReasonList] = useState([]);
  const getReasonList = async () => {
    const list = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_LOGBOOK_REASON,
      fetchPolicy: "network-only",
      dataPath: ["data", "configurationByModelAndText"],
      drillData: false
    });
    const tempList = list.items.map((x) => {
      return { id: x.id, key: x.configText, value: x.configText };
    });
    setReasonList([...tempList]);
  };

  useEffect(() => {
    getReasonList();
    setSelectedReason("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReasonForm>
      <GridAutocomplete
        style={{ width: "100%" }}
        dataTestid="reason-select"
        className="customSelect"
        size="small"
        sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
        value={find(reasonList, { value: selectedReason }) || null}
        optionsList={reasonList}
        propValue="value"
        propLabel="key"
        label="Select reason"
        onAutoCompleteChange={(selectedValue) => {
          if (selectedValue !== null) {
            setSelectedReason(selectedValue?.value);
          }
        }}
        disableClearable
      />

      <OwcTextarea
        style={{ width: "100%" }}
        data-testid={`textarea-field-edit-comment`}
        label="Add comment"
        name="editComment"
        value={addComment}
        onInputChange={(event) => {
          setAddComment(event.target.value);
        }}
        onInputKeyDown={(event) => {
          setAddComment(event.target.value);
        }}
        onInputReset={() => setAddComment("")}
        maxLength={80}
        cols={18}
        rows={4}
      />
    </ReasonForm>
  );
};

export default compose(connect(null), withApollo)(ReasonDropdown);
