import React from "react";
import MaintenanceIcon from "../icons/MaintenanceIcon";
import { ErrorScreenStyled } from "./NotFoundScreen";

const MaintenanceScreen = ({
  text = "The application is currently undergoing scheduled maintenance. We should be back shortly",
  title = "This application is under maintenance"
}) => {
  return (
    <ErrorScreenStyled data-testid="main-page-with-maintenance-mode">
      <MaintenanceIcon />
      <h2>{title}</h2>
      <h6>{text}</h6>
    </ErrorScreenStyled>
  );
};

export default MaintenanceScreen;
