import * as yup from "yup";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const InstrumentDetailsValidationSechma = {
  instrumentDetails: yup.object({
    configurationBaseline: yup.string().nullable(),
    instrumentGxPStatus: yup.string().nullable(),
    equipmentNickName: yup.string().nullable(),
    responsiblePerson: yup.string().nullable(),
    softwareVersion: yup.string().nullable(),
    manufacturer: yup.string().nullable(),
    location: yup.string().nullable(),
    responsibleProxy: yup.string().nullable()
  })
};

export const subEquipmentValidationSechma = {
  subEquipment: yup.object({
    systemStatus: yup
      .object({
        key: yup.string("Enter system Status").typeError("Enter system Status"),
        value: yup.string("Enter system Status").typeError("Enter system Status")
      })
      .typeError("Enter system Status"),
    actionDate: yup.date().typeError("Enter action date"),
    actionTime: yup.string().required().typeError("Enter action time"),
    gxpReady: yup
      .object({
        key: yup
          .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
          .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
        value: yup
          .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
          .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
      })
      .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
    ecrNumber: yup.string("Enter ECR number").typeError("Enter ECR number").nullable(),
    logStatus: yup.string().typeError().nullable(),
    modelVersion: yup.string().typeError().nullable(),
    site: yup.string(),
    approverId: yup.string(),
    approverUserName: yup.string(),
    id: yup.string(),
    logSheetEntryId: yup.string(),
    materialNumber: yup.string(),
    description: yup.string("Enter description").typeError("Enter description"),
    operatorId: yup.string().nullable(),
    operatorUserName: yup.string("Enter operator user name").typeError("Enter operator User Name").nullable(),
    serialNumber: yup.string(),
    equipmentId: yup.string(),
    equipmentModel: yup.string(),
    equipmentNickName: yup.string(),
    inventoryId: yup.string(),
    updatedSoftwareVersion: yup.object({
      newValue: yup.string("Enter New software version").typeError("EnterNew software version").nullable(),
      shouldBePublished: yup
        .boolean("Enter publish to equipment repository")
        .typeError("Enter publish to equipment repository")
        .nullable()
    })
  })
};
