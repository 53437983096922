import { useFormikContext } from "formik";
import { entryType, equipmentStatus, formStates } from "../../../../constants";
import { Mainbox, FirstHalfCell, LabelInnerHeading } from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import RunSelectInput from "../../run-logs/run-logs-form/run-data-inputs/RunSelectInput";
import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import { useContext, useRef, useState } from "react";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { useEffect } from "react";
import { LogBookSelectContext } from "../../log-book-table/logBooksSelectAll/context";
import { OwcChip, OwcIcon, OwcListItem, OwcSelectDropdown, OwcSlideToggle, OwcTypography } from "@one/react";
import styles from "./ActionDataInputsBoxCluster.module.scss";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import ClusterGridTableAddFlow from "../../../../components/shared/ClusterAgGridTableAddFlow";
import { generateID } from "@digitallab/grid-common-components";
import { find, isEqual, omit, capitalize } from "lodash";
import { StatusClusterComponent, subClusterMapping } from "../../log-book-table/LogBookClusterTable";
import omitDeep from "omit-deep-lodash";
import moment from "moment";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import { replaceEmptyWithHyphen, validateSelection } from "../../../../utils/helpers/text";
import { ClusterActions } from "../../../../components/shared/ClusterActions";

const ActionDataInputsBoxClusterImpact = ({ equipmentDetail }) => {
  const formik = useFormikContext();

  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const systemStatuss = useSelector((store) => store.runLogsForm.systemStatuss);
  const { setSubClusterFlag, setIsSubmitDisabled, setPropagationSync, subClusterPendingFlag } =
    useContext(ItemDialogFormContext);
  const { selected, onSetSelection, selectedOnlyParents, onAddSelectedParents } = useContext(LogBookSelectContext);
  const [thirdStepTableData, setThirdStepTableData] = useState([]);
  const [thirdStepTableDataInitial, setThirdStepTableDataInitial] = useState([]);
  const currentTableData = useRef([]);
  const { formState } = useContext(CoverSheetMainPageContext);
  const [isSubmitDisabledExist, setSubmitDisabledExist] = useState(false);
  const [isSubSystemStatusChange, setIsSubSystemStatusChange] = useState(false);
  const [isSubQualificationStatusChange, setIsSubQualificationStatusChange] = useState(false);

  const updateReduxKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(
      updatedDetail,
      "__typename",
      "id",
      "approverId",
      "runLogEntryId",
      "logSheetEntryId",
      "approverUserName",
      "instrumentDetails"
    );
    tempDetail = {
      ...updatedDetail
    };
    return tempDetail;
  };

  const updateFormikKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(
      updatedDetail,
      "__typename",
      "editReason",
      "editComment",
      "runLogEntryId",
      "logSheetEntryId",
      "id",
      "approverId",
      "approverUserName",
      "instrumentDetails"
    );
    tempDetail = {
      ...updatedDetail,
      actionDate: (updatedDetail.actionDate =
        moment(updatedDetail?.actionDate).format("YYYY-MM-DD") || updatedDetail.actionDate),
      ecrNumber: updatedDetail?.ecrNumber || null,
      gxpReady: omit(updatedDetail?.gxpReady, "site"),
      gxpReadyCluster: omit(updatedDetail?.gxpReadyCluster, "site"),
      systemStatus: omit(updatedDetail?.systemStatus, "site"),
      systemStatusCluster: omit(updatedDetail?.systemStatusCluster, "site")
    };
    return tempDetail;
  };

  useEffect(() => {
    if (formState === formStates?.EDITABLE_FORM) {
      let tempequipmentDetail = updateReduxKeys(equipmentDetail);
      let tempFormikValues = updateFormikKeys(formik?.values);
      if (!isEqual(tempequipmentDetail, tempFormikValues)) {
        setSubmitDisabledExist(() => true);
      }
      setIsSubmitDisabled(() => isEqual(tempequipmentDetail, tempFormikValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  useEffect(() => {
    const flatSubEquipments = subClusterMapping(equipmentDetail?.subEquipment);
    let onlyParentEquipments = [];
    let selectedEquipments = [...selected];
    selectedEquipments.forEach((item) => {
      if (item?.clusterId) {
        let parentObj = find(flatSubEquipments, {
          inventoryId: item?.clusterId
        });
        if (parentObj && !find(selectedEquipments, { inventoryId: parentObj?.inventoryId })) {
          const existingObj = find(selectedOnlyParents, {
            inventoryId: parentObj?.inventoryId
          });
          onlyParentEquipments.push(existingObj ? existingObj : parentObj);
        }
      }
    });
    onAddSelectedParents(onlyParentEquipments);
    currentTableData.current = [...selected, ...selectedOnlyParents];
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setThirdStepTableData(() => [...selected, ...selectedOnlyParents]);
    // eslint-disable-next-line
  }, [selected, selectedOnlyParents]);

  useEffect(() => {
    formik.setFieldValue("gxpReady", formik.values?.gxpReadyCluster);
    formik.setFieldValue("systemStatus", formik.values?.systemStatusCluster);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.gxpReadyCluster, formik.values?.systemStatusCluster]);

  useEffect(() => {
    if (
      thirdStepTableData.length > 0 &&
      (thirdStepTableDataInitial.length === 0 || thirdStepTableDataInitial.length !== thirdStepTableData.length)
    ) {
      setThirdStepTableDataInitial([...thirdStepTableData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdStepTableData]);

  useEffect(() => {
    let systemDiffCount = 0;
    let gxpDiffCount = 0;
    if (thirdStepTableData.length > 0) {
      thirdStepTableData?.forEach((item, index) => {
        if (thirdStepTableData[index]?.systemStatus?.value !== thirdStepTableDataInitial[index]?.systemStatus?.value) {
          systemDiffCount++;
        }
      });
    }

    if (thirdStepTableData.length > 0) {
      thirdStepTableData?.forEach((item, index) => {
        if (thirdStepTableData[index]?.gxpReady?.value !== thirdStepTableDataInitial[index]?.gxpReady?.value) {
          gxpDiffCount++;
        }
      });
    }

    if (
      formik.values?.systemStatusCluster?.value &&
      formik.values?.systemStatusCluster?.value !== capitalize(equipmentDetail?.equipSystemStatus)
    ) {
      systemDiffCount++;
    }

    if (
      formik.values?.gxpReadyCluster?.key &&
      formik.values?.gxpReadyCluster?.key !== equipmentDetail?.qualificationStatus
    ) {
      gxpDiffCount++;
    }
    if (subClusterPendingFlag) {
      setPropagationSync(4);
    } else if (systemDiffCount > 0 && gxpDiffCount > 0) {
      setPropagationSync(3);
    } else if (systemDiffCount > 0) {
      setPropagationSync(2);
    } else if (gxpDiffCount > 0) {
      setPropagationSync(1);
    } else {
      setPropagationSync(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values?.gxpReadyCluster,
    formik.values?.systemStatusCluster,
    isSubSystemStatusChange,
    isSubQualificationStatusChange
  ]);
  useEffect(() => {
    let gxpReadyStatus = false;
    let systemStatus = false;
    thirdStepTableData?.forEach((item) => {
      if (item?.gxpReady?.key === null || item?.gxpReady?.key === undefined) {
        gxpReadyStatus = true;
      }
      if (item?.systemStatus?.key === null || item?.systemStatus?.key === undefined) {
        systemStatus = true;
      }
    });
    if (gxpReadyStatus || systemStatus) {
      setSubClusterFlag(true);
    } else {
      setSubClusterFlag(false);
    }

    if (!isSubmitDisabledExist && thirdStepTableData && thirdStepTableData?.length > 0) {
      setIsSubmitDisabled(() => isEqual(currentTableData.current, thirdStepTableData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdStepTableData]);

  const updatedStateEquipments = (modifiedData) => {
    let onlyParentModifiedData = [];
    let selectedData = modifiedData?.filter((item) => {
      let obj = find(selectedOnlyParents, { inventoryId: item?.inventoryId });
      if (obj) {
        onlyParentModifiedData?.push(item);
        return false;
      }
      return true;
    });
    onAddSelectedParents(onlyParentModifiedData);
    onSetSelection(selectedData);
  };

  const EquipmentMetaDisplay = {
    fields: {
      clusterIdentifier: {
        headProps: { style: { width: 100 } },
        cellProps: { style: { width: 100 } },
        text: "",
        sortable: false,
        component: ({ item, triggerClusterSetCollapse, client, clusterEquipmentInfoAction }) => {
          switch (item.entryType) {
            case entryType?.cluster:
              return (
                <ClusterActions
                  client={client}
                  item={item}
                  triggerClusterSetCollapse={triggerClusterSetCollapse}
                  clusterEquipmentInfoAction={clusterEquipmentInfoAction}
                  viewExpander={true}
                />
              );
            default:
              return "";
          }
        }
      },
      equipmentModel: {
        text: DATA_MODEL_TABLE.equipmentModel.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        }
      },
      variant: {
        text: DATA_MODEL_TABLE.variant.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentId: {
        text: DATA_MODEL_TABLE.equipmentId.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      serialNumber: {
        text: DATA_MODEL_TABLE.serialNumber.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentNickName: {
        text: DATA_MODEL_TABLE.equipmentNickName.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      status: {
        text: DATA_MODEL_TABLE.status.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => {
          return (
            <div style={{ marginTop: "10px" }}>
              <StatusClusterComponent params={params} />
            </div>
          );
        }
      },
      gxpReady: {
        text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
        sortable: true,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => (
          <OwcSelectDropdown
            outlined
            notFoundText={"No options"}
            style={{ width: 155, margin: 5 }}
            placeholder="Select"
            onSelectChange={(e) => {
              let selected = e.detail;
              selected = selected.map((v) => gxpReadys[v]);
              const inventoryId = params?.data?.inventoryId;
              const modifiedData = thirdStepTableData.map((item) => {
                if (item.inventoryId === inventoryId) {
                  const newData = {
                    ...item,
                    gxpReady: {
                      key: selected[0].key,
                      value: selected[0].value
                    }
                  };
                  return newData;
                }
                return item;
              });
              setThirdStepTableData(() => modifiedData);
              updatedStateEquipments([...modifiedData]);
              setIsSubQualificationStatusChange(!isSubQualificationStatusChange);
            }}
            id={generateID.dropDownID("gxpReadys", "dropdown")}
            disabled={
              formState !== formStates?.EDITABLE_FORM && params?.data?.status === equipmentStatus?.pending?.key
                ? true
                : false
            }
          >
            {gxpReadys?.map((gxpReadyItem, index) => {
              return (
                <>
                  <OwcListItem selected={gxpReadyItem?.value === params?.data?.gxpReady?.value}>
                    {gxpReadyItem?.value}
                  </OwcListItem>
                </>
              );
            })}
          </OwcSelectDropdown>
        )
      },
      systemStatus: {
        text: DATA_MODEL_TABLE.systemStatus.value,
        sortable: true,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => (
          <OwcSelectDropdown
            outlined
            notFoundText={"No options"}
            style={{ width: 155, margin: 5 }}
            placeholder="Select"
            onSelectChange={(e) => {
              let selected = e.detail;
              selected = selected.map((v) => systemStatuss[v]);
              const inventoryId = params?.data?.inventoryId;
              const modifiedData = thirdStepTableData.map((item) => {
                if (item.inventoryId === inventoryId) {
                  const newData = {
                    ...item,
                    systemStatus: {
                      key: selected[0].key,
                      value: selected[0].value
                    }
                  };
                  return newData;
                }
                return item;
              });
              setThirdStepTableData(modifiedData);
              updatedStateEquipments([...modifiedData]);
              setIsSubSystemStatusChange(!isSubSystemStatusChange);
            }}
            id={generateID.dropDownID("systemStatuss", "dropdown")}
            disabled={
              formState !== formStates?.EDITABLE_FORM && params?.data?.status === equipmentStatus?.pending?.key
                ? true
                : false
            }
          >
            {systemStatuss?.map((statusItem, index) => {
              return (
                <>
                  <OwcListItem selected={statusItem?.value === capitalize(params?.data?.systemStatus?.value)}>
                    {statusItem?.value}
                  </OwcListItem>
                </>
              );
            })}
          </OwcSelectDropdown>
        )
      }
    },
    rowKeyId: "id"
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
        <div style={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
          <OwcTypography variant="body1" style={{ marginRight: "5px" }}>
            <strong>{DATA_MODEL_TABLE?.gxpReady?.value}:</strong>
          </OwcTypography>

          {equipmentDetail?.qualificationStatus ? (
            <>
              <OwcChip
                outlined
                label={replaceEmptyWithHyphen(
                  validateSelection(gxpReadys, {
                    key: equipmentDetail?.qualificationStatus
                  })
                )}
                style={{ marginRight: "10px" }}
              >
                {replaceEmptyWithHyphen(
                  validateSelection(gxpReadys, {
                    key: equipmentDetail?.qualificationStatus
                  })
                )}
              </OwcChip>
            </>
          ) : (
            "-"
          )}
        </div>

        <div style={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
          <OwcTypography variant="body1" style={{ marginRight: "5px" }}>
            <strong>System status:</strong>
          </OwcTypography>
          {equipmentDetail?.equipSystemStatus ? (
            <>
              <OwcChip outlined label={capitalize(equipmentDetail?.equipSystemStatus)}>
                {capitalize(equipmentDetail?.equipSystemStatus)}
              </OwcChip>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center"
        }}
      >
        <OwcSlideToggle
          id={`devices-item-checkbox`}
          data-testid={`devices-item-checkbox`}
          className={styles.SlideToggle}
          checked={formik.values?.isClusterQualificationChanged}
          onInputChange={(event) => {
            formik.setFieldValue("isClusterQualificationChanged", event.target.checked, true);

            if (event.target.checked) {
              formik.setFieldValue(
                "gxpReadyCluster",
                find(gxpReadys, {
                  key: equipmentDetail?.qualificationStatus
                }) || null
              );
              formik.setFieldValue(
                "systemStatusCluster",
                find(systemStatuss, {
                  value: capitalize(equipmentDetail?.equipSystemStatus)
                }) || null
              );
            }
            event.stopPropagation();
          }}
        />
        <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
          <strong>No change to parent cluster</strong>
        </OwcTypography>
      </div>
      <Mainbox>
        <div style={{ display: "grid" }}>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={gxpReadys}
            property="gxpReadyCluster"
            Selectlabel={ACTION_LOGS_DATA_MODEL.gxpReady.value}
            testid="gxpReadyCluster"
            propValue="key"
            propLabel="value"
            required
            SelectProps={{
              disabled: formik.values?.isClusterQualificationChanged
            }}
          />
          {formState !== formStates?.EDITABLE_FORM && !formik.values?.isClusterQualificationChanged && (
            <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
              Propagate to repository
            </OwcTypography>
          )}
        </div>
        <div>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={systemStatuss}
            property="systemStatusCluster"
            Selectlabel={ACTION_LOGS_DATA_MODEL.systemStatus.value}
            testid="systemStatusCluster"
            propValue="key"
            propLabel="value"
            required
            fullWidth
            SelectProps={{
              disabled: formik.values?.isClusterQualificationChanged
            }}
          />
          {formState !== formStates?.EDITABLE_FORM && !formik.values?.isClusterQualificationChanged && (
            <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
              Propagate to repository
            </OwcTypography>
          )}
        </div>
      </Mainbox>
      <FirstHalfCell>
        <div>
          <LabelInnerHeading>
            Change to sub components (Change has an impact on the following sub components)
          </LabelInnerHeading>
          {formState !== formStates?.EDITABLE_FORM && (
            <OwcTypography variant="body1">
              <div style={{ display: "flex" }}>
                <OwcIcon name="circle_info" type="outlined" style={{ marginRight: "5px" }} />
                The 'System status' and 'Qualification status' of the equipment will be propagated to the repository,
                only if their ‘Equipment status’ is active
              </div>
            </OwcTypography>
          )}
        </div>
      </FirstHalfCell>
      <ClusterGridTableAddFlow
        equipmentDetail={equipmentDetail}
        data={thirdStepTableData}
        meta={EquipmentMetaDisplay}
        selected={selected}
      />
    </>
  );
};

export default ActionDataInputsBoxClusterImpact;
