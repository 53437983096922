import { OwcCard, OwcTooltip, OwcTypography } from "@one/react";
import { getFile } from "./log-sheet/log-sheet-form/downloadFile";
import { generateID } from "@digitallab/grid-common-components";
import {
  LinkContainerDivInnerLeft,
  LinkContainerDivInnerRight,
  LinkContainerDivView,
  LinkContainerStyled
} from "./log-book-item-form-dialog/FormsStyled";
import FileIcon from "../../icons/FileIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { LOGBOOK_ATTACHMENTS } from "../../constants";

const ActionLogFileList = ({ attachments, user, client }) =>
  attachments?.length > 0 ? (
    <div style={{ margin: "10px 0" }}>
      <OwcCard
        flat
        style={{
          padding: "0px",
          width: "625px",
          border: "1px solid var(--one-color-interaction-default-neutral-2)"
        }}
      >
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-3px",
            padding: "10px"
          }}
        >
          <OwcTypography
            variant="button"
            id={generateID.UUID("AddLinkHeadingBanner", `addLinkHeadingBanner`, "type_title")}
          >
            <strong>File list</strong>
          </OwcTypography>
        </div>
        <LinkContainerStyled
          data-testid="link-block"
          id={generateID.UUID("LinkBlock", `link_block`, "div")}
          style={{ maxHeight: "150px", height: "auto" }}
        >
          {attachments
            ?.filter((i) => i.status === LOGBOOK_ATTACHMENTS.STATUS.ACTIVE)
            .map((item, index) => {
              return (
                <LinkContainerDivView key={`file${index}`}>
                  <LinkContainerDivInnerLeft>
                    <FileIcon style={{ marginTop: "14px" }} />
                    <div>
                      <OwcTypography
                        variant="subtitle2"
                        style={{
                          marginLeft: "5px",
                          color: "var(--one-color-interaction-default-brand-1)",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          width: "415px"
                        }}
                      >
                        {item.fileDescription}
                      </OwcTypography>
                      <OwcTypography
                        variant="subtitle2"
                        style={{
                          marginLeft: "5px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          width: "415px"
                        }}
                      >
                        {item.fileName.split("--")[1]}
                      </OwcTypography>
                    </div>
                  </LinkContainerDivInnerLeft>
                  <LinkContainerDivInnerRight>
                    <div style={{ cursor: "pointer", marginRight: "5px" }}>
                      <span
                        onClick={() => getFile(item)}
                        style={{
                          color: "var(--one-color-interaction-default-brand-1)"
                        }}
                      >
                        <DownloadIcon />
                      </span>
                      <OwcTooltip anchor={generateID.UUID("LinkBlockEdit", `link_block`, `icon-${index}`)}>
                        {"Link"}
                      </OwcTooltip>
                    </div>
                  </LinkContainerDivInnerRight>
                </LinkContainerDivView>
              );
            })}
        </LinkContainerStyled>
      </OwcCard>
    </div>
  ) : (
    <></>
  );

const mapStateToProps = (store) => ({
  user: store.user
});

export default compose(connect(mapStateToProps, {}), withApollo)(ActionLogFileList);
