import React, { useContext, useEffect, useState } from "react";
import { CLUSTER_COVER_SHEET_LIST } from "../../../constants";
import { replaceEmptyWithHyphen, validateSelection } from "../../../utils/helpers/text";
import { CoverSheetNoDataConatiner } from "../CoverSheetMainPageStyle";
import { generateID } from "@digitallab/grid-common-components";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { Module } from "../../../constants";
import ClusterGridTable from "../../../components/shared/ClusterAgGridTable";
import { entryType } from "../../../constants";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import ListAction from "./ListAction.jsx";
import SnapshotAction from "../SnapshotAction";
import { FullScreenCentered } from "../../log-book/log-book-item-form-dialog/LoadItemForm";
import moment from "moment";
import { OwcTab, OwcTabs, OwcTypography } from "@one/react";
import { CircularProgress } from "@mui/material";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { getEquipDetailInfo } from "../../log-book/LoadLogBooksInfo";
import { CoverSheetMainPageContext } from "../cover-sheet-main-page-context/context";
import { DigitalLabLogbookInstrumentModelType } from "../../../models/DigitalLabLogbookInstrumentModelType";
import { sortBy } from "lodash";
import { IDigitalLabLogbookInstrumentSnapshotWithInventoryIdModelType } from "../../../models/DigitalLabLogbookInstrumentSnapshotModelType";
import { DigitalLabInstrumentRepositoryEntryShortModelType } from "../../../models/DigitalLabInstrumentRepositoryEntryModelType";
import { appSyncClient } from "../../../appSyncClient";

import { CoverSheetData } from "../CoverSheetData";
import { ClusterActions } from "../../../components/shared/ClusterActions";

const sortSubEquipmentListByOrderInCluster = (
  sorted: DigitalLabInstrumentRepositoryEntryShortModelType[],
  unSorted: DigitalLabLogbookInstrumentModelType[]
): DigitalLabLogbookInstrumentModelType[] => {
  const subEquipmentList = unSorted.sort((a, b) => {
    const indexA = sorted.findIndex((type) => a.inventoryId === type.id);
    const indexB = sorted.findIndex((type) => b.inventoryId === type.id);
    return indexA - indexB;
  });
  return subEquipmentList;
};

export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
`;

const ClusterDescriptionDetailView = ({
  inventoryId,
  equipmentDetail,
  getSubEquipmentsDetailInfo,
  getRepoClusterDetailsById,
  showSnapShot,
  clearSnapShot,
  exportSnapShotPDF,
  loading,
  snapShotView,
  snapShotDate,
  setSnapShotDate,
  snapShotTime,
  setSnapShotTime,
  showSnapShotClk,
  setShowSnapShotClk,
  getSnapShotDetails,
  client
}: {
  inventoryId: string;
  equipmentDetail: DigitalLabLogbookInstrumentModelType;
  getSubEquipmentsDetailInfo: (id: string) => Promise<DigitalLabLogbookInstrumentModelType[]>;
  getRepoClusterDetailsById: (id: string) => Promise<DigitalLabInstrumentRepositoryEntryShortModelType[]>;
  showSnapShot: boolean;
  clearSnapShot: boolean;
  exportSnapShotPDF: boolean;
  loading: boolean;
  snapShotView: boolean;
  snapShotDate: string;
  setSnapShotDate: React.Dispatch<React.SetStateAction<string>>;
  snapShotTime: string;
  setSnapShotTime: React.Dispatch<React.SetStateAction<string>>;
  showSnapShotClk: string;
  setShowSnapShotClk: React.Dispatch<React.SetStateAction<string>>;
  getSnapShotDetails: ({
    snapShotDate,
    snapShotTime,
    id
  }: {
    snapShotDate: string;
    snapShotTime: string;
    id: string;
  }) => IDigitalLabLogbookInstrumentSnapshotWithInventoryIdModelType | null;
  client: typeof appSyncClient;
}) => {
  const [value, setValue] = useState("attribute");
  const [newEquipmentDetails, setNewEquipmentDetails] = useState<DigitalLabLogbookInstrumentModelType[] | null>(null);
  const { loadAllLogHierarchyData } = useContext(CoverSheetMainPageContext) as {
    loadAllLogHierarchyData: (input: DigitalLabLogbookInstrumentModelType[]) => {};
  };
  //@ts-ignore
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);

  useEffect(() => {
    const getClusterEquipment = async () => {
      let clusterEquipmentDetail = (await getEquipDetailInfo(
        equipmentDetail?.entryType === entryType?.clusterSubequipment
          ? equipmentDetail?.clusterId
          : equipmentDetail?.inventoryId,
        client
      )) as DigitalLabLogbookInstrumentModelType;

      if (clusterEquipmentDetail?.clusterId) {
        clusterEquipmentDetail = (await getEquipDetailInfo(
          clusterEquipmentDetail?.clusterId,
          client
        )) as DigitalLabLogbookInstrumentModelType;
      }

      const clusterSubEquipmentDetail: DigitalLabLogbookInstrumentModelType[] = await getSubEquipmentsDetailInfo(
        clusterEquipmentDetail?.inventoryId
      );
      let subEquipmentMapping = await getRepoClusterDetailsById(clusterEquipmentDetail?.inventoryId);
      subEquipmentMapping = sortBy(subEquipmentMapping, ["positionInCluster"]);

      const instrumentsListSortedByPositionInCluster = sortSubEquipmentListByOrderInCluster(
        subEquipmentMapping,
        clusterSubEquipmentDetail
      ) as DigitalLabLogbookInstrumentModelType[];
      const subClusterDetails = await subClusterMapping(instrumentsListSortedByPositionInCluster);
      const obj = {
        ...clusterEquipmentDetail,
        subEquipment: subClusterDetails
      };

      setNewEquipmentDetails([obj]);
      loadAllLogHierarchyData([obj]);
    };

    const subClusterMapping = async (instrumentsList: DigitalLabLogbookInstrumentModelType[]) => {
      const subClusters: DigitalLabLogbookInstrumentModelType[] = [];
      for (const details of instrumentsList) {
        if (details?.entryType === entryType?.cluster) {
          const clusterEquipmentDetail = await getEquipDetailInfo(
            details?.entryType === entryType?.clusterSubequipment ? details?.clusterId : details?.inventoryId,
            client
          );
          const clusterSubEquipmentDetail = await getSubEquipmentsDetailInfo(clusterEquipmentDetail?.inventoryId);
          let subEqpmtMapping = await getRepoClusterDetailsById(clusterEquipmentDetail?.inventoryId);
          subEqpmtMapping = sortBy(subEqpmtMapping, ["positionInCluster"]);
          const subEquipmentSortedList = sortSubEquipmentListByOrderInCluster(
            subEqpmtMapping,
            clusterSubEquipmentDetail
          );
          const obj = {
            ...clusterEquipmentDetail,
            subEquipment: subEquipmentSortedList
          };
          subClusters.push(obj);
        } else {
          subClusters.push(details);
        }
      }
      return subClusters;
    };

    const getSnapShotClusterEquipment = async () => {
      const invntryId =
        equipmentDetail?.entryType === entryType?.clusterSubequipment
          ? equipmentDetail?.clusterId || ""
          : equipmentDetail?.inventoryId;
      let clusterSnapShotEquipmentDetail = await getSnapShotDetails({
        snapShotDate,
        snapShotTime,
        id: invntryId
      });
      if (clusterSnapShotEquipmentDetail?.clusterId) {
        clusterSnapShotEquipmentDetail = await getSnapShotDetails({
          snapShotDate,
          snapShotTime,
          id: clusterSnapShotEquipmentDetail?.clusterId
        });
      }
      if (clusterSnapShotEquipmentDetail?.subEquipment) {
        clusterSnapShotEquipmentDetail.subEquipment = sortBy(clusterSnapShotEquipmentDetail.subEquipment, [
          "positionInCluster"
        ]);
      }

      const clusterSnapShotSubEquipmentDetail = await getSubEquipmentsDetailInfo(
        clusterSnapShotEquipmentDetail?.inventoryId!
      );

      const subEquipmentSortedList = sortSubEquipmentListByOrderInCluster(
        clusterSnapShotEquipmentDetail?.subEquipment!,
        clusterSnapShotSubEquipmentDetail
      );
      const subClusterDetails = await subClusterMapping(subEquipmentSortedList);
      const clusterSnapShotAllEquipmentDetail = {
        ...clusterSnapShotEquipmentDetail,
        subEquipment: subClusterDetails
      };
      setNewEquipmentDetails([clusterSnapShotAllEquipmentDetail as DigitalLabLogbookInstrumentModelType]);
    };

    if (snapShotDate && snapShotTime) {
      getSnapShotClusterEquipment();
    } else {
      getClusterEquipment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDetail]);

  const EquipmentMetaDisplay = {
    fields: {
      clusterIdentifier: {
        headProps: { style: { width: 100 } },
        cellProps: { style: { width: 100 } },
        text: "",
        sortable: false,
        component: ({
          item,
          triggerClusterSetCollapse
        }: {
          item: DigitalLabLogbookInstrumentModelType;
          triggerClusterSetCollapse: (value: boolean) => void;
        }) => {
          switch (item.entryType) {
            case entryType?.cluster:
              return (
                <ClusterActions item={item} triggerClusterSetCollapse={triggerClusterSetCollapse} viewExpander={true} />
              );
            default:
              return "";
          }
        }
      },
      equipmentModel: {
        text: DATA_MODEL_TABLE.equipmentModel.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        }
      },
      belongingToGroup: {
        text: DATA_MODEL_TABLE.belongingToGroup.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      manufacturer: {
        text: DATA_MODEL_TABLE.manufacturer.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      serialNumber: {
        text: DATA_MODEL_TABLE.serialNumber.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentId: {
        text: DATA_MODEL_TABLE.equipmentId.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      softwareVersion: {
        text: DATA_MODEL_TABLE.softwareVersion.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      currentGxPStatusFromLogs: {
        text: DATA_MODEL_TABLE.currentGxPStatusFromLogs.value,
        component: ({ item }: { item: DigitalLabLogbookInstrumentModelType }) => (
          <div>
            {replaceEmptyWithHyphen(
              validateSelection(gxpReadys, {
                key: item?.qualificationStatus
              })
            )}
          </div>
        ),
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      actions: {
        text: "Actions",
        HeadCellComponent: ActionsCell,
        component: ({
          item,
          triggerSetCollapse
        }: {
          item: DigitalLabLogbookInstrumentModelType;
          triggerSetCollapse: boolean;
        }) => <ListAction item={item} triggerSetCollapse={triggerSetCollapse} />
      }
    },
    rowKeyId: "id"
  };

  const handleChange = (event: { detail: React.SetStateAction<string> }) => {
    setValue(event.detail);
  };

  if (!equipmentDetail) {
    return (
      <CoverSheetNoDataConatiner data-testid="cover-sheet-no-data">
        <OwcTypography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} variant="body1">
          No data
        </OwcTypography>
      </CoverSheetNoDataConatiner>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          borderBottom: "1px solid rgb(211, 211, 211)",
          background: snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)"
        }}
      >
        <OwcTabs
          data-testid="page-tabs"
          value={value}
          onTabChange={handleChange}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <OwcTab
            data-testid="page-tab-run-logs"
            value="attribute"
            id={generateID.UUID(Module.CLUSTER_TAB.attribute, 1, "tab")}
            placeholder=""
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          >
            {LOGBOOK_LABEL.CLUSTER_TABS.attribute}
          </OwcTab>

          <OwcTab
            data-testid="page-tab-log-sheet"
            value="hierarchy"
            id={generateID.UUID(Module.CLUSTER_TAB.hierarchy, 1, "tab")}
            placeholder=""
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          >
            {LOGBOOK_LABEL.CLUSTER_TABS.hierarchy}
          </OwcTab>
        </OwcTabs>
        <SnapshotAction
          inventoryId={inventoryId}
          showSnapShot={showSnapShot}
          clearSnapShot={clearSnapShot}
          exportSnapShotPDF={exportSnapShotPDF}
          bgColor={snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)"}
          borderBottom={true}
          snapShotDate={snapShotDate}
          setSnapShotDate={setSnapShotDate}
          snapShotTime={snapShotTime}
          setSnapShotTime={setSnapShotTime}
          showSnapShotClk={showSnapShotClk}
          setShowSnapShotClk={setShowSnapShotClk}
          equipmentDetail={equipmentDetail}
        />
      </div>
      <div
        style={{
          background: snapShotView ? "var(--one-color-background-neutral-2)" : "var(--one-color-foreground-neutral-2)",
          padding: "0px 10px 10px 30px"
        }}
      >
        {loading && (
          <FullScreenCentered data-testid="loader">
            <CircularProgress size={40} />
          </FullScreenCentered>
        )}
        {!loading && value === "attribute" && (
          <>
            {snapShotDate && showSnapShotClk && (
              <OwcTypography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                <b>Snapshot ({moment(snapShotDate).format("DD-MMM-yyyy")})</b>
              </OwcTypography>
            )}
            <CoverSheetData
              equipmentDetail={equipmentDetail}
              snapShotView={snapShotView}
              coverSheetFields={CLUSTER_COVER_SHEET_LIST}
            />
          </>
        )}
      </div>
      {!loading && value === "hierarchy" && (
        <>
          {snapShotDate && showSnapShotClk && (
            <OwcTypography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
              <b
                style={{
                  padding: "28px"
                }}
              >
                Snapshot ({moment(snapShotDate).format("DD-MMM-yyyy")})
              </b>
            </OwcTypography>
          )}
          <ClusterGridTable
            data={newEquipmentDetails!}
            meta={EquipmentMetaDisplay}
            snapShotDate={snapShotDate}
            snapShotTime={snapShotTime}
            gxpReadys={gxpReadys}
          />
        </>
      )}
    </>
  );
};

export default compose(connect(null, {}), withApollo)(ClusterDescriptionDetailView);
