import React, { useContext, useEffect } from "react";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useParams } from "react-router-dom";
import { generateID } from "@digitallab/grid-common-components";
import { LOG_TYPES, Module, formTypes } from "../../../constants";
import { OwcTab, OwcTabs } from "@one/react";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";

const PageTabs = ({ value }) => {
  // Track page view
  const { trackPageView } = useMatomo();
  const { inventoryId } = useParams();
  const { dispatchAction, fetching } = useContext(PaginationContext);
  const { setTabValue, loadFormType } = useContext(CoverSheetMainPageContext);
  useEffect(() => {
    const pageName =
      value === formTypes?.ACTION_LOG
        ? "Action Logs"
        : value === formTypes?.RUN_LOG
        ? "Run Logs"
        : value === formTypes?.CHANGE_LOG
        ? "Change Logs"
        : "All Logs";
    trackPageView({
      documentTitle: pageName,
      href: `${window.location?.origin}/` + pageName
    });
    // eslint-disable-next-line
  }, [value]);

  /**
   * function that changes type of logs displayed in the table
   * @param {*} e -e.detail string: runLog, actionLog, changeLog, allLog
   */
  const handleChange = (e) => {
    dispatchAction({ type: "clearAll" });
    loadFormType(e.detail);
  };

  useEffect(() => {
    if (value === formTypes?.RUN_LOG) {
      setTabValue(LOG_TYPES.ADD_RUN_LOG);
    } else if (value === formTypes?.ACTION_LOG) {
      setTabValue(LOG_TYPES.ADD_ACTION_LOG);
    } else if (value === formTypes?.CHANGE_LOG) {
      setTabValue(LOG_TYPES.CHANGE);
    } else if (value === formTypes?.ALL_LOG) {
      setTabValue(LOG_TYPES.ALL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <OwcTabs
        data-testid="page-tabs"
        value={value}
        onTabChange={handleChange}
        inventoryId={inventoryId}
        style={{
          width: "100%",
          marginBottom: 10,
          marginTop: 2,
          background: "var(--one-color-cobas-white)",
          borderBottom: "1px solid rgb(211, 211, 211)"
        }}
        disabled={fetching}
      >
        <OwcTab
          data-testid="page-tab-run-logs"
          label={LOGBOOK_LABEL.TAB.run_logs}
          value={formTypes?.RUN_LOG}
          id={generateID.UUID(Module.TAB.run_log, 1, "tab")}
        >
          {LOGBOOK_LABEL.TAB.run_logs}
        </OwcTab>
        <OwcTab
          data-testid="page-tab-log-sheet"
          label={LOGBOOK_LABEL.TAB.action_logs}
          value={formTypes?.ACTION_LOG}
          id={generateID.UUID(Module.TAB.action_log, 1, "tab")}
        >
          {LOGBOOK_LABEL.TAB.action_logs}
        </OwcTab>
        {inventoryId && (
          <>
            <OwcTab
              data-testid="page-tab-change-logs"
              label={LOGBOOK_LABEL.TAB.change_logs}
              value={formTypes?.CHANGE_LOG}
              id={generateID.UUID(Module.TAB.change_log, 1, "tab")}
            >
              {LOGBOOK_LABEL.TAB.change_logs}
            </OwcTab>
            <OwcTab
              data-testid="page-tab-all-logs"
              label={LOGBOOK_LABEL.TAB.all_logs}
              value={formTypes?.ALL_LOG}
              id={generateID.UUID(Module.TAB.all_log, 1, "tab")}
            >
              {LOGBOOK_LABEL.TAB.all_logs}
            </OwcTab>
          </>
        )}
      </OwcTabs>
    </>
  );
};

export default PageTabs;
