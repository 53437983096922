import React from "react";
import ChangeLogTable from "./ChangeLogTable";
import ChangeLogUpdateData from "./ChangeLogUpdateData";

const ChangeLogList = ({ dataTestId, fetchingFn }) => {
  return (
    <>
      <ChangeLogTable dataTestId={dataTestId} />
      <ChangeLogUpdateData fetchingFn={fetchingFn} />
    </>
  );
};

export default ChangeLogList;
