import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { OwcListItem } from "@one/react";

export const CustomSelect = ({
  name,
  options,
  value,
  propLabel,
  propValue,
  onChange,
  testid,
  selectLabel,
  emptyOption = false,
  displayEmpty = false,
  emptyOptionText = "Select all",
  SelectProps,
  InputLabelProps,
  multiple,
  renderValue,
  onBlur,
  ...rest
}) => {
  const prop = { ...rest };
  return (
    <FormControl {...rest}>
      <InputLabel shrink={displayEmpty ? true : undefined} id={testid} {...InputLabelProps}>
        {selectLabel}
      </InputLabel>
      <Select
        size="small"
        onBlur={onBlur}
        multiple={multiple}
        renderValue={renderValue}
        labelId={testid}
        data-testid={testid}
        id={testid}
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty={displayEmpty}
        {...SelectProps}
      >
        {emptyOption && <OwcListItem value="">{emptyOptionText}</OwcListItem>}
        {options?.map((item) => {
          const option = propValue ? item?.[propValue] : item;
          return (
            <MenuItem value={option} key={option} data-testid={`${testid}-${option}`}>
              {propLabel ? item?.[propLabel] : item}
            </MenuItem>
          );
        })}
      </Select>
      {prop.error && <FormHelperText>{prop.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
