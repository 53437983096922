import styled from "styled-components";
import InstrumentIdentyficationBox from "./InstrumentIdentyficationBox";

const FormStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemForm = ({
  children,
  dataTestId,
  selectSuggestionFn,
  clearSuggestionFn,
  showChipBox,
  equipmentDetail,
  type
}) => {
  return (
    <FormStyles data-testid={`${dataTestId}-form`}>
      <InstrumentIdentyficationBox
        showChipBox={showChipBox}
        selectSuggestionFn={selectSuggestionFn}
        clearSuggestionFn={clearSuggestionFn}
        equipmentDetail={equipmentDetail}
        type={type}
      />
      {children}
    </FormStyles>
  );
};

export default ItemForm;
