import { useFormikContext } from "formik";
import { changeDateFormat, commonPropsForInputsWithoutValue } from "../../helpers";
import { SOFTWARE_CHANGE, equipmentStatus, formStates, formTypes } from "../../../../constants";
import { Mainbox, Title } from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import RunSelectInput from "../../run-logs/run-logs-form/run-data-inputs/RunSelectInput";
import { useContext, useEffect, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { ActionTypeSelect, ConfigurationDetails, generateID, THEME } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";
import { OwcDatepicker, OwcInput, OwcSlideToggle, OwcTextarea, OwcTypography } from "@one/react";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import { find } from "lodash";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import FileList from "./FileList";
import { LinksList } from "./LinksList";
import { KEEP_STATUS_VALUE } from "../../../../logs/StepperContainer";
const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const ActionDataInputsBox = ({ isPropagationDisabled, numberOfSelectedEquipment }) => {
  const formik = useFormikContext();
  const classes = {};
  const [actionTime, setActionTime] = useState(null);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const actionLog = useSelector((store) => store.runLogsForm.actionLog);
  const systemStatuss = useSelector((store) => store.runLogsForm.systemStatuss);
  const { formState } = useContext(CoverSheetMainPageContext);
  const editMode = formState === formStates?.EDITABLE_FORM;
  const storeUserSite = useSelector((store) => store.user?.site);
  const { equipmentActionObject } = useContext(ItemDialogFormContext);

  // eslint-disable-next-line no-unused-vars
  const [selectedActionType, setSelectedActionType] = useState(null);
  const dateValue = (value) => {
    if ((value.detail instanceof Date && !isNaN(value.detail)) || value.detail === null) {
      formik.setFieldValue("actionDate", value.detail, true);
    }
  };

  useEffect(() => {
    if (formik?.values?.action) {
      setSelectedActionType(() => {
        if (formik?.values?.action?.key) {
          const obj = find(actionLog, { value: formik?.values?.action?.value });
          if (!obj) {
            formik.setFieldError("action", "Enter action type");
          }
          if (
            obj &&
            obj?.value === SOFTWARE_CHANGE &&
            !editMode &&
            equipmentActionObject?.status === equipmentStatus?.pending?.key
          ) {
            formik.setFieldValue(
              "updatedSoftwareVersion.newValue",
              equipmentActionObject?.softwareVersion !== "null" ? equipmentActionObject?.softwareVersion : "",
              true
            );
            formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", false, true);
          }

          return obj ? obj.value : null;
        }

        return null;
      });
    } else {
      setSelectedActionType(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.action]);

  useEffect(() => {
    if (!editMode && equipmentActionObject) {
      formik.setFieldValue(
        "gxpReady",
        find(gxpReadys, {
          key: equipmentActionObject?.qualificationStatus
        }) || {
          key: "",
          value: ""
        }
      );

      formik.setFieldValue(
        "systemStatus",
        find(systemStatuss, {
          value: equipmentActionObject?.equipSystemStatus
        }) || null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentActionObject]);
  useEffect(() => {
    if (!editMode) {
      let time = formik?.values?.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik?.values?.actionTime)
        : null;

      setActionTime(time);
    } else {
      const tempActionTime = formik?.values?.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik?.values?.actionTime)
        : null;
      setActionTime(tempActionTime);
      formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", false, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.actionTime]);

  return (
    <>
      <Mainbox>
        <Title data-testid="action-data-title">{LOGBOOK_LABEL.action_data}</Title>
        <OwcDatepicker
          style={{ width: "100%" }}
          autoClose
          inputVariant="filled"
          onBlur={formik.handleBlur}
          format="dd-MMM-yyyy"
          data-testid="action-data-inputs-action-date-input"
          id={generateID.UUID(Module.POPUP_FIELDS.actionDate, 1, "datePicker")}
          maxDate={new Date().getTime()}
          name="actionDate"
          variant="filled"
          label={`${ACTION_LOGS_DATA_MODEL.actionDate.value} *`}
          value={
            typeof formik.values.actionDate === "string" ? new Date(formik.values.actionDate) : formik.values.actionDate
          }
          onValueChange={(e) => dateValue(e)}
          invalidDateMessage={"Invalid date format"}
          required={true}
          requiredDateMessage={formik.errors.actionDate}
        >
          {formik.errors.actionDate && <span slot="error-text">{formik.errors.actionDate}</span>}
        </OwcDatepicker>

        <RunTimeInput
          property={ACTION_LOGS_DATA_MODEL.actionTime.key}
          label={ACTION_LOGS_DATA_MODEL.actionTime.value}
          testid="action-start-time"
          timeObj={actionTime}
          setTimeObj={setActionTime}
          required={true}
          maxTime={new Date().getTime()}
          logType={formTypes?.ACTION_LOG}
        />
        <ActionTypeSelect
          formik={formik}
          name="action"
          actionTypes={["MAINTENANCE_USER_MAINTENANCE", "INSTALL_INSTALLATION"]}
        />
        <OwcInput
          style={{ width: "100%" }}
          onBlur={formik.handleBlur}
          data-testid="action-data-inputs-operator-user-name-input"
          color="primary"
          variant="filled"
          id={generateID.textBoxID(Module.POPUP_FIELDS.actionOperatorUserName, "textbox")}
          name="operatorUserName"
          label={COMMON_LOGS_DATA_MODEL.operatorUserName.value}
          value={formik.values.operatorUserName}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "operatorUserName",
            "operator-user-name",
            "action-data-inputs"
          )}
        />
        <OwcInput
          onBlur={formik.handleBlur}
          style={{ width: "100%" }}
          data-testid="action-data-inputs-ecrNumber-input"
          variant="filled"
          fullWidth
          id={generateID.textBoxID(Module.POPUP_FIELDS.ecrNumber, "textbox")}
          name="ecrNumber"
          label={ACTION_LOGS_DATA_MODEL.ecrNumber.value}
          value={formik.values.ecrNumber}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(classes, formik, "ecrNumber", "ecrNumber", "action-data-inputs")}
        />
        <div>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={
              numberOfSelectedEquipment > 1
                ? [KEEP_STATUS_VALUE].concat(
                    gxpReadys.map(({ key, value }) => ({
                      key,
                      value: `Set all to "${value}"`
                    }))
                  )
                : gxpReadys
            }
            property="gxpReady"
            Selectlabel={ACTION_LOGS_DATA_MODEL.gxpReady.value}
            testid="gxpReady"
            propValue="key"
            propLabel="value"
            required
            fullWidth
            disabled={isPropagationDisabled}
          />
          {!editMode &&
            !isPropagationDisabled &&
            formik?.values?.gxpReady?.key &&
            formik?.values?.gxpReady?.key !== KEEP_STATUS_VALUE.key && (
              <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
                Will be propagated to repository
              </OwcTypography>
            )}
        </div>
        <div>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={
              numberOfSelectedEquipment > 1
                ? [KEEP_STATUS_VALUE].concat(
                    systemStatuss.map(({ key, value }) => ({
                      key,
                      value: `Set all to "${value}"`
                    }))
                  )
                : systemStatuss
            }
            property="systemStatus"
            Selectlabel={ACTION_LOGS_DATA_MODEL.systemStatus.value}
            testid="systemStatus"
            propValue="key"
            propLabel="value"
            required
            fullWidth
            disabled={isPropagationDisabled}
          />
          {!editMode &&
            !isPropagationDisabled &&
            formik?.values?.systemStatus?.key &&
            formik?.values?.systemStatus?.key !== KEEP_STATUS_VALUE.key && (
              <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
                Will be propagated to repository
              </OwcTypography>
            )}
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <OwcTextarea
            cols={20}
            data-testid="action-data-inputs-description-input"
            id={generateID.textBoxID(Module.POPUP_FIELDS.actionDescription, "textbox")}
            label={COMMON_LOGS_DATA_MODEL.description.value + ` *`}
            onInputChange={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            onInputKeyDown={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            onInputReset={() => formik.setFieldValue("description", "", true)}
            value={formik.values.description}
            resizable
            reset
            rows={4}
            maxLength={200}
            minLength={0}
          />
          {formik.values.action?.value?.toLowerCase() === SOFTWARE_CHANGE.toLowerCase() && (
            <>
              <OwcInput
                onBlur={formik.handleBlur}
                data-testid="action-data-inputs-new-software-version-input"
                variant="filled"
                id="updatedSoftwareVersion.newValue"
                name="updatedSoftwareVersion.newValue"
                label="New software version"
                value={formik.values.updatedSoftwareVersion.newValue}
                onInputChange={formik.handleChange}
                onInputKeyDown={formik.handleChange}
                style={{ width: "100%" }}
              />
              {!isPropagationDisabled && (
                <OwcSlideToggle
                  style={{ marginTop: THEME["one-spacer-24"] }}
                  checked={formik.values.updatedSoftwareVersion.shouldBePublished}
                  onInputChange={(e) => formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", e.detail)}
                  id="updatedSoftwareVersion.shouldBePublished"
                  disabled={editMode}
                >
                  Publish “New software version” to Equipment Repository
                </OwcSlideToggle>
              )}
            </>
          )}
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <ConfigurationDetails
            formik={formik}
            assistiveText={isPropagationDisabled ? "keep current status" : ""}
            textAreaStyle={{ minHeight: 136 }}
            value={formik.values.logbookConfigurationDetails?.newValue}
            id="logbookConfigurationDetails.newValue"
          />
          {!isPropagationDisabled && (
            <OwcSlideToggle
              checked={formik.values.logbookConfigurationDetails?.shouldBePublished}
              onInputChange={(e) => formik.setFieldValue("logbookConfigurationDetails.shouldBePublished", e.detail)}
              id="logbookConfigurationDetails.shouldBePublished"
              disabled={editMode}
            >
              Publish “Configuration details” to Equipment Repository
            </OwcSlideToggle>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "1/5"
          }}
        >
          <OwcTypography
            variant="subtitle1"
            id={generateID.UUID("AddLinkHeading", `addLinkHeading`, "type_title")}
            style={{ padding: "10px 0" }}
          >
            <strong>External document and file links</strong>
          </OwcTypography>

          <div style={{ display: "flex", gap: "8px" }}>
            <LinksList />
            {attachmentSites.includes(storeUserSite) ? <FileList /> : null}
          </div>
        </div>
      </Mainbox>
    </>
  );
};

export default ActionDataInputsBox;
