import React, { useReducer } from "react";
import { PaginationContext } from "./PaginationContext";
import { sortLogs } from "../../../utils/helpers/text";

const handleSetLimit = (action) => {
  return {
    ...initialState,
    limit: action.payload
  };
};

const handleClearAll = (state) => {
  return {
    ...{
      ...initialState,
      limit: state.limit,
      sortDirection: state.sortDirection
    },
    nextData: state.nextData + 1
  };
};

const handleRefresh = (state, action) => {
  return {
    ...state,
    prevTokens: action.payload.samePage ? state.prevTokens : [],
    nextToken: action.payload.samePage ? state.prevTokens.slice(-1)[0] ?? null : null,
    nextData: state.nextData + 1
  };
};

const handleSortDirection = (state, action) => {
  return {
    ...{ ...initialState, limit: state.limit },
    sortDirection: action.payload ? "ASC" : "DESC",
    nextData: state.nextData + 1
  };
};

const handleNextToken = (state, action) => {
  return {
    ...state,
    fetching: false,
    data: sortLogs(action.payload?.data) ?? [],
    nextToken: action.payload.token ?? null
  };
};

const handleNextRunToken = (state, action) => {
  return {
    ...state,
    runData: sortLogs(action.payload?.runData) ?? [],
    nextRunToken: action.payload.token ?? null
  };
};

const handleNextActionToken = (state, action) => {
  return {
    ...state,
    actionData: sortLogs(action.payload?.actionData) ?? [],
    nextActionToken: action.payload.token ?? null
  };
};

const handlePrevData = (state) => {
  return {
    ...state,
    prevTokens: state.prevTokens.slice(0, -1),
    nextToken: state.prevTokens.slice(0, -1).slice(-1)[0] ?? null,
    nextData: state.nextData - 1
  };
};

const handleFetching = (state, action) => {
  return {
    ...state,
    fetching: action.payload
  };
};

const handleNextData = (state, action) => {
  return {
    ...state,
    prevTokens: state.nextToken ? [...state.prevTokens, state.nextToken] : state.prevTokens,
    nextToken: action?.payload ? null : state.nextToken,
    ...(action?.payload
      ? {
          ...initialState,
          limit: state.limit,
          sortDirection: state.sortDirection
        }
      : {}),
    nextData: state.nextData + 1
  };
};

export const initialState = {
  fetching: false,
  prevTokens: [],
  nextToken: null,
  nextRunToken: null,
  nextActionToken: null,
  nextData: 0,
  limit: 5000,
  data: [],
  runData: [],
  actionData: [],
  sortDirection: "DESC"
};

export function reducer(state = initialState, action = { type: "", payload: "" }) {
  switch (action.type) {
    case "fetching":
      return handleFetching(state, action);
    case "nextData":
      return handleNextData(state, action);
    case "prevData":
      return handlePrevData(state);
    case "nextToken":
      return handleNextToken(state, action);
    case "nextRunToken":
      return handleNextRunToken(state, action);
    case "nextActionToken":
      return handleNextActionToken(state, action);
    case "sortDirection":
      return handleSortDirection(state, action);
    case "refresh":
      return handleRefresh(state, action);
    case "clearAll":
      return handleClearAll(state);
    case "clearAllButDates":
      return handleClearAll(state);
    case "setLimit":
      return handleSetLimit(action);
    default:
      return state;
  }
}

const PaginationWrapper = ({ children, initialValues = {} }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues
  });
  return (
    <PaginationContext.Provider
      value={{
        ...state,
        dispatchAction
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationWrapper;
