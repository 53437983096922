import { useEffect, useState } from "react";
import { DocumentNode, GraphQLError } from "graphql";
import { configureAwsAppSync } from "../../utils/helpers/configureAwsAppSync";
import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";

type GenericData = {
  items: unknown[];
  nextToken: string;
  errors: GraphQLError[];
};

/** function to process single query   */

export function useQueryExt({
  query,
  endpoint,
  variables
}: {
  query: DocumentNode;
  endpoint: string;
  variables?: Record<string, unknown>;
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, unknown> | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const runQuery = async () => {
      try {
        if (!query) throw new Error("Not query provided");
        setLoading(true);
        const { limit = 1000 } = variables ?? { limit: 1000 };
        configureAwsAppSync({ aws_appsync_graphqlEndpoint: endpoint });
        await (
          API.graphql({
            query,
            variables: { ...variables, limit, nextToken: null }
          }) as Promise<GraphQLResult<unknown>>
        ).then((result) => {
          if (result.data) {
            setData(result.data as Record<string, unknown>);
            setError("");
          }
        });
      } catch (error) {
        if (Array.isArray(error)) setError(error.map((errorItem) => errorItem.message).join("\n"));
        else if (error instanceof Error && error.message) setError(error.message);
        else setError(JSON.stringify(error));
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, data, error };
}

/** function to get all data for query
 * it does not respect limit or nextToken variables
 * always gets all records in 1000 bunches
 */

export function useQueryAll({
  query,
  variables,
  endpoint
}: {
  query: DocumentNode;
  variables?: {
    [key: string]: unknown;
  };
  endpoint: string;
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, unknown> | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const runQuery = async () => {
      try {
        if (!query) throw new Error("Not query provided");
        setLoading(true);
        configureAwsAppSync({ aws_appsync_graphqlEndpoint: endpoint });
        let resultItems: unknown[] = [];
        let resultData = {};
        let nextToken = null;
        do {
          await (
            API.graphql({
              query,
              variables: { ...variables, nextToken, limit: 1000 }
            }) as Promise<GraphQLResult<unknown>>
          )
            // eslint-disable-next-line no-loop-func
            .then((result) => {
              if (result.data) {
                const key = Object.keys(result.data)[0];
                const values = Object.values(result.data)[0];
                resultItems = [...resultItems, ...values.items];
                nextToken = typeof values.nextToken === "string" ? values.nextToken : null;
                (result.data[key as keyof typeof result.data] as GenericData).items = resultItems;
                resultData = result.data;
              }
            });
        } while (nextToken !== null);
        setData(resultData);
        setError("");
      } catch (error) {
        if (Array.isArray(error)) setError(error.map((errorItem) => errorItem.message).join("\n"));
        else if (error instanceof Error && error.message) setError(error.message);
        else setError(JSON.stringify(error));
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, data, error };
}
