import { useEffect } from "react";
import LOGBOOK_LABEL from "../../utils/constants/logbookLabel";
import ItemFormDialog from "../log-book/log-book-item-form-dialog/ItemFormDialog";
import { CreateRunLogChange } from "../log-book/run-logs/run-logs-form/CreateRunLogChange";
import { emptyRunLog } from "../log-book/run-logs/run-logs-form/empty-run-log";
import { makeCleanRunLog } from "../log-book/run-logs/run-logs-form/make-clean-run-log";
import { runLogValidationSchemaFactory } from "../log-book/run-logs/run-logs-form/run-log-validation-schema";
import { runLogsClearSuggestion } from "../log-book/run-logs/run-logs-form/run-logs-clear-suggestion";
import { runLogsSelectSuggestion } from "../log-book/run-logs/run-logs-form/run-logs-select-suggestion";
import { useContext } from "react";
import { ItemDialogFormContext } from "../log-book/log-book-item-form-dialog/item-context/context";
import RunDataInputsBox from "../log-book/run-logs/run-logs-form/RunDataInputsBox";
import ActionDataInputsBox from "../log-book/log-sheet/log-sheet-form/ActionDataInputsBox";
import { emptyLogSheet } from "../log-book/log-sheet/log-sheet-form/empty-log-sheet";
import { makeCleanLogSheet } from "../log-book/log-sheet/log-sheet-form/make-clean-log-sheet";
import { CreateLogSheetChange } from "../log-book/log-sheet/log-sheet-form/CreateLogSheetChange";
import { logSheetValidationSchemaFactory } from "../log-book/log-sheet/log-sheet-form/log-sheet-validation-schema";
import { logSheetSelectSuggestion } from "../log-book/log-sheet/log-sheet-form/log-sheet-select-suggestion";
import { logSheetClearSuggestion } from "../log-book/log-sheet/log-sheet-form/log-sheet-clear-suggestion";
import { formTypes } from "../../constants";

const StandAlonActionDialog = ({ type, equipmentDetail }) => {
  const { handleOpen, setIsInventoryId } = useContext(ItemDialogFormContext);

  useEffect(() => {
    handleOpen(null);
    setIsInventoryId(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ItemFormDialog
      addNewDialogButtonLabel={
        type === formTypes?.RUN_LOG ? LOGBOOK_LABEL.BUTTON.add_new_run : LOGBOOK_LABEL.BUTTON.add_new_log
      }
      emptyItem={type === formTypes?.RUN_LOG ? emptyRunLog : emptyLogSheet}
      cleanItemFn={type === formTypes?.RUN_LOG ? makeCleanRunLog : makeCleanLogSheet}
      createItemChangeFn={type === formTypes?.RUN_LOG ? CreateRunLogChange : CreateLogSheetChange}
      validationSchemaFactory={
        type === formTypes?.RUN_LOG ? runLogValidationSchemaFactory : logSheetValidationSchemaFactory
      }
      selectSuggestionFn={type === formTypes?.RUN_LOG ? runLogsSelectSuggestion : logSheetSelectSuggestion}
      clearSuggestionFn={type === formTypes?.RUN_LOG ? runLogsClearSuggestion : logSheetClearSuggestion}
      dataTestId={type === formTypes?.RUN_LOG ? "run-log" : "action-log"}
      type={type}
      showChipBox={true}
      equipmentDetail={equipmentDetail}
    >
      {type === formTypes?.RUN_LOG && <RunDataInputsBox equipmentDetail={equipmentDetail} />}
      {type === formTypes?.ACTION_LOG && <ActionDataInputsBox />}
    </ItemFormDialog>
  );
};

export default StandAlonActionDialog;
