import {
  LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_ASC,
  LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_ASC,
  LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_DESC,
  LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_DESC
} from "../../../gql/logBooksapi/queries";
import { fetchCommon } from "../common-for-log-sheet-and-run-logs/fetch-common";
export const fetchLogSheets = fetchCommon({
  queryAsc: LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_ASC,
  queryDesc: LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_DESC,
  queryWithInstrumentAsc: LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_ASC,
  queryWithInstrumentDesc: LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_DESC,
  queryName: "logSheetBySiteAndActionDateTime"
});
