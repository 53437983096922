import { THEME } from "@digitallab/grid-common-components";
import { CircularProgress } from "@mui/material";
import { OwcHorizontalStepper, OwcHorizontalStep, OwcButton } from "@one/react";
import { Dispatch, SetStateAction } from "react";

export const Stepper = ({
  onCancel,
  presentStep,
  setPresentStep,
  steps,
  nextStepDisabled,
  finishText = "Save",
  finishDisabled,
  stepperStyle,
  handleFinish,
  isSubmitting
}: {
  onCancel: () => {};
  presentStep: number;
  setPresentStep: Dispatch<SetStateAction<number>>;
  steps: { mainText: string; optionalText: string }[];
  nextStepDisabled?: boolean;
  finishText: string;
  finishDisabled: boolean;
  stepperStyle?: React.CSSProperties;
  handleFinish: () => {};
  isSubmitting: boolean;
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: THEME["one-color-cobas-blue-50"],
          display: "flex",
          justifyContent: "center",
          marginBottom: THEME["one-spacer-16"]
        }}
      >
        <OwcHorizontalStepper activeStep={presentStep} style={stepperStyle} condensed linear>
          {steps.map(({ optionalText, mainText }, index) => (
            <OwcHorizontalStep key={index} number={index} completed={index < presentStep} optionalText={optionalText}>
              {mainText}
            </OwcHorizontalStep>
          ))}
        </OwcHorizontalStepper>
      </div>
      <div
        style={{
          width: "100%",
          zIndex: 3,
          left: 0,
          bottom: THEME["one-spacer-28"],
          position: "fixed",
          display: "flex",
          justifyContent: presentStep === 0 ? "flex-end" : "space-between",
          padding: THEME["one-spacer-16"],
          backgroundColor: THEME["one-color-cobas-accent-gray-100"]
        }}
      >
        <div>
          {presentStep !== 0 && (
            <OwcButton onClick={() => setPresentStep(presentStep - 1)} variant="secondary">
              Previous
            </OwcButton>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <OwcButton onClick={onCancel} variant="secondary" style={{ marginRight: THEME["one-spacer-32"] }}>
            Cancel
          </OwcButton>
          {presentStep < steps.length - 1 ? (
            <OwcButton disabled={nextStepDisabled} onClick={() => setPresentStep(presentStep + 1)}>
              Next
            </OwcButton>
          ) : (
            <OwcButton onClick={handleFinish} disabled={finishDisabled || isSubmitting}>
              <div style={{ display: "flex" }}>
                {isSubmitting && (
                  <>
                    <CircularProgress size={20} />
                    &nbsp;
                  </>
                )}
                {finishText}
              </div>
            </OwcButton>
          )}
        </div>
      </div>
    </>
  );
};
