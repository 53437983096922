import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withApollo } from "react-apollo";
import { Notify } from "@digitallab/grid-common-components";

import { CloudSearchConfig } from "../CloudSearchConfig";
import {
  agGridAutoGroupfieldName,
  allTableColumn,
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_PAGE_SIZE,
  entryType,
  listOfFieldAcceptOnlyInteger,
  listOfFieldsIgnoredForSearch,
  listOfFieldsSortable,
  listOfNumberFilter,
  literalFields
} from "../../../constants";
import { filter, find, sortBy, uniq } from "lodash";
import { convertStrToObjColumns, convertToCamelCase, convertToSnakeCase } from "../../../utils/helpers/text";
import {
  getColumnObj,
  onCreateSelectColumn,
  onCreatefilter,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize
} from "../../../features/equipment-list/EquipmentHelper";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { DLabEquipmentList as CommonAgGrid } from "@digitallab/grid-common-components";
import { OwcModalDialog, OwcButton, OwcTypography, OwcGrid, OwcProgressSpinner } from "@one/react";
import { OwcBadge } from "@one/react/Components/OwcBadge";
import { getAllMasterFilterData } from "../../../features/equipment-list/LoadEquipmentService";
import { useSelector } from "react-redux";
import {
  CREATE_USER_DISPLAY_COLUMN,
  CREATE_USER_FILTER,
  DELETE_USER_DISPLAY_COLUMN,
  DELETE_USER_FILTER,
  UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_USER_DISPLAY_COLUMN,
  UPDATE_USER_FILTER
} from "../../../gql/logBooksapi";
import { TOOL_PANELS, filterKeyMapping } from "../../DLabGrid/dLabGridConstant";
import { CircularProgress } from "@mui/material";

const MAX_SELECTED = 20;
const BADGE_TYPE = {
  ACTIVE: "active",
  WARNING: "warning"
};

const isEmpty = (value) =>
  value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0);

const compareEquipmentModels = (initialEquipment, cloudSearchEquipment) => {
  const compareByKey = (keyName) => {
    const initialValue = initialEquipment?.[keyName];
    const cloudSearchValue = cloudSearchEquipment?.[convertToSnakeCase(keyName)]?.[0];
    if (isEmpty(initialValue) && isEmpty(cloudSearchValue)) return true; //catching both empty
    if (isEmpty(initialValue) || isEmpty(cloudSearchValue)) return false; // this will catch when one is empty

    return initialValue === cloudSearchValue;
  };

  return compareByKey("equipmentModel") && compareByKey("serialNumber") && compareByKey("equipmentId");
};

export const SetEquipmentsForLogBookDialog = withApollo(
  ({
    open,
    close,
    title,
    onDialogApproveCallBack,
    initiallySelectedEquipments = [],
    cancelTitle = "Cancel",
    approveTitle = "Add",
    client,
    columnDefs = null
  }) => {
    const [lastPageSize, setLastPageSize] = useState(DEFAULT_PAGE_SIZE);

    const attributes = {
      hiddenByDefault: true,
      height: 500,
      rowModelType: "serverSide",
      rowQuickSearch: false,
      treeData: true,
      limit: 50,
      suppressPaginationPanel: false,
      disablePagination: true,
      pagination: true,
      animateRows: false,
      rowSelection: "multiple",
      rowMultiSelectWithClick: false,
      rowExport: false,
      masterDetail: true,
      isGroupOpenByDefault: false,
      defaultToolPanel: "filters",
      serverSideInfiniteScroll: false,
      serverSideSortOnServer: true,
      suppressRowClickSelection: true,
      suppressChangeDetection: true,
      maxBlocksInCache: 1
    };

    const requestServer = (cloudSearchConfigOption) => CloudSearchConfig(cloudSearchConfigOption);
    const columnDefsOrDefault = columnDefs.length > 0 ? columnDefs : DEFAULT_COLUMNS;
    const [dataModel, setDataModel] = useState({});
    const [masterDataFilters, setMasterDataFilters] = useState({});
    const [lastDisplayColumns, setLastDisplayColumns] = useState(columnDefsOrDefault);
    const [lastFavFilter, setLastFavFilter] = useState(DEFAULT_FILTER);
    const [userFilters, setUserFilters] = useState([]);
    const [myLabFilter, setMyLabFilter] = useState(null);
    const [userDisplayColumn, setUserDisplayColumn] = useState([]);
    const [badgeType, setBadgeType] = useState(BADGE_TYPE.ACTIVE);
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const autoGroupColField = "equipment_model";
    const [selectedEquipmentsId, setSelectedEquipmentsId] = useState([]);
    const [selectedEquipments, setSelectedEquipments] = useState([]);
    const [defaultShowColumns, setDefaultShowColumns] = useState(columnDefsOrDefault);
    const [additionalFields, setAdditionalFields] = useState([]);

    const handleCancel = () => {
      close(false);
    };
    const handleApprove = async () => {
      setLoading(true);
      await onDialogApproveCallBack(selectedEquipments);
      setLoading(false);
    };

    const storeUser = useSelector((store) => store.user);
    const loadFilterMasterData = useCallback(async () => {
      const {
        masterFiltersList = [],
        myLabFilterMaster,
        userDetail,
        userDisplayColumnMaster
      } = await getAllMasterFilterData({
        client: client,
        site: storeUser?.site,
        userID: storeUser?.id
      });
      setMyLabFilter(() => myLabFilterMaster);
      setMasterDataFilters(() => masterFiltersList);
      setUserFilters(() => userDetail?.userFilters);
      setLastFavFilter(() => userDetail?.lastFavFilter);
      setUserDisplayColumn(() => userDisplayColumnMaster);
      setLastDisplayColumns(() => userDetail?.lastDisplayColumns);
    }, [client, storeUser?.id, storeUser?.site]);

    const localUserFilters = useRef(userFilters);
    useEffect(() => {
      localUserFilters.current = userFilters;
    }, [userFilters]);

    useEffect(() => {
      loadFilterMasterData();
    }, [loadFilterMasterData]);

    useEffect(() => {
      const unorderedHiddenColumn = [];
      const orderedShowColumns = sortBy(
        convertStrToObjColumns(lastDisplayColumns)?.displayDefinition?.showColumns,
        ["order"],
        ["asc"]
      );

      allTableColumn()?.forEach((value, index) => {
        const obj = find(orderedShowColumns, {
          key: value?.key
        });
        if (!obj) {
          unorderedHiddenColumn.push(value);
        }
      });

      const redefinedColumns = [...orderedShowColumns, ...unorderedHiddenColumn]?.map((value) => {
        return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField));
      });

      const defaultShowCols = filter(allTableColumn(), { show: true });
      setDefaultShowColumns(() =>
        defaultShowCols?.map((value) => {
          return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField));
        })
      );

      const aditionColumns = []; //additional columns can be declared here
      setAdditionalFields(() => [...aditionColumns]);
      setDataModel([...redefinedColumns]);
      // eslint-disable-next-line
    }, [masterDataFilters]);

    const initiallySelectedEquipmentsStringified = JSON.stringify(initiallySelectedEquipments);

    useEffect(() => {
      if (selectedEquipmentsId.length + initiallySelectedEquipments.length > MAX_SELECTED) {
        if (badgeType !== BADGE_TYPE.WARNING)
          Notify({
            type: "info",
            icon: "alarm",
            text: `Limit exceeded, to continue reduce your selection to ${MAX_SELECTED} equipment`,
            title: ""
          });
        setBadgeType(BADGE_TYPE.WARNING);
        setAddButtonDisabled(true);
      } else {
        if (selectedEquipmentsId.length === 0) {
          setBadgeType(BADGE_TYPE.ACTIVE);
          setAddButtonDisabled(true);
        } else {
          setAddButtonDisabled(false);
          setBadgeType(BADGE_TYPE.ACTIVE);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEquipmentsId, initiallySelectedEquipments.length, initiallySelectedEquipmentsStringified]);

    const autoGroupColumnDef = useMemo(() => {
      return {
        headerName: "Model",
        field: autoGroupColField,
        sortable: true,
        pinned: "left",
        lockPinned: true,
        filter: "agSetColumnFilter",
        lockVisible: true,
        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          values: (params) => {
            const values = masterDataFilters[DATA_MODEL_TABLE?.equipmentModel?.key] || [];
            params.success(values);
          }
        },
        showDisabledCheckboxes: true,
        cellStyle: (params) => {
          return params?.node?.level > 0 &&
            (params?.data?.entry_type[0] === entryType?.cluster ||
              params?.data?.entry_type[0] === entryType?.clusterSubequipment)
            ? {
                paddingLeft: 20,
                border: "none !important",
                backgroundColor: "#F5F5F2",
                backgroundImage: "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
              }
            : {};
        },
        cellRendererParams: {
          selected: true,
          checkbox: (params) => {
            let isInInitiallySelected = false;
            initiallySelectedEquipments?.forEach((initialEquipment) => {
              if (compareEquipmentModels(initialEquipment, params?.data)) isInInitiallySelected = true;
            });
            return !isInInitiallySelected;
          },
          innerRenderer: (params) => {
            return params?.data?.equipment_model[0];
          }
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterDataFilters]);

    const onCheckBoxSelected = (data, test) => {
      const { id = "", updatedIds = null, rowData } = data;
      if (id) {
        setSelectedEquipmentsId((prevState) => [...prevState, id]);
        setSelectedEquipments((prevState) => {
          if (!prevState.some((equipment) => equipment.id === id)) {
            return [...prevState, { id, rowData }];
          }
          return prevState;
        });
      }
      if (updatedIds) {
        setSelectedEquipmentsId(updatedIds);
        setSelectedEquipments((prevEquipments) => prevEquipments.filter((element) => updatedIds.includes(element.id)));
      }
    };

    const onSave = async ({
      currentState,
      chipName = "",
      type = "update",
      isAgGridObj = false,
      pannel = "",
      isSaveConfigUpdated = false,
      updateRedux = true
    }) => {
      let result = null;
      if (pannel === TOOL_PANELS?.filters) {
        let obj = {};
        if (isAgGridObj) {
          for (const key in DEFAULT_FILTER.filterDefinition) {
            if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)] === undefined) {
              obj[key] = DEFAULT_FILTER.filterDefinition[key];
            } else if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.filterType === "set") {
              let values = currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.values;
              if (
                filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
                key === DATA_MODEL_TABLE?.equipmentModel?.key
              ) {
                if (Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)) {
                  values = uniq([...values, ...currentState[agGridAutoGroupfieldName]?.values]);
                }
              }
              obj[key] = values;
            } else {
              obj[key] = currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
            }
          }
        } else {
          obj = {
            ...currentState
          };
        }

        const lastSavedFilter = {
          filterDefinition: obj,
          filterName: chipName
        };
        if (type === "create") {
          lastSavedFilter.userID = storeUser?.id;
          lastSavedFilter.chipName = chipName;
          result = await onCreatefilter({
            id: storeUser?.id,
            email: storeUser?.email,
            userFilters: localUserFilters.current,
            lastFavFilter: lastSavedFilter,
            client,
            query: CREATE_USER_FILTER,
            userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
            updateUserFilters: setUserFilters,
            updateLastFavFilter: setLastFavFilter
          });
        } else {
          result = await onUpdateFilter({
            lastFavFilter: lastSavedFilter,
            isSaveConfigUpdated: isSaveConfigUpdated,
            updateRedux,
            id: storeUser?.id,
            email: storeUser?.email,
            client,
            query: UPDATE_USER_FILTER,
            userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
            updateLastFavFilter: setLastFavFilter
          });
        }
        return { savedObj: lastSavedFilter, result };
      } else if (pannel === TOOL_PANELS?.columns) {
        const savedColumns = [...currentState];
        const updatedSavedColumns = savedColumns.map((column, index) => {
          const dataModelKey = convertToCamelCase(column?.colId);
          const obj = {
            key: dataModelKey,
            val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
            order: index,
            show: DATA_MODEL_TABLE[dataModelKey]?.value?.length ? !column?.hide : "ignore",
            sortStatus: listOfFieldsSortable.includes(DATA_MODEL_TABLE[dataModelKey]?.key)
          };
          return obj;
        });
        const showColumns = filter(updatedSavedColumns, { show: true });
        const hideColumns = filter(updatedSavedColumns, { show: false });
        let savecolumnObj = {
          displayName: chipName,
          displayDefinition: {
            showColumns,
            hideColumns
          }
        };
        if (type === "create") {
          savecolumnObj.userID = storeUser?.id;
          savecolumnObj.chipName = chipName;
          result = await onCreateSelectColumn({
            id: storeUser?.id,
            email: storeUser?.email,
            userDisplayList: userDisplayColumn,
            unParsedData: savecolumnObj,
            client,
            query: CREATE_USER_DISPLAY_COLUMN,
            userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
            updateUserDisplayColumns: setUserDisplayColumn,
            updateLastDisplayColumn: setLastDisplayColumns
          });
        } else {
          result = await onUpdateSelectColumn({
            data: JSON.stringify(savecolumnObj),
            isSaveConfigUpdated,
            updateRedux,
            id: storeUser?.id,
            email: storeUser?.email,
            client,
            userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
            query: UPDATE_USER_DISPLAY_COLUMN,
            userDisplayList: userDisplayColumn,
            updateUserDisplayColumns: setUserDisplayColumn,
            updateLastDisplayColumn: setLastDisplayColumns
          });
        }
        return { savedObj: savecolumnObj, result };
      }
    };

    const onChipDelete = async (chipToDelete, lastSavedChip, panel, userFilters) => {
      return await onDeleteSaveConfigurations({
        userID: storeUser?.id,
        chipToDelete,
        panel,
        lastSavedChip,
        client,
        email: storeUser?.email,
        deleteFilterQuery: DELETE_USER_FILTER,
        deleteColumnQuery: DELETE_USER_DISPLAY_COLUMN,
        userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
        userFilters,
        userDisplayList: userDisplayColumn,
        updateUserFilters: setUserFilters,
        updateUserDisplayColumns: setUserDisplayColumn,
        updateLastFavFilter: setLastFavFilter,
        updateLastDisplayColumn: setLastDisplayColumns
      });
    };
    const isServerSideGroup = useCallback((dataItem) => {
      // indicate if node is a group
      if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
        return dataItem?.id;
      }

      return "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getServerSideGroupKey = useCallback((dataItem) => {
      // specify which group key to use
      if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
        return dataItem?.id;
      }

      return "";
    }, []);

    const onResetAll = async () => {
      await onUpdateFilter({
        lastFavFilter: DEFAULT_FILTER,
        updateRedux: true,
        id: storeUser?.id,
        email: storeUser?.email,
        client,
        query: UPDATE_USER_FILTER,
        userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
        updateLastFavFilter: setLastFavFilter
      });
      await onUpdateSelectColumn({
        data: JSON.stringify(DEFAULT_COLUMNS),
        updateRedux: true,
        id: storeUser?.id,
        email: storeUser?.email,
        client,
        userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
        query: UPDATE_USER_DISPLAY_COLUMN,
        userDisplayList: userDisplayColumn,
        updateUserDisplayColumns: setUserDisplayColumn,
        updateLastDisplayColumn: setLastDisplayColumns
      });
    };
    const onRowsPerChange = async (rowPerPage) => {
      await saveLastPageSize({
        lastPageSize: rowPerPage,
        query: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
        updateLastPageSize: setLastPageSize,
        id: storeUser?.id,
        email: storeUser?.email,
        client
      });
    };

    //override of default AgGrid onViewportChanged
    const onViewportChanged = async (gridApi) => {
      let nodes = gridApi?.api?.getRenderedNodes();
      let startTime = Date.now();
      //initially nodes are created, but without id's and data (spinners)
      //We need to wait for nodes with data.id - 3s limit to avoid infinite loop
      while (!nodes?.[0]?.data?.id && Date.now() - startTime < 3000) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        nodes = gridApi?.api?.getRenderedNodes();
      }
      if (gridApi?.api && nodes?.length > 0) {
        if (selectedEquipmentsId?.length > 0 || initiallySelectedEquipments.length > 0) {
          gridApi?.api?.forEachNode(async (node, index) => {
            const isSelectedInModal = selectedEquipmentsId.includes(node?.data?.id?.[0]);
            const isInitiallySelected = initiallySelectedEquipments.some((item) =>
              compareEquipmentModels(item, node?.data)
            );
            return node?.setSelected(isSelectedInModal || isInitiallySelected);
          });
        }
      }
    };
    if (Object.keys(masterDataFilters)?.length === 0) {
      return (
        <>
          <div
            className="loading"
            style={{
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress size={30} />
          </div>
        </>
      );
    }

    return (
      <OwcModalDialog
        disableScrollLock
        visible={open}
        className="owcmodalZIndex"
        size={"lg"}
        width={"1440px"}
        disableBackdropClick={true}
        close={close}
        cancel={true}
      >
        <div slot="header">{title && <OwcTypography variant="title6">{title}</OwcTypography>}</div>
        <div>
          <CommonAgGrid
            autoGroupColumnDefObj={autoGroupColumnDef}
            columnData={dataModel}
            masterDataFilters={masterDataFilters}
            additionalFields={additionalFields}
            userFilters={userFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={lastFavFilter}
            lastDisplayColumns={lastDisplayColumns ?? columnDefsOrDefault}
            suppressRowClickSelection={true}
            userDisplays={userDisplayColumn}
            systemDisplays={[]}
            onSave={onSave}
            onChipDelete={onChipDelete}
            defaultFilterObj={DEFAULT_FILTER}
            defaultShowColumns={defaultShowColumns}
            listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
            listOfNumberFilter={listOfNumberFilter}
            listOfFieldAcceptOnlyInteger={listOfFieldAcceptOnlyInteger}
            isServerSideGroupObj={isServerSideGroup}
            getServerSideGroupKeyObj={getServerSideGroupKey}
            onResetAll={onResetAll}
            showSaveConfiguration={true}
            limit={typeof lastPageSize === "string" ? JSON.parse(lastPageSize)?.ir_grid : lastPageSize?.ir_grid}
            onRowsPerChange={onRowsPerChange}
            requestServer={requestServer}
            siteName={storeUser?.site}
            literalFields={literalFields}
            onCheckBoxChange={onCheckBoxSelected}
            onViewportChanged={onViewportChanged}
            selectedEquipment={selectedEquipmentsId}
            customSearchQueryRestriction = {' -_id: 4pf_* '}
            isStandaloneEquipmentOnly
            {...attributes}
          />
        </div>

        <div slot="actions">
          <OwcGrid
            container
            columns={3}
            style={{
              padding: "1rem 0"
            }}
          >
            <OwcGrid item></OwcGrid>
            <OwcGrid style={{ alignSelf: "center" }} item>
              <div
                style={{
                  textAlign: "center"
                }}
              >
                <OwcTypography variant="subtitle2">
                  Equipment selected&nbsp;
                  <OwcBadge size={"medium"} type={badgeType}>
                    {selectedEquipmentsId.length + initiallySelectedEquipments.length}/{MAX_SELECTED}
                  </OwcBadge>
                </OwcTypography>
              </div>
            </OwcGrid>
            <OwcGrid item>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  padding: "0.25rem 0",
                  justifyContent: "flex-end"
                }}
              >
                <OwcButton variant="secondary" onClick={handleCancel} disabled={loading}>
                  {cancelTitle}
                </OwcButton>
                <OwcButton variant="primary" onClick={handleApprove} disabled={loading || addButtonDisabled}>
                  <div style={{ display: "flex" }}>
                    {loading && (
                      <OwcProgressSpinner
                        style={{
                          height: 24,
                          width: 24,
                          marginTop: "0px"
                        }}
                        diameter={24}
                      />
                    )}
                    {approveTitle}
                  </div>
                </OwcButton>
              </div>
            </OwcGrid>
          </OwcGrid>
        </div>
      </OwcModalDialog>
    );
  }
);
