import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { format } from "date-fns";

const fileType = "text/csv;charset=utf-8";
const fileExtension = ".csv";

export const exportItemToCSV = async ({
  paginationContext: { sortDirection },
  customFilterContext: { signee, equipmentModel, instrument, dateFrom, dateTo },
  searchTextContext: { logListData },
  storeUser,
  client,
  fetchingFn,
  fileNamePrefix,
  mapFn,
  inventoryId,
  logType
}) => {
  /*const { items } = await fetchingFn({
    client,
    signee,
    equipmentModel,
    instrument,
    dateFrom,
    dateTo,
    sortDirection,
    limit: 200,
    nextToken: null,
    drillData: true,
    storeUser,
    inventoryId,
    logType
  });*/

  const fileName = `${fileNamePrefix}-${format(new Date(), "dd-MMM-yyyy")}`;
  // mapping object to csv data
  const jsonSheet = mapFn(logListData);

  //CR: why do we use this library and not ag-grid built-in support for exporting to csv?
  const ws = XLSX.utils.json_to_sheet(jsonSheet);

  const csvBuffer = XLSX.utils.sheet_to_csv(ws);
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);

  return csvBuffer;
};
