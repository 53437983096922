import { UUID } from "crypto";
import { GET_IMPORT_FILE_FROM_ENDOR } from "../../../../gql/logBooksapi";
import { store } from "../../../../store";
import { Notify, NotifyPropsType } from "@digitallab/grid-common-components";

type AttachmentType = {
  addedBy: string;
  bucketRegion: string;
  createdAt: string; //date
  fileDescription: string,
  fileId: UUID,
  fileName: string,
  groupId: UUID,
  groupMetadataVersion: string,
  status: "ACTIVE" | "INACTIVE",
  targetModule: "LOGBOOK"
 }

/**
 * fetches predefined Url for file to download
 * @param {class} client appsync client
 * @param {object} user user from current session
 * @param {string} fileId if of file to be downloaded
 * @returns {string} url as string
 */
const getUrlToDownloadFile = async (fileId: string) => {
  const storeState = store.getState();
  const id = storeState.user.id;
  const client = storeState.appSyncClient.client;
  const { data } = await client.query({
    query: GET_IMPORT_FILE_FROM_ENDOR,
    fetchPolicy: "no-cache",
    variables: {
      targetModule: "LOGBOOK",
      metadata: JSON.stringify({ userId: id }),
      fileId
    }
  });
  return data?.getImportFileFromEndor?.url || null;
};

/**
 * function that downloads file itself
 * @param {string} url presigned url to the file
 * @param {string} name file name
 */
const downloadFile = (url: string, name: string) => {
  const a = document.createElement("a");
  a.setAttribute("download", name);
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.click();
};

const NotifyDownloadErrorProps = {
    type: "warning",
    icon: "alarm",
    text: "Try again later",
    title: "Error downloading file."
 } as NotifyPropsType;

/**
 * function that handles process of downlaoding file
 * @param {class} client appsync client
 * @param {object} user user of current session
 * @param {string} fileId id of file to be downlaoded
 * @param {string} name file name
 */
export const getFile = async (item: AttachmentType) => {
  const url = await getUrlToDownloadFile(item.fileId);
  url ? downloadFile(url, item.fileName) : Notify(NotifyDownloadErrorProps);
};
