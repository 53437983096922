import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import { loadUserInfo } from "../user/redux/actions";
import styled from "styled-components";
import { signOut } from "../../utils/signout";
import {
  GROUPS_TOKEN_PATH,
  SYNC_PROFILE_FIELDS,
  ACCESS_TOKEN_PATH,
  EDITORS_GROUP,
  REQUIRED_GROUPS,
  displayColumns,
  DEFAULT_COLUMNS,
  allTableColumn
} from "../../constants";
import {
  CREATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE,
  UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS
} from "../../gql/logBooksapi/mutations";
import {
  INSTRUMENT_BY_SITE,
  LOGBOOK_GET_LOGGED_USER_DETAILS,
  LIST_DIGITAL_LAB_LOGBOOK_USER_PROFILES,
  GET_DIGITAL_LAB_LOGBOOK_INSTRUMENT
} from "../../gql/logBooksapi/queries";
import { loadEquipmentModels } from "./redux/actions";
import { loadEquipmentSignee } from "./redux/actions";
import { getAllData } from "../../utils/helpers/fetching";
import { isEqual, pick, sortBy, uniq } from "underscore";
import { logSheetLoadItemSpecifics } from "./log-sheet/log-sheet-form/log-sheet-load-item-specifics";
import { runLogsLoadItemSpecifics } from "./run-logs/run-logs-form/run-logs-load-item-specifics";
import {Notify} from "@digitallab/grid-common-components";
import { CircularProgress, Typography } from "@mui/material";
import { OwcButton, OwcIconButton } from "@one/react";
import { getMegaClusterIds } from "./loadLogBookInfo";
import { loadMegaClusterIds } from "./log-book-item-form-dialog/redux/actions";

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;
`;

const checkAccesGroupInArray = (access_groups, array) => {
  return access_groups.some((group) => array.includes(group));
};
class SiteError extends Error {
  constructor(message) {
    super(message);
    this.name = "SiteError";
  }
}

const stripPhoneNumber = (phoneNumber) => phoneNumber?.replace(/[^+\d]/gi, "") ?? "";

const checkRoleAndGetUserEmail = async () => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (!Array.isArray(access_groups) || !checkAccesGroupInArray(access_groups, REQUIRED_GROUPS)) {
    throw new Error("You do not have permission to access.");
  }
  const canEdit = checkAccesGroupInArray(access_groups, EDITORS_GROUP);
  const syncFields = pick(get(currentAuthenticatedUser, ACCESS_TOKEN_PATH), Object.values(SYNC_PROFILE_FIELDS));
  if (syncFields?.[SYNC_PROFILE_FIELDS.phone]) {
    syncFields[SYNC_PROFILE_FIELDS.phone] = stripPhoneNumber(syncFields?.[SYNC_PROFILE_FIELDS.phone]);
  }
  const email = get(currentAuthenticatedUser, "attributes.email");
  return { email, canEdit, ...syncFields };
};

export const updateProfileFields = async ({ client, email, user, ...syncFields }) => {
  const syncKeys = Object.keys(SYNC_PROFILE_FIELDS);
  const profileObj = syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFields?.[SYNC_PROFILE_FIELDS?.[currKey]] ?? ""
    }),
    {}
  );
  if (!user) {
    return profileObj;
  }
  const shouldUpdate = !isEqual(pick(user, syncKeys), {
    ...profileObj,
    site: profileObj?.site || user.site
  });
  if (!shouldUpdate) {
    return user;
  }
  const {
    data: { updateDigitalLabLogbookUserProfile: updatedUser }
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      email,
      ...profileObj,
      site: profileObj?.site || user?.site
    }
  });
  return updatedUser;
};

export const getUserDetails = async ({ client, email, ...syncFields }) => {
  const result = await client.query({
    query: LOGBOOK_GET_LOGGED_USER_DETAILS,
    fetchPolicy: "no-cache"
  });

  const user = get(result, "data.getLoggedInUserDetails.items[0]");

  if (!syncFields?.[SYNC_PROFILE_FIELDS.site] && !user?.site) {
    throw new SiteError("You do not have site location assigned. Go to the Landing Page to configure this.");
  }
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site]) {
    Notify({
      type: "warning",
      icon: "alarm",
      title: "",
      text: "Your location has been changed. Please check your settings at the Landing Page."
    });
  }

  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    user,
    email,
    ...syncFields
  });

  if (profileFieldsOrUpdatedUser?.id) {
    return { user: profileFieldsOrUpdatedUser };
  }

  const {
    data: { createDigitalLabLogbookUserProfile }
  } = await client.mutate({
    mutation: CREATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      email,
      ...profileFieldsOrUpdatedUser
    }
  });

  return {
    user: createDigitalLabLogbookUserProfile
  };
};

export const getEquipDetailInfo = async (id, client) => {
  if (id !== undefined) {
    try {
      const result = await client.query({
        query: GET_DIGITAL_LAB_LOGBOOK_INSTRUMENT,
        fetchPolicy: "no-cache",
        variables: {
          inventoryId: id
        }
      });

      if (result?.data?.getDigitalLabLogbookInstrument) {
        return result?.data?.getDigitalLabLogbookInstrument;
      }
    } catch (err) {}
  }
};

const LoadLogBooksInfo = ({
  children,
  client,
  loadUserInfo,
  loadEquipmentModels,
  loadEquipmentSignee,
  loadMegaClusterIds
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { email, canEdit, ...syncFields } = await checkRoleAndGetUserEmail();
        const { user } = await getUserDetails({ client, email, ...syncFields });

        if (!user?.lastDisplayColumns) {
          user.lastDisplayColumns = {
            displayName: DEFAULT_COLUMNS?.displayName,
            displayDefinition: {
              showColumns: allTableColumn().filter((item) => item.show),
              hideColumns: allTableColumn().filter((item) => !item.show)
            }
          };
        }

        if (user?.lastDisplayColumns) {
          const lastDisplayColumns =
            typeof user?.lastDisplayColumns === "string"
              ? JSON.parse(user?.lastDisplayColumns)
              : user?.lastDisplayColumns;
          if (!lastDisplayColumns.hasOwnProperty(displayColumns?.EQUIPMENT_LIST)) {
            user.lastDisplayColumns = {
              equipmentList: user?.lastDisplayColumns
            };
            client.mutate({
              mutation: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
              variables: {
                id: user?.id,
                email,
                lastDisplayColumns: JSON.stringify(user?.lastDisplayColumns)
              },
              fetchPolicy: "no-cache"
            });
          }
        }
        loadUserInfo({
          ...user,
          canEdit
        });
        const [{ items: equipmentModel }, { items: userProfiles }, megaClusterIds] = await Promise.all([
          getAllData({
            client,
            query: INSTRUMENT_BY_SITE,
            variables: {
              siteName: user.site,
              limit: 1000
            },
            dataPath: ["data", "instrumentBySite"],
            drillData: true
          }),
          getAllData({
            client,
            query: LIST_DIGITAL_LAB_LOGBOOK_USER_PROFILES,
            variables: {
              limit: 1000
            },
            dataPath: ["data", "listDigitalLabLogbookUserProfiles"],
            drillData: true
          }),
          getMegaClusterIds(),
          logSheetLoadItemSpecifics({
            client,
            dispatch,
            site: user?.site
          }),
          runLogsLoadItemSpecifics({
            client,
            dispatch,
            site: user?.site
          })
        ]);

        loadEquipmentModels(
          sortBy(uniq(equipmentModel.map((type) => type.equipmentModel)), (v) => v?.toLowerCase()).filter((v) =>
            Boolean(v)
          )
        );
        loadMegaClusterIds(megaClusterIds);
        let list = userProfiles.map((item) => ({
          ...item,
          key: item?.email,
          value: item?.name
        }));
        list = sortBy(list, "value").filter((v) => v.value !== "");
        loadEquipmentSignee(uniq(list, "value"));
      } catch (err) {
        console.warn(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserInfo, loadEquipmentModels, client, retry, dispatch, loadEquipmentSignee]);
  if (loading) {
    return (
      <FullScreenCentered>
        <CircularProgress data-testid="loading" size={80} />
      </FullScreenCentered>
    );
  }
  if (error !== null) {
    return (
      <FullScreenCentered>
        <>
          <Typography variant="h6" data-testid="message-error" gutterBottom>
            {error?.message ? error?.message : "Unexpected error has occurred"}
          </Typography>
          {error?.name !== "SiteError" && (
            <OwcIconButton variant="primary" onClick={() => setRetry(retry + 1)} data-testid="try-again-button">
              Try again
            </OwcIconButton>
          )}
          <OwcButton
            data-testid="signout-button"
            variant="primary"
            style={{ marginTop: ".7rem" }}
            onClick={() => signOut()}
          >
            Sign out
          </OwcButton>
        </>
      </FullScreenCentered>
    );
  }
  return children;
};

export default compose(
  connect(null, {
    loadUserInfo,
    loadEquipmentModels,
    loadEquipmentSignee,
    loadMegaClusterIds
  }),
  withApollo
)(LoadLogBooksInfo);
