import { THEME } from "@digitallab/grid-common-components";
import { OwcIconButton, OwcTypography } from "@one/react";

export const LogsTopBar = ({ titleText, onCancel }: { titleText: string; onCancel: () => {} }) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      height: THEME["one-sizer-base-100"],
      width: "100vw",
      display: "flex",
      alignContent: "center",
      backgroundColor: THEME["one-color-cobas-accent-gray-100"]
    }}
  >
    <OwcIconButton icon="clear" style={{ color: THEME["one-color-cobas-gray-600"] }} flat onClick={onCancel} />
    <OwcTypography style={{ alignContent: "center" }}>{titleText}</OwcTypography>
  </div>
);
