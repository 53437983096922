import React from "react";
import styled from "styled-components";

import ChangeLogGridTable from "./ChangeLogAgGridTable";
import { formTypes } from "../../../constants";

export const ScreenCentered = styled.div`
  text-align: center;
`;

const LogBookTableStyled = styled.div``;

const ChangeLogTable = ({ dataTestId }) => {
  return (
    <LogBookTableStyled data-testid={dataTestId}>
      <ChangeLogGridTable type={formTypes?.CHANGE_LOG} />
    </LogBookTableStyled>
  );
};

export default ChangeLogTable;
