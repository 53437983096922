import { omit } from "underscore";
import { v4 } from "uuid";
import { DEFAULT_SITE, formStates } from "../../../../constants";

export const makeCleanRunLog = ({ item, isNew, user, formState }) => {
  return isNew
    ? {
        id: v4(),
        runLogEntryId: v4(),
        runStartDate: new Date(),
        runEndDate: new Date(),
        approverId: user?.user ?? "",
        approverUserName: user.name ?? "",
        site: user.site ?? DEFAULT_SITE,
        inventoryId: formState === formStates?.NEW_FORM ? "" : item?.inventoryId,
        equipmentId: formState === formStates?.NEW_FORM ? "" : item?.equipmentId,
        serialNumber: formState === formStates?.NEW_FORM ? "" : item?.serialNumber,
        gxpReady: formState === formStates?.NEW_FORM ? { key: null, value: null } : item?.gxpReady,
        systemStatus: formState === formStates?.NEW_FORM ? { key: null, value: null } : item?.systemStatus
      }
    : {
        ...omit(item, "__typename"),
        approverId: user?.user ?? "",
        approverUserName: user.name ?? "",
        site: user.site ?? DEFAULT_SITE,
        mediaType: item?.mediaType || [],
        instrumentDetails: {
          ...omit(item?.instrumentDetails, "__typename")
        },
        id: v4(),
        runLogEntryId: item?.id
      };
};
