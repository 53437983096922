import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { DetailsInfoInstruments } from "./DetailsInfoInstruments";
import styled from "styled-components";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const DetailsInfoBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px 30px;
`;
const CoverInfo = [
  {
    label: "Equipment ID",
    field: ["equipmentId"],
    source: "suggestion"
  },
  {
    label: "Serial number",
    field: ["serialNumber"],
    source: "suggestion"
  },
  {
    label: "Material number",
    field: ["materialNumber"],
    source: "suggestion"
  },
  {
    label: "Nickname",
    field: ["equipmentNickName"],
    source: "suggestion"
  },
  {
    label: "Model",
    field: ["equipmentModel"],
    source: "suggestion"
  },
  {
    label: "Manufacturer",
    field: ["manufacturer"],
    source: "suggestion"
  },
  {
    label: "Software version",
    field: ["softwareVersion"],
    source: "suggestion"
  },
  {
    label: "Configuration",
    field: ["configurationBaseline"],
    source: "suggestion"
  },
  {
    label: DATA_MODEL_TABLE?.gxpReady?.value,
    field: ["qualificationStatus"],
    source: "suggestion"
  },
  {
    label: "System status",
    field: ["systemStatus"],
    source: "suggestion"
  },
  {
    label: "Location",
    field: ["location"],
    source: "suggestion"
  },
  {
    label: "Responsible person",
    field: ["responsiblePerson"],
    source: "suggestion"
  },
  {
    label: "Responsible proxy",
    field: ["responsibleProxy"],
    source: "suggestion"
  }
];

const ItemCoverSheetContent = () => {
  const formik = useFormikContext();
  const instrumentSuggestion = useSelector((state) => state.runLogsForm.instrumentSuggestion);
  return (
    <DetailsInfoBox data-testid="cover-sheet-expanded-content">
      {CoverInfo.map((item, index) => {
        const dataSource = item.source === "formik" ? formik.values : instrumentSuggestion;
        return <DetailsInfoInstruments item={item} dataSource={dataSource} index={index} />;
      })}
    </DetailsInfoBox>
  );
};

export default ItemCoverSheetContent;
