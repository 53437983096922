import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Auth } from "@aws-amplify/auth";
import { setClient } from "./reducers/actions";
import { store } from "./store";

// AppSync client instantiation
export const appSyncClient = () => {
  const client = new AWSAppSyncClient({
    url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken()
    },
    disableOffline: true
  });
  store.dispatch(setClient(client));
  return client;
};
