import React, { useContext } from "react";
import StandAlonActionDialog from "./StandAloneActionDialog";
import { entryType } from "../../constants";
import ClusterActionDialog from "./ClusterActionDialog";
import { ItemDialogFormContext } from "../log-book/log-book-item-form-dialog/item-context/context";

const EquipmentAction = ({ type, equipmentDetail }) => {
  const { setIsStandaloneInvId } = useContext(ItemDialogFormContext);

  if (equipmentDetail?.entryType === entryType.standaloneEquipment) {
    setIsStandaloneInvId(equipmentDetail?.inventoryId);
  }
  return (
    <>
      {equipmentDetail?.entryType === entryType.standaloneEquipment && equipmentDetail && (
        <StandAlonActionDialog type={type} equipmentDetail={equipmentDetail} />
      )}
      {equipmentDetail?.entryType === entryType.cluster && Object.keys(equipmentDetail.subEquipment).length !== 0 && (
        <ClusterActionDialog type={type} equipmentDetail={equipmentDetail} />
      )}
    </>
  );
};
export default EquipmentAction;
