import React, { useContext, useState } from "react";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CustomFilterContext } from "../custom-filter/context";
import { useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import { exportToPDF } from "./export-to-pdf";
import { SearchTextContext } from "../log-book-item-fillter/suggester/search-context/context";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { OwcListItem } from "@one/react";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { formTypes } from "../../../constants";

const ExportToPDFButton = ({
  client,
  dataTestId,
  fetchingFn,
  fileNamePrefix,
  raportTitle,
  mapFn,
  getMetaDescription,
  id
}) => {
  const [exporting, setExporting] = useState(false);
  const { appliedSearchText } = useContext(SearchTextContext);
  const { logType, allLogType, allLogHierarchyData, logBookEquipment } = useContext(CoverSheetMainPageContext);
  const paginationContext = useContext(PaginationContext);
  const customFilterContext = useContext(CustomFilterContext);
  const searchTextContext = useContext(SearchTextContext);
  const storeUser = useSelector((store) => store.user);
  const storeGxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const { inventoryId } = useParams();
  const savePDF = async () => {
    setExporting(true);
    await exportToPDF({
      client,
      customFilterContext,
      paginationContext,
      searchTextContext,
      storeUser,
      fetchingFn,
      fileNamePrefix,
      mapFn,
      raportTitle,
      getMetaDescription,
      appliedSearchText,
      inventoryId,
      logType: logType === formTypes?.ALL_LOG ? allLogType : logType,
      allLogHierarchyData,
      logBookEquipment,
      storeGxpReadys
    });
    setExporting(false);
  };
  return (
    <OwcListItem
      data-testid={`export-pdf-${dataTestId}-button`}
      noBorder={true}
      onClick={savePDF}
      disabled={
        logType === formTypes?.ALL_LOG
          ? paginationContext.runData.length === 0 && paginationContext.actionData.length === 0
          : paginationContext.data.length === 0 || paginationContext.fetching || exporting
      }
      id={id}
    >
      {exporting && <CircularProgress size={20} style={{ marginRight: 6 }} />}
      {LOGBOOK_LABEL.BUTTON.download_PDF}
    </OwcListItem>
  );
};

export default withApollo(ExportToPDFButton);
