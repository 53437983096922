import React, { useEffect } from "react";
import ItemFormSuggestionTable from "./ItemFormSuggestionTable";
import { Popover } from "@mui/material";
import { useSelector } from "react-redux";

const ItemFormSuggestionPickerBase = ({
  loadInstrumentSuggestion,
  anchorEl,
  selectSuggestionFn,
  instrumentSuggestions,
  formik,
  meta,
  onOpen,
  PopoverStyle = {},
  shouldShow = true
}) => {
  const [open, setOpen] = React.useState(false);
  const [currentAnchorEl, setCurrentAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (anchorEl && currentAnchorEl !== anchorEl) {
      setCurrentAnchorEl(anchorEl);
      setOpen(true);
    }
    if (!anchorEl && currentAnchorEl && !open) {
      setCurrentAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  useEffect(() => {
    if (instrumentSuggestions.length > 1 && shouldShow) {
      setOpen(true);
    }
  }, [instrumentSuggestions, shouldShow]);

  useEffect(() => {
    onOpen?.(open);
  }, [open, onOpen]);

  const closePopover = () => {
    setOpen(false);
  };

  if (instrumentSuggestions.length <= 1) {
    return null;
  }

  return (
    <Popover
      data-testid="suggestion-picker-popover"
      open={open}
      onClose={closePopover}
      anchorEl={currentAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      PaperProps={{
        style: {
          width: currentAnchorEl?.parentElement?.clientWidth ?? "auto",
          ...PopoverStyle
        }
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <ItemFormSuggestionTable
        meta={meta}
        instrumentSuggestions={instrumentSuggestions}
        onSelect={(instrument) => {
          const instrumentUpdatedObj = {
            ...instrument,
            equipSystemStatus: instrument?.systemStatus
          };
          selectSuggestionFn(instrumentUpdatedObj, {
            loadInstrumentSuggestion,
            formik,
            user
          });
          closePopover();
        }}
      />
    </Popover>
  );
};

export default ItemFormSuggestionPickerBase;
