import ClusterItemFormDialog from "../log-book/log-book-item-form-dialog/ClusterItemFormDialog";
import ClusterItemSubComponentDialog from "../log-book/log-book-item-form-dialog/ClusterItemSubComponentDialog";
import { emptyRunLogCluster } from "../log-book/run-logs/run-logs-form/empty-run-log";
import { emptyLogSheetCluster } from "../log-book/log-sheet/log-sheet-form/empty-log-sheet";
import { CreateRunLogChangeCluster } from "../log-book/run-logs/run-logs-form/CreateRunLogChange";
import { CreateLogSheetChangeCluster } from "../log-book/log-sheet/log-sheet-form/CreateLogSheetChange";
import { runLogClusterValidationSchemaFactory } from "../log-book/run-logs/run-logs-form/run-log-validation-schema";
import { logSheetClusterValidationSchemaFactory } from "../log-book/log-sheet/log-sheet-form/log-sheet-validation-schema";
import LOGBOOK_LABEL from "../../utils/constants/logbookLabel";
import { makeCleanRunLog } from "../log-book/run-logs/run-logs-form/make-clean-run-log";
import { makeCleanLogSheet } from "../log-book/log-sheet/log-sheet-form/make-clean-log-sheet";
import { connect } from "react-redux";
import { useContext } from "react";
import { ItemDialogFormContext } from "../log-book/log-book-item-form-dialog/item-context/context";
import { useEffect } from "react";
import { formTypes } from "../../constants";

const ClusterActionDialog = ({ type, equipmentDetail }) => {
  const { handleOpen } = useContext(ItemDialogFormContext);
  useEffect(() => {
    handleOpen(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ClusterItemFormDialog
        addNewDialogButtonLabel={
          type === formTypes?.RUN_LOG ? LOGBOOK_LABEL.BUTTON.add_new_run : LOGBOOK_LABEL.BUTTON.add_new_log
        }
        cleanItemFn={type === formTypes?.RUN_LOG ? makeCleanRunLog : makeCleanLogSheet}
        emptyItem={type === formTypes?.RUN_LOG ? emptyRunLogCluster : emptyLogSheetCluster}
        createItemChangeFn={type === formTypes?.RUN_LOG ? CreateRunLogChangeCluster : CreateLogSheetChangeCluster}
        validationSchemaFactory={
          type === formTypes?.RUN_LOG ? runLogClusterValidationSchemaFactory : logSheetClusterValidationSchemaFactory
        }
        dataTestId={type === formTypes?.RUN_LOG ? "run-log" : "action-log"}
        type={type}
        equipmentDetail={equipmentDetail}
      />
      <ClusterItemSubComponentDialog equipmentDetail={equipmentDetail} />
    </>
  );
};

export default connect(null, {})(ClusterActionDialog);
