import React, { useState } from "react";
import InstrumentInformationsDialog from "../InstrumentInformationsDialog";
import { InstrumentInformationsContext } from "./context";

const InstrumentInformationsWrapper = ({ children, addButtonLabel }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);

  const handleOpenInstrumentInformation = (theItem) => {
    setItem(theItem);
    setOpen(true);
  };
  const handleCloseInstrumentInformation = () => {
    setOpen(false);
    setItem(null);
  };

  return (
    <InstrumentInformationsContext.Provider
      value={{
        open,
        handleOpenInstrumentInformation,
        handleCloseInstrumentInformation,
        item
      }}
    >
      {children}
      <InstrumentInformationsDialog addButtonLabel={addButtonLabel} />
    </InstrumentInformationsContext.Provider>
  );
};

export default InstrumentInformationsWrapper;
