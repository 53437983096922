import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { DESCRIPTION_FIELD } from "../../../../utils/constants/descriptionField";
import {
  // actionButtonsColumnFactory,
  ApproverUserName,
  CustomDetailsCellStyled,
  Flex,
  RunLogWrapper,
  SystemStatusCell,
  GxpReadyCell,
  ActionTypeCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import { changeDateFormat } from "../../helpers";

export const LogSheetClusterTableMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: ACTION_LOGS_DATA_MODEL.actionTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item?.actionTime} />
        },
        {
          label: ACTION_LOGS_DATA_MODEL.materialNumber.value,
          field: ["materialNumber"]
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: ACTION_LOGS_DATA_MODEL.ecrNumber.value,
          field: ["ecrNumber"]
        },
        DESCRIPTION_FIELD
      ]
    }
  },
  fields: {
    detailExpander: {
      sortable: false,
      text: ""
    },
    actionDate: {
      sortable: true,
      text: ACTION_LOGS_DATA_MODEL.actionDate.value,
      component: ({ item }) => changeDateFormat(item?.actionDate)
    },
    subEquipment: {
      text: COMMON_LOGS_DATA_MODEL.subEquipment.value
    },
    action: {
      text: ACTION_LOGS_DATA_MODEL.actionType.value,
      component: ActionTypeCell
    },

    gxpReady: {
      text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
      component: GxpReadyCell
    },
    systemStatus: {
      text: ACTION_LOGS_DATA_MODEL.systemStatus.value,
      component: SystemStatusCell
    },
    ecrNumber: {
      text: ACTION_LOGS_DATA_MODEL.ecrNumber.value
    },
    ...ApproverUserName,
    description: {
      text: COMMON_LOGS_DATA_MODEL.description.value
    },
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    }

    //TODO: check with raj and remove the code
    // ...actionButtonsColumnFactory("log-sheet")
  }
};

export const logSheetTabletSizes = {
  actionDate: "0 1 12.75%",
  equipmentModel: "0 1 12.75%",
  action: "0 1 12.75%",
  subEquipment: "0 1 16%",
  approverUserName: "0 1 10.7%",
  gxpReady: "0 1 12.75%",
  systemStatus: "0 1 12.75%",
  actionButtons: "0 0 132px"
};

export const logSheetRegularSizes = {
  actionDate: "0 1 10%",
  equipmentModel: "0 1 15%",
  action: "0 1 15%",
  subEquipment: "0 1 16%",
  approverUserName: "0 1 15%",
  gxpReady: "0 1 15%",
  systemStatus: "0 1 15%",
  actionButtons: "0 0 132px"
};

export default LogSheetClusterTableMeta;
