import DATA_MODEL_TABLE from "./dataModelTable";

const RUN_LOGS_DATA_MODEL = {
  runStartDate: {
    key: "runStartDate",
    value: "Run start"
  },
  runStatus: {
    key: "runStatus",
    value: "Run status"
  },
  description: {
    key: "description",
    value: "Description"
  },
  startDate: {
    key: "runStartDate",
    value: "Start date"
  },
  runStartTime: {
    key: "runStartTime",
    value: "Start time"
  },
  runEndDate: {
    key: "runEndDate",
    value: "Run end"
  },
  runEndTime: {
    key: "runEndTime",
    value: "End time"
  },
  samplesProcessed: {
    key: "samplesProcessed",
    value: "Number of samples"
  },
  numberOfRuns: {
    key: "numberOfRuns",
    value: "Number of runs"
  },
  defectId: {
    key: "defectId",
    value: "Defect ID"
  },
  gxpReady: {
    key: "gxpReady",
    value: DATA_MODEL_TABLE?.gxpReady?.value
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status"
  },
  runIdentification: {
    key: "runIdentification",
    value: "Run ID"
  },
  eLNid: {
    key: "eLNid",
    value: "eLN ID"
  },
  tipsUsed: {
    key: "tipsUsed",
    value: "Tips"
  },
  mediaType: {
    key: "mediaType",
    value: "Media type"
  },
  assay: {
    key: "assay",
    value: "Assays"
  }
};

export default RUN_LOGS_DATA_MODEL;
