import React, { useState } from "react";
import { ItemCoverSheetContext } from "./context";

const ItemCoverSheetWrapper = ({ children }) => {
  const [open, setOpen] = useState(false);
  const handleOpenCoverSheet = () => {
    setOpen(true);
  };
  const handleCloseCoverSheet = () => {
    setOpen(false);
  };

  return (
    <ItemCoverSheetContext.Provider
      value={{
        open,
        handleOpenCoverSheet,
        handleCloseCoverSheet
      }}
    >
      {children}
    </ItemCoverSheetContext.Provider>
  );
};

export default ItemCoverSheetWrapper;
