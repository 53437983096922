import { OwcHyperlink } from "@one/react";
import { COVER_SHEET_LIST } from "../../constants";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { replaceEmptyWithHyphen, validateSelection } from "../../utils/helpers/text";
import EquipmentStatusRender from "../log-book/log-book-table/EquipmentStatusRender";
import { DialogServiceContext } from "@digitallab/grid-common-components";
import { useContext } from "react";
import { ViewChecklist } from "../equipment-list/ViewChecklist";
import { AdditionalEquipmentInformationModelType } from "../../models/AdditionalEquipmentInformationModelType";

export type MapProps = {
  snapShotView: boolean;
  equipmentDetail: DigitalLabLogbookInstrumentModelType;
  gxpReadys: unknown;
};

export const DisplayItemValueDefault = (
  equipmentDetail: DigitalLabLogbookInstrumentModelType,
  coverSheetItem: string
) => replaceEmptyWithHyphen(equipmentDetail[coverSheetItem as keyof DigitalLabLogbookInstrumentModelType]);

export const DisplayStatus = (props: MapProps) => {
  return (
    <EquipmentStatusRender
      value={props.equipmentDetail[COVER_SHEET_LIST.status.key as keyof DigitalLabLogbookInstrumentModelType] as string}
      style={{ height: "inherit" }}
    />
  );
};

export const DisplaySystemStatus = (props: MapProps) => {
  return replaceEmptyWithHyphen(
    props.snapShotView
      ? props.equipmentDetail?.addInfo?.[
          COVER_SHEET_LIST.currentSystemStatusFromLogs.key as keyof AdditionalEquipmentInformationModelType
        ]
      : props.equipmentDetail[DATA_MODEL_TABLE?.systemStatus?.key as keyof DigitalLabLogbookInstrumentModelType]
  );
};

export const DisplayGxPStatus = (props: MapProps) => {
  return replaceEmptyWithHyphen(
    props.snapShotView
      ? props.equipmentDetail?.addInfo?.[
          COVER_SHEET_LIST.currentGxPStatusFromLogs.key as keyof AdditionalEquipmentInformationModelType
        ]
      : validateSelection(props.gxpReadys, {
          key: props.equipmentDetail[
            DATA_MODEL_TABLE?.qualificationStatus?.key as keyof DigitalLabLogbookInstrumentModelType
          ]
        })
  );
};

const CheckListLink = (props: MapProps) => {
  // @ts-ignore
  const dialogService = useContext(DialogServiceContext);
  return (
    <OwcHyperlink
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        dialogService.show({
          title: "Checklists",
          confirmOnly: true,
          confirmText: "Close",
          confirmButtonVariant: "secondary",
          body: <ViewChecklist equipmentDetails={props.equipmentDetail} />
        });
      }}
      style={{ cursor: "pointer" }}
      href={""}
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
    >
      <strong>View Checklists</strong>
    </OwcHyperlink>
  );
};

export const coverSheetValuesComponents = new Map([
  [COVER_SHEET_LIST.currentGxPStatusFromLogs.key, DisplayGxPStatus],
  [COVER_SHEET_LIST.currentSystemStatusFromLogs.key, DisplaySystemStatus],
  [COVER_SHEET_LIST.status.key, DisplayStatus],
  [COVER_SHEET_LIST.checklists.key, CheckListLink]
]);
