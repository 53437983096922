import * as types from "./actionTypes";
import initialState from "./initialState";

export default function RunLogsFormReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_TIPS:
      return { ...state, tips: [...payload] };
    case types.LOAD_MEDIA_TYPES:
      return { ...state, mediaTypes: [...payload] };
    case types.LOAD_GXP_READYS:
      return { ...state, gxpReadys: [...payload] };
    case types.LOAD_SYSTEM_STATUSS:
      return { ...state, systemStatuss: [...payload] };
    case types.LOAD_LOG_SHEET_ACTIONS:
      return { ...state, actionLog: [...payload] };
    case types.LOAD_INSTRUMENT_SUGGESTIONS:
      return {
        ...state,
        instrumentSuggestions: [...payload]
      };
    case types.LOAD_INSTRUMENT_SUGGESTION:
      return {
        ...state,
        instrumentSuggestion: payload ? { ...payload } : null
      };
    case types.LOAD_MEGA_CLUSTER_IDS:
      return {
        ...state,
        megaClusterIds: [...payload]
      };
    default:
      return state;
  }
}
