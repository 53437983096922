import { OwcCard, OwcIcon, OwcTypography } from "@one/react";
import { useContext, useEffect, useMemo } from "react";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { useFormikContext } from "formik";
import { EmptyLinkContainer, EmptyLinkContainerStyled } from "../../log-book-item-form-dialog/FormsStyled";
import useDialog from "../../../../utils/hooks/useDialog";
import ConfirmDialogFiles from "../../../../components/shared/ConfirmDialogFiles";
import BinIcon from "../../../../icons/BinIcon";
import DownloadIcon from "../../../../icons/DownloadIcon";
import FileIcon from "../../../../icons/FileIcon";
import { getFile } from "./downloadFile";

import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import { LOGBOOK_ATTACHMENTS } from "../../../../constants";
import { BoxIcon, SVGWrapper } from "@digitallab/grid-common-components";

function FileList() {
  const formik = useFormikContext();
  const { openDialog, ...dialogRest } = useDialog();
  const { setFiles, filesData } = useContext(ItemDialogFormContext);

  const formikAttachments = useMemo(() => formik.values.attachments || [], [formik.values.attachments]);

  /**
   * remove file from the list
   * @param {object} file object with metadata associated to the file stored in logsheet
   * @returns void
   */
  const removeFile = (file) => () => {
    // creating deep copy of formikAttachments, so the change of status will be reflected only in tempFormikValues, but not in tempEquipmentDetail
    // in useEffect for save button state to disabled/enabled in ItemFormDialogBody.jsx
    const formikAttachmentsCopy = JSON.parse(JSON.stringify(formikAttachments));
    const updatedFiles = [...filesData].filter(({ fileId }) => fileId !== file.fileId);
    const FormikUpdatedFiles = formikAttachmentsCopy.map((item) => {
      if (item.fileId === file.fileId) {
        item.status = LOGBOOK_ATTACHMENTS.STATUS.INACTIVE;
      }
      return item;
    });
    //set status to invactive for files already stored
    formik.setFieldValue(LOGBOOK_ATTACHMENTS.ATTACHMENTS, FormikUpdatedFiles);
    //remove new files from the list of files that are going to be saved
    setFiles(updatedFiles);
  };

  const activeFilesExists =
    (formikAttachments || []).filter((item) => item.status === LOGBOOK_ATTACHMENTS.STATUS.ACTIVE).length > 0;

  useEffect(() => {
    if (!!filesData) {
      const fixed = filesData.map(({ __typename, checksum, ...rest }) => ({
        ...rest
      }));

      const files = [...formikAttachments, ...fixed].reduce((acc, curr) => {
        const doesExist = acc.find(({ fileId }) => fileId === curr.fileId);
        if (doesExist) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, []);

      formik.setFieldValue(LOGBOOK_ATTACHMENTS.ATTACHMENTS, files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesData]);

  return (
    <>
      <OwcCard flat style={{ padding: "0px", width: "100%" }}>
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-3px",
            padding: "10px"
          }}
        >
          <OwcTypography variant="button">
            <strong>File list</strong>
          </OwcTypography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <OwcIcon type="legacy" variant="primary" name="circle_add" />
            <OwcTypography
              variant="subtitle2"
              style={{
                marginLeft: "5px",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
              onClick={openDialog}
            >
              <strong> Add file</strong>
            </OwcTypography>
          </div>
        </div>

        {activeFilesExists && (
          <div style={{ height: "300px", width: "100%", marginTop: 0 }}>
            {formikAttachments
              .filter((item) => item.status === LOGBOOK_ATTACHMENTS.STATUS.ACTIVE)
              .map((file, index) => (
                <div
                  key={`${file.s3FileName}${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottom: "1px solid #C2BAB5AA"
                  }}
                >
                  <div style={{ padding: "12px 12px 12px 16px" }}>
                    <FileIcon />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: "12px 0px",
                      fontSize: "14px"
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        color: "var(--one-color-interaction-default-brand-1)"
                      }}
                    >
                      {file.fileDescription}
                    </div>
                    <div>{file.fileName.split("--")[1]}</div>
                  </div>
                  <div style={{ padding: "0 8px", cursor: "pointer" }} onClick={removeFile(file)}>
                    <BinIcon />
                  </div>
                  <div style={{ padding: "0 32px 0 16px", cursor: "pointer" }} onClick={() => getFile(file)}>
                    <DownloadIcon />
                  </div>
                </div>
              ))}
          </div>
        )}

        {!activeFilesExists && (
          <EmptyLinkContainerStyled>
            <EmptyLinkContainer onClick={openDialog}>
              {!activeFilesExists && (
                <>
                  <OwcIcon className="emptyIconMyLab">
                    <SVGWrapper icon={<BoxIcon />} dimension={90} />
                  </OwcIcon>
                  <OwcTypography variant="title6">
                    <strong> Please add file</strong>
                  </OwcTypography>
                </>
              )}
              <OwcTypography variant="body2">No files available</OwcTypography>
            </EmptyLinkContainer>
          </EmptyLinkContainerStyled>
        )}
      </OwcCard>
      <ConfirmDialogFiles {...dialogRest} onSave={setFiles} />
    </>
  );
}

export default compose(withApollo)(FileList);
