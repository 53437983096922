import React, { useContext, useRef } from "react";
import { OwcMenu, OwcListItem } from "@one/react";
import { MyLabMenuArray, entryType, equipmentStatus, formStates, formTypes } from "../../constants";
import { useSelector } from "react-redux";
import { CoverSheetMainPageContext } from "../cover-sheet/cover-sheet-main-page-context/context";
import { DialogServiceContext, toQueryParameter } from "@digitallab/grid-common-components";
import { ViewChecklist } from "./ViewChecklist";
import { DigitalLabLogbookInstrumentModelType } from "../../models/DigitalLabLogbookInstrumentModelType";
import { useHistory } from "react-router-dom";

const MyEquipmentMenu = ({
  anchorId,
  setShowMenu,
  logBookEquipment,
  equipmentDetails
}: {
  anchorId: string;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  logBookEquipment: DigitalLabLogbookInstrumentModelType;
  equipmentDetails: DigitalLabLogbookInstrumentModelType;
}) => {
  const menuElement = useRef(null);
  // @ts-ignore
  const dialogService = useContext(DialogServiceContext);
  const history = useHistory();
  //@ts-ignore
  const canEdit = useSelector((store) => store.user.canEdit);
  const { handleOpenWorkFlowModel, loadFormType } = useContext(CoverSheetMainPageContext);
  const redirectWithoutCookie = (inventoryId: any, option: string) => {
    let launchURL = "";
    if (option === "viewLogbook") {
      launchURL = `/cover-sheet/${inventoryId}`;
    }
    window.open(launchURL);
  };

  const handleClick = (option: string) => {
    setShowMenu(false);
    switch (option) {
      case "viewLogbook":
        redirectWithoutCookie(anchorId, option);
        break;
      case "addRunLogs":
        if (logBookEquipment?.entryType === entryType?.standaloneEquipment) {
          history.push(`/logs/run${toQueryParameter("equipmentIdList", [equipmentDetails.id])}`);
        } else {
          if (handleOpenWorkFlowModel && typeof handleOpenWorkFlowModel === "function")
            handleOpenWorkFlowModel({
              isCluster: logBookEquipment?.entryType === entryType?.cluster,
              form: formStates?.PARTIAL_NEW_FORM
            });
          if (loadFormType && typeof loadFormType === "function") loadFormType(formTypes?.RUN_LOG);
        }
        break;
      case "addActionlogs":
        if (logBookEquipment?.entryType === entryType?.standaloneEquipment) {
          history.push(`/logs/action${toQueryParameter("equipmentIdList", [equipmentDetails.id])}`);
        } else {
          if (handleOpenWorkFlowModel && typeof handleOpenWorkFlowModel === "function")
            handleOpenWorkFlowModel({
              isCluster: logBookEquipment?.entryType === entryType?.cluster,
              form: formStates?.PARTIAL_NEW_FORM
            });
          if (loadFormType && typeof loadFormType === "function") loadFormType(formTypes?.ACTION_LOG);
        }
        break;
      case "viewChecklists":
        dialogService.show({
          title: "Checklists",
          confirmOnly: true,
          confirmText: "Close",
          confirmButtonVariant: "secondary",
          body: <ViewChecklist equipmentDetails={equipmentDetails} />
        });
        break;
      default:
        console.error(`Menu option ${option} not implemented`);
    }
  };

  return (
    <div>
      <OwcMenu
        ref={menuElement}
        visible={true}
        onClickOutside={() => setShowMenu(false)}
        anchor={anchorId}
        disable-portal={true}
        strategy="fixed"
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
        {MyLabMenuArray.map((item, index) => {
          return (
            <OwcListItem
              key={index}
              onClick={() => {
                handleClick(item.key);
              }}
              id={item.key}
              disabled={
                (["viewChecklists", "viewLogbook"].includes(item.key)
                  ? false
                  : !canEdit || !!logBookEquipment?.clusterId) ||
                logBookEquipment?.status === equipmentStatus?.deleted?.key
              }
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              {item.value}
            </OwcListItem>
          );
        })}
      </OwcMenu>
    </div>
  );
};

export default MyEquipmentMenu;
