import {
  LIST_DIGITAL_LAB_LOGBOOK_MEDIA_TYPES,
  LIST_DIGITAL_LAB_LOGBOOK_TIPS
} from "../../../../gql/logBooksapi/queries";
import { loadMediaType, loadTips } from "../../log-book-item-form-dialog/redux/actions";
import { sortBy } from "underscore";
import { getAllData } from "../../../../utils/helpers/fetching";

export const runLogsLoadItemSpecifics = async ({ client, dispatch }) => {
  const [{ items: mediaTypes }, { items: tips }] = await Promise.all([
    getAllData({
      client,
      query: LIST_DIGITAL_LAB_LOGBOOK_MEDIA_TYPES,
      fetchPolicy: "no-cache",
      variables: {
        limit: 200
      },
      dataPath: ["data", "listDigitalLabLogbookMediaTypes"],
      drillData: true
    }),
    getAllData({
      client,
      query: LIST_DIGITAL_LAB_LOGBOOK_TIPS,
      fetchPolicy: "no-cache",
      variables: {
        limit: 200
      },
      dataPath: ["data", "listDigitalLabLogbookTips"],
      drillData: true
    })
  ]);

  dispatch(loadMediaType(sortBy(mediaTypes, ["value"])));

  dispatch(loadTips(sortBy(tips, ["value"])));
};
