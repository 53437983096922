import {
  RUN_LOG_BY_SITE_AND_START_DATE_ASC,
  RUN_LOG_BY_SITE_AND_START_DATE_DESC,
  RUN_LOG_BY_INVENTORY_ID_ASC,
  RUN_LOG_BY_INVENTORY_ID_DESC,
  LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_DESC
} from "../../../gql/logBooksapi/queries";
import { fetchCommon } from "../common-for-log-sheet-and-run-logs/fetch-common-all";

export const fetchAllLogs = fetchCommon({
  queryAsc: RUN_LOG_BY_SITE_AND_START_DATE_ASC,
  queryDesc: RUN_LOG_BY_SITE_AND_START_DATE_DESC,
  queryWithInstrumentAsc: RUN_LOG_BY_INVENTORY_ID_ASC,
  runQueryWithInstrumentDesc: RUN_LOG_BY_INVENTORY_ID_DESC,
  runQueryName: "runLogBySiteAndStartDate",
  actionQueryWithInstrumentDesc: LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_DESC,
  actionQueryName: "logSheetBySiteAndActionDateTime"
});
