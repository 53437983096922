import styled from "styled-components";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef, useState } from "react";
// import { ItemDialogFormContext } from "../log-book-item-form-dialog/item-context/context";
import ExportItemToCSVButton from "../log-book-export/ExportItemToCSVButton";
import ExportToPDFButton from "../log-book-export-pdf/ExportToPDFButton";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";
import { OwcIconButton } from "@one/react";
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";

const ButtonActionBoxStyled = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1279px) {
    padding-top: 10px;
  }
  justify-content: flex-end;
  && > *:not(:first-child) {
    margin-left: 1rem;
  }
  position: relative;
`;

const ButtonActionBox = ({
  fetchingFn,
  dataTestId,
  fileNamePrefix,
  mapFn,
  raportTitle,
  dataTestIdPDF,
  fetchingFnPDF,
  fileNamePrefixPDF,
  mapFnPDF,
  addButtonLabel,
  equipmentDetail
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <ButtonActionBoxStyled data-testid={`${dataTestId}-filter-button-action-box`}>
      <div>
        <OwcIconButton
          flat
          ref={anchorRef}
          type="outlined"
          icon="more_vertical"
          onClick={handleToggle}
          id={
            dataTestId === "run-log"
              ? generateID.buttonID(Module.BUTTON.add_new_run, "button")
              : generateID.buttonID(Module.BUTTON.add_new_log, "button")
          }
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 2
          }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id={`export-${dataTestId}-menu`}
                    data-testid={`export-${dataTestId}-menu`}
                    onClick={() => setOpen(false)}
                  >
                    <ExportItemToCSVButton
                      fetchingFn={fetchingFn}
                      dataTestId={dataTestId}
                      fileNamePrefix={fileNamePrefix}
                      mapFn={mapFn}
                      id={
                        dataTestId === "run-log"
                          ? generateID.buttonID(Module.BUTTON.run_log_export.run_export_CSV, "button")
                          : generateID.buttonID(Module.BUTTON.action_log_export.action_export_CSV, "button")
                      }
                    />
                    <ExportToPDFButton
                      raportTitle={raportTitle}
                      fetchingFn={fetchingFnPDF}
                      dataTestId={dataTestIdPDF}
                      fileNamePrefix={fileNamePrefixPDF}
                      mapFn={mapFnPDF}
                      id={
                        dataTestId === "run-log"
                          ? generateID.buttonID(Module.BUTTON.run_log_export.run_download_PDF, "button")
                          : generateID.buttonID(Module.BUTTON.action_log_export.action_download_PDF, "button")
                      }
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ButtonActionBoxStyled>
  );
};

export default ButtonActionBox;
