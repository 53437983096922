import * as types from "./actionTypes";
import initialState from "./initialState";

export default function equipmentModelsReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_EQUIPMENT_MODELS:
      return { ...state, equipmentModels: [...payload] };
    case types.LOAD_EQUIPMENT_SIGNEE:
      return { ...state, signee: [...payload] };
    default:
      return state;
  }
}
