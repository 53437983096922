import { useSelector } from "react-redux";
import { get } from "underscore";
import { findOrFallbackByObject } from "../helpers/find-or-fallback-by-object";
import { stringOrEmpty } from "../../../utils/helpers/text";

const ValueOrFallbackText = ({ item, reduxPath, propValue, propLabel, defaultValue = "", isArrayOfObjects }) => {
  const array = useSelector((state) => get(state, reduxPath));
  return isArrayOfObjects
    ? stringOrEmpty(item, { propLabel, defaultValue })
    : findOrFallbackByObject(array, item, {
        propLabel,
        propValue,
        defaultValue
      });
};

export default ValueOrFallbackText;
