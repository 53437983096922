import React from "react";
import { SHOW_COMMON_KEY, ALL_EQUIPMENT_KEY } from "../../constants";
import { find } from "lodash";
import { Grid, Tooltip } from "@mui/material";

const ShowEquipmentInfo = ({ equipmentDetail }) => {
  let finalArr = [...SHOW_COMMON_KEY];
  if (equipmentDetail?.linkedInstance === "None") {
    finalArr.push(find(ALL_EQUIPMENT_KEY, { key: "materialNumber" }));
  } else if (equipmentDetail?.secondaryIDMapping) {
    const fieldsName = equipmentDetail?.secondaryIDMapping.split("; ");
    fieldsName.forEach((item) => {
      if (!find(SHOW_COMMON_KEY, { key: item })) {
        if (item !== "CLUSTER") {
          finalArr.push(find(ALL_EQUIPMENT_KEY, { key: item }));
        }
      }
    });
  }

  return (
    <div>
      <Grid container spacing={1}>
        {finalArr.map((item) => {
          const isValue =
            typeof item !== "undefined" && equipmentDetail[item.key] && equipmentDetail[item.key] !== "null"
              ? equipmentDetail[item.key]
              : "-";
          return (
            <Grid container item xs={4} style={{ fontSize: 16, color: "#333" }} key={item?.label}>
              {item?.label}:
              <Tooltip title={isValue}>
                <strong
                  style={{
                    maxWidth: "120px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                  }}
                >
                  &nbsp;
                  {isValue}
                </strong>
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ShowEquipmentInfo;
