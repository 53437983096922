const DownloadIcon = (props) => {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0V8H13L9 12L5 8H8V0H10ZM0 16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V11H16V16H2V11H0V16Z"
        fill="#706B69"
      />
    </svg>
  );
};

export default DownloadIcon;
