import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../../../helpers";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";
import { OwcInput, OwcIcon } from "@one/react";

const RunClassicInput = ({ property, label, testid, fileTestId, required = false }) => {
  const formik = useFormikContext();
  const classes = {};
  return (
    <OwcInput
      style={{ width: "100%" }}
      onBlur={formik.handleBlur}
      data-testid={`run-data-inputs-${testid}-input`}
      variant="filled"
      required={required}
      id={generateID.textBoxID(Module.POPUP_FIELDS[property], "textbox")}
      name={property}
      label={label}
      value={formik.values[property]}
      onInputChange={formik.handleChange}
      {...commonPropsForInputsWithoutValue(classes, formik, property, testid, fileTestId)}
    >
      {formik.errors[property] && formik.touched[property] === true && (
        <>
          <span
            // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
            slot="error-text"
          >
            {formik.errors[property]}
          </span>
        </>
      )}
      {formik.values[property] !== "" && formik.values[property] !== null && (
        <OwcIcon
          // id={generateID.UUID(
          //   adminTopBannerMessage,
          //   `topBannerMessage`,
          //   "icon_clear_circle"
          // )}
          name="circle_clear_filled"
          slot="suffix"
          type="legacy"
          onClick={() => formik.setFieldValue(property, "", true)}
        />
      )}
    </OwcInput>
  );
};

export default RunClassicInput;
