const COMMON_LOGS_DATA_MODEL = {
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID"
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentModel: {
    key: "equipmentModel",
    value: "Model"
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category"
  },
  description: {
    key: "description",
    value: "Description"
  },
  fullDescription: {
    key: "description",
    value: "Full description"
  },
  approverUserName: {
    key: "approverUserName",
    value: "Signee"
  },
  operatorUserName: {
    key: "operatorUserName",
    value: "Operator"
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number"
  },
  manufacturer: {
    key: "manufacturer",
    value: "Manufacturer"
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version"
  },
  configuration: {
    key: "configurationBaseline",
    value: "Configuration"
  },
  location: {
    key: "location",
    value: "Location"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  runStartDate: {
    key: "runStartDate",
    value: "Run start date"
  },
  runEndDate: {
    key: "runEndDate",
    value: "Run end date"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  subEquipment: {
    key: "subEquipment",
    value: "Sub component"
  },
  tipsUsed: {
    key: "tipsUsed",
    value: "Tips"
  }
};

export default COMMON_LOGS_DATA_MODEL;
