import React, { useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { CustomFilterContext } from "./context";
import { DEFAULT_SITE } from "../../../constants";

const handleClearAll = (state) => ({
  ...initialState,
  site: state.site,
  inputsState: {
    ...initialState.inputsState,
    site: state.inputsState.site
  }
});

const handleSetEquipmentModel = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    equipmentModel: action.payload
  }
});

const handleSetSignee = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    signee: action.payload
  }
});

const handleSetSigneeChangeLog = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    signeeChangeLog: action.payload
  }
});

const handleSetReason = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    reason: action.payload
  }
});

const handleSetInstrument = (state, action) => {
  const newState = {
    ...state,
    inputsState: {
      ...state.inputsState,
      instrument: action.payload
    }
  };
  if (!action.payload && (!state.inputsState.dateFrom || !state.inputsState.dateTo)) {
    newState.inputsState.dateFrom = initialState.dateFrom;
    newState.inputsState.dateTo = initialState.dateTo;
  }
  return newState;
};

const handleSetSite = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    site: action.payload
  }
});

const handleSetDateRange = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    dateFrom: action.payload?.dateFrom,
    dateTo: action.payload?.dateTo
  }
});

const handleSetDateTo = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    dateTo: action.payload
  }
});

const handleSetDateFrom = (state, action) => ({
  ...state,
  inputsState: {
    ...state.inputsState,
    dateFrom: action.payload
  }
});
const handleSetGridFilter = (state, action) => ({
  ...state,
  gridFilter: action.payload
});

const handleApplyInputsState = (state) => ({
  ...state,
  ...state.inputsState,
  inputsState: {
    ...state.inputsState
  }
});

export const initialState = {
  dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  site: DEFAULT_SITE,
  equipmentModel: null,
  signee: [],
  signeeChangeLog: [],
  reason: [],
  instrument: null,
  gridFilter: false,
  inputsState: {
    dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    site: DEFAULT_SITE,
    equipmentModel: null,
    signee: [],
    signeeChangeLog: [],
    reason: [],
    instrument: null
  }
};

export function reducer(state = initialState, action = { type: "", payload: "" }) {
  switch (action.type) {
    case "applyInputsState":
      return handleApplyInputsState(state);
    case "setDateFrom":
      return handleSetDateFrom(state, action);
    case "setDateTo":
      return handleSetDateTo(state, action);
    case "setDateRange":
      return handleSetDateRange(state, action);
    case "setSite":
      return handleSetSite(state, action);
    case "setEquipmentModel":
      return handleSetEquipmentModel(state, action);
    case "setSignee":
      return handleSetSignee(state, action);
    case "setSigneeChangeLog":
      return handleSetSigneeChangeLog(state, action);
    case "setReason":
      return handleSetReason(state, action);
    case "setInstrument":
      return handleSetInstrument(state, action);
    case "setGridFilter":
      return handleSetGridFilter(state, action);
    case "clearAll":
      return handleClearAll(state);
    default:
      return state;
  }
}

const CustomFilterWrapper = ({ children, initialValues }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues
  });
  const storeUser = useSelector((store) => store.user);

  useEffect(() => {
    dispatchAction({
      type: "setSite",
      payload: storeUser?.site
    });
  }, [storeUser?.site]);

  return (
    <CustomFilterContext.Provider
      value={{
        ...state,
        dispatchAction
      }}
    >
      {children}
    </CustomFilterContext.Provider>
  );
};

export default CustomFilterWrapper;
