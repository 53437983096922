import gql from "graphql-tag";

export const ON_CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG = gql`
  subscription onCreateDigitalLabLogbookRunLog {
    onCreateDigitalLabLogbookRunLog {
      id
    }
  }
`;
export const ON_UPDATE_DIGITAL_LAB_LOGBOOK_RUN_LOG = gql`
  subscription onUpdateDigitalLabLogbookRunLog {
    onUpdateDigitalLabLogbookRunLog {
      id
    }
  }
`;

export const ON_CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET = gql`
  subscription onCreateDigitalLabLogbookLogSheet {
    onCreateDigitalLabLogbookLogSheet {
      id
    }
  }
`;

export const ON_UPDATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET = gql`
  subscription onCreateDigitalLabLogbookLogSheet {
    onUpdateDigitalLabLogbookLogSheet {
      id
    }
  }
`;

export const ON_UPDATE_BACKEND_HEALTH_CHECK = gql`
  subscription onUpdateBackendHealthCheck {
    onUpdateBackendHealthCheck {
      isAppUnderMaintenance
    }
  }
`;
