import * as yup from "yup";
import DATA_MODEL_TABLE from "../utils/constants/dataModelTable";
import { format, parseISO, isBefore } from "date-fns";
export const actionLogValidationSchema = () =>
  yup.object({
    systemStatus: yup
      .object({
        key: yup.string().typeError("Enter System status").required("Enter System status"),
        value: yup.string().typeError("Enter System status").required("Enter System status")
      })
      .typeError("Enter System status")
      .required("Enter System status")
      .nullable(),
    actionDate: yup.date().typeError("Enter action date").required("Enter correct action date").nullable(),
    actionTime: yup
      .string()
      .typeError("Enter action Time")
      .required("Enter correct action Time")
      .test("maxtimeValidation", "Time should not be after maximal time", (value, obj) => {
        let actionDateObj = obj?.parent?.actionDate;
        if (typeof actionDateObj === "string") {
          actionDateObj = new Date(actionDateObj);
        }
        const valueWithDate = parseISO(`${format(actionDateObj, "yyyy-MM-dd")}T${value}`);
        return isBefore(valueWithDate, new Date());
      }),
    gxpReady: yup
      .object({
        key: yup
          .string()
          .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
          .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
        value: yup
          .string()
          .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
          .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
      })
      .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
      .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
      .nullable(),
    action: yup
      .object({
        key: yup.string().typeError("Enter action type").required("Enter action type"),
        value: yup.string().typeError("Enter action type").required("Enter action type")
      })
      .typeError("Enter action type")
      .required("Enter action type")
      .nullable(),
    externalDocument: yup
      .array(
        yup.object({
          link: yup.string().typeError("Enter valid URL"),
          name: yup.string().typeError("Enter Description")
        })
      )
      .nullable(),
    ecrNumber: yup.string().typeError("Enter ECR number").nullable(),
    description: yup.string().typeError("Enter description").required("Enter description")
  });
