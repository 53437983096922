import * as types from "./actionTypes";

export const loadMediaType = (mediaTypes) => ({
  type: types.LOAD_MEDIA_TYPES,
  payload: mediaTypes
});
export const loadTips = (tips) => ({
  type: types.LOAD_TIPS,
  payload: tips
});

export const loadGxpReadys = (gxpReadys) => ({
  type: types.LOAD_GXP_READYS,
  payload: gxpReadys
});
export const loadSystemStatuss = (systemStatuss) => ({
  type: types.LOAD_SYSTEM_STATUSS,
  payload: systemStatuss
});
export const loadLogSheetActions = (actionLog) => ({
  type: types.LOAD_LOG_SHEET_ACTIONS,
  payload: actionLog
});
export const loadInstrumentSuggestions = (instrumentSuggestions) => ({
  type: types.LOAD_INSTRUMENT_SUGGESTIONS,
  payload: instrumentSuggestions
});
export const loadInstrumentSuggestion = (instrumentSuggestion) => ({
  type: types.LOAD_INSTRUMENT_SUGGESTION,
  payload: instrumentSuggestion
});
export const loadMegaClusterIds = (megaClusterIds) => ({
  type: types.LOAD_MEGA_CLUSTER_IDS,
  payload: megaClusterIds
});
