import { useSelector } from "react-redux";
import styled from "styled-components";
import { fetchAllLogs } from "../run-logs/fetch-all-logs";
import { CreateRunLogChange } from "../run-logs/run-logs-form/CreateRunLogChange";
import { emptyRunLog } from "../run-logs/run-logs-form/empty-run-log";
import { makeCleanRunLog } from "../run-logs/run-logs-form/make-clean-run-log";
import { runLogValidationSchemaFactory } from "../run-logs/run-logs-form/run-log-validation-schema";
import { runLogsClearSuggestion } from "../run-logs/run-logs-form/run-logs-clear-suggestion";
import { runLogsSelectSuggestion } from "../run-logs/run-logs-form/run-logs-select-suggestion";
import RunDataInputsBox from "../run-logs/run-logs-form/RunDataInputsBox";
import { CellsInfoArray } from "../run-logs/run-logs-history/HistoryRunLogCellsInfoArray";
import { RunLogTableMeta, RunLogTableEquipmentCentricMeta } from "../run-logs/runLogs-table/RunLogTableMeta";
import ItemPage from "./ItemPage";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";
import { formTypes } from "../../../constants";
import { useParams } from "react-router-dom";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { useContext } from "react";
import { HistoryLogSheetCellsInfoArray } from "../log-sheet/log-sheet-history/HistoryLogSheetCellsInfoArray";
import { ImportHistoryOfLogSheet } from "../log-sheet/log-sheet-history/ImportHistoryOfLogSheet";
import { ImportHistoryOfRunLogs } from "../run-logs/run-logs-history/ImportHistoryOfRunLogs";
import { emptyLogSheet } from "../log-sheet/log-sheet-form/empty-log-sheet";
import { makeCleanLogSheet } from "../log-sheet/log-sheet-form/make-clean-log-sheet";
import { CreateLogSheetChange } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import { logSheetValidationSchemaFactory } from "../log-sheet/log-sheet-form/log-sheet-validation-schema";
import { logSheetSelectSuggestion } from "../log-sheet/log-sheet-form/log-sheet-select-suggestion";
import { logSheetClearSuggestion } from "../log-sheet/log-sheet-form/log-sheet-clear-suggestion";
import ActionDataInputsBox from "../log-sheet/log-sheet-form/ActionDataInputsBox";
import { allLogsItemsToPDF, allLogsItemsToSheet } from "../run-logs/run-logs-export/all-logs-items-to-sheet";
import { Attachments } from "../common-for-log-sheet-and-run-logs/HistoryCommonCellsInfo";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Header = styled.span`
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
`;

const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const AllLogsPage = ({ equipmentDetail }) => {
  const { inventoryId } = useParams();
  const { allLogType } = useContext(CoverSheetMainPageContext);
  const storeUserSite = useSelector((store) => store.user?.site);
  //adding audit trails logs
  if (
    !HistoryLogSheetCellsInfoArray.some((el) => el.label === Attachments.label) &&
    attachmentSites.includes(storeUserSite)
  ) {
    HistoryLogSheetCellsInfoArray.push(Attachments);
  }

  return (
    <ItemPage
      addButtonLabel={
        allLogType === formTypes?.RUN_LOG ? LOGBOOK_LABEL.BUTTON.add_new_run : LOGBOOK_LABEL.BUTTON.add_new_log
      }
      queryName={allLogType === formTypes?.RUN_LOG ? "runLogChangeByRunLogEntryId" : "logSheetChangeByLogSheetEntryId"}
      importFn={allLogType === formTypes?.RUN_LOG ? ImportHistoryOfRunLogs : ImportHistoryOfLogSheet}
      CellsInfoArray={allLogType === formTypes?.RUN_LOG ? CellsInfoArray : HistoryLogSheetCellsInfoArray}
      title={
        allLogType === formTypes?.RUN_LOG
          ? LOGBOOK_LABEL.run_logs_history_heading
          : LOGBOOK_LABEL.action_logs_history_heading
      }
      dataTestId={allLogType === formTypes?.RUN_LOG ? "run-log" : "log-sheet"}
      type={formTypes?.ALL_LOG}
      fetchingFn={fetchAllLogs}
      mapFn={allLogsItemsToSheet}
      mapSecondFn={allLogsItemsToPDF}
      fileNamePrefix="all-logs-report"
      pageTitle={LOGBOOK_LABEL.PAGE_TITLE.all_logs}
      orderBy="allLogDate"
      meta={inventoryId ? RunLogTableEquipmentCentricMeta : RunLogTableMeta}
      emptyItem={allLogType === formTypes?.RUN_LOG ? emptyRunLog : emptyLogSheet}
      cleanItemFn={allLogType === formTypes?.RUN_LOG ? makeCleanRunLog : makeCleanLogSheet}
      createItemChangeFn={allLogType === formTypes?.RUN_LOG ? CreateRunLogChange : CreateLogSheetChange}
      validationSchemaFactory={
        allLogType === formTypes?.RUN_LOG ? runLogValidationSchemaFactory : logSheetValidationSchemaFactory
      }
      selectSuggestionFn={allLogType === formTypes?.RUN_LOG ? runLogsSelectSuggestion : logSheetSelectSuggestion}
      clearSuggestionFn={allLogType === formTypes?.RUN_LOG ? runLogsClearSuggestion : logSheetClearSuggestion}
      showChipBox={true}
      equipmentDetail={equipmentDetail}
    >
      {allLogType === formTypes?.RUN_LOG ? (
        <RunDataInputsBox equipmentDetail={equipmentDetail} />
      ) : (
        <ActionDataInputsBox />
      )}
    </ItemPage>
  );
};

export default AllLogsPage;
