import { Amplify } from "aws-amplify";

export const configureAwsAppSync = async ({ aws_appsync_graphqlEndpoint }: { aws_appsync_graphqlEndpoint: string }) => {
  try {
    const myAppConfig = {
      aws_appsync_graphqlEndpoint
    };
    Amplify.configure(myAppConfig);
  } catch (error) {
    console.error(`error when trying to configure Amplify for ${aws_appsync_graphqlEndpoint}: `, error);
  }
};
